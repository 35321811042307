import { createContext } from 'react';
import { reaction } from 'mobx';
import { RouterStore } from 'mobx-router5';
import UserStore from './User';
import ConfigStore from './Config';
import UIStore from './UI';
import CatalogStore from './Catalog';
import ClassesStore from './Classes';
import ProgressStore from './Progress';
import LearningPathStore from './LearningPaths';
import VocabularyStore from './Vocabulary';
import SearchStore from './Search';
import ExamsStore from './Exams';
import AlertStore from './Alert';
import EventStore from './Event';
import FeaturesStore from './Features';

import SessionStore from './Session';

import OrganizationStore from './Organization';
import PremiumSessionsStore from './PremiumSessions';
import PremiumSessionFilterStore from './PremiumSessionFilter';

import PendoStore from './Pendo';
import UserNotificationsStore from '../components/Drawer/UserNotification/store';
import AllPaneStore from '../components/Drawer/AllPane/store';

class RootStore {
  constructor() {
    this.routerStore = new RouterStore();
    this.uiStore = new UIStore(this);
    this.userStore = new UserStore(this);
    this.configStore = new ConfigStore(this);
    this.catalogStore = new CatalogStore(this);

    this.progressStore = new ProgressStore(this);
    this.learningPathStore = new LearningPathStore(this);
    this.vocabularyStore = new VocabularyStore(this);
    this.searchStore = new SearchStore(this);
    this.examsStore = new ExamsStore(this);
    this.alertStore = new AlertStore(this);
    this.eventStore = new EventStore();

    this.sessionStore = new SessionStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.premiumSessionsStore = new PremiumSessionsStore(this);
    this.premiumSessionFilterStore = new PremiumSessionFilterStore(
      this.premiumSessionsStore,
      this.catalogStore,
      this,
    );
    // as classes refere this.premiumSessionsStore
    this.classesStore = new ClassesStore(this);

    this.pendoStore = new PendoStore(this);
    this.featuresStore = new FeaturesStore(this);

    this.configStore.getServerConfig();
    this.alertStore.getAlerts();
    this.allPaneStore = new AllPaneStore();
    this.userNotificationsStore = new UserNotificationsStore(this.allPaneStore);

    reaction(
      () => this.userStore.isLoggedIn,
      () => {
        this.userStore.getEnablements();
        this.progressStore.getProgress();
        this.classesStore.getClassesForCurrentUser();
        this.configStore.getServerConfig();
        this.eventStore.getConcurrentLogin();
        this.userStore.getPartnerInfo();
        this.sessionStore.startSessionExpiryPolling();
        this.organizationStore.init();
        // getting alerts again, since unathenticated users get alerts only for login page
        this.alertStore.getAlerts();
        this.eventStore.getNotifications();
        this.eventStore.startNotificationsPolling();
        this.userNotificationsStore.getUserNotifications(
          this.userStore.user.uuid,
        );
        this.featuresStore.getAllFeatures();
        this.userStore.startEnablementsPolling();
      },
    );

    reaction(
      () => this.userStore.subscription?.subscription,
      () => {
        this.catalogStore.getCurrentSubscriptionCatalog();
        this.learningPathStore.getLearningPaths();
        this.examsStore.getUserExams();

        if (this.userStore.isPremiumSubscriber) {
          this.premiumSessionsStore.getPremiumSessionContent();
          this.classesStore.getPremiumSessionEnrollmentsForCurrentUser();
        }

        if (this.userStore.isFreeTierSubscriber) {
          this.premiumSessionsStore.getPremiumSessionContent();
        }
      },
    );
    reaction(
      () =>
        this.classesStore.didFetchClassesForUser &&
        this.userStore.didFetchSubscription &&
        this.isLoggedIn,
      () => {
        if (
          !this.userStore.isUserAuthorized &&
          this.configStore.unauthorizedRedirectUrl !== ''
        ) {
          window.location.href = this.configStore.unauthorizedRedirectUrl;
        }
      },
    );
  }
}

const rootStore = new RootStore();
// eslint-disable-next-line import/no-mutable-exports
let storesContext = createContext(rootStore);

// used for unit tests
const createRootStore = () => {
  const newRootStore = new RootStore();
  storesContext = createContext(newRootStore);
  return newRootStore;
};

export { storesContext, createRootStore };
export default rootStore;
