/* eslint-disable no-console */
// TODO: This is not yet in place. Staging this for when we convert to a SPA

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import { reportAppLanguage } from 'config/sentry';
import { APP_BASE } from './constants';

const configureI18n = (uiStore, fallbackLng = 'en-US') => {
  const isLocalhost =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1';

  const instance = i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng,
      nsSeparator: false,
      keySeparator: false,
      debug: process.env.NODE_ENV === 'development' && !isLocalhost,
      backend: {
        loadPath: `${APP_BASE}/locales/{{lng}}.json`,
      },
      react: {
        useSuspense: true,
      },
      detection: {
        order: [
          'querystring',
          'localStorage', // user manual choice will have priority over browser language
          'sessionStorage',
          'navigator', // will almost always be defined...
          'htmlTag', // html lang attribute
          'path',
          'subdomain',
        ],
      },
    });

  instance.then((t) => {
    uiStore.tFunction = t;
    if (!i18n.language) {
      i18n.changeLanguage('en-US');
    }
    document.documentElement.setAttribute('lang', i18n.language);
    reportAppLanguage(i18n.language);
    moment.locale(i18n.language);
  });

  i18n.on('languageChanged', (language) => {
    document.documentElement.setAttribute('lang', language); // update html for accessibility purposes
    reportAppLanguage(language);
    moment.locale(language);
    uiStore.onLanguageChange(language);
  });

  return i18n;
};

export default configureI18n;
