/**
 * @file Course Catalog filters
 * @author Joshua Jack <jjack@redhat.com>
 */

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  Checkbox,
  Form,
  FormGroup,
  Panel,
  PanelGroup,
  Button,
} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { storesContext } from 'stores';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import './styles.scss';
import { useFeature } from '@ole-ui/ole-ui-components';

const LearningPathsFilters = () => {
  const { t } = useTranslation();
  const newTaxonomiesEnabled = useFeature({
    section: 'catalog',
    flagName: 'new_taxonomies',
    defaultFlagValue: false,
  });
  const { learningPathStore, userStore, routerStore } =
    useContext(storesContext);

  /**
   * This method is used to apply the filter when the user clicks on a filter option
   * @param {*} e onChange event
   * @param {*} selectedValues value(s) selected by the user when clicking the filter. Ex: 'redhat_fuse'
   * @param {*} filterKey key of the filter. Ex: 'taxonomyProducts'. If not provided, the check will be applied to all filters containing the selected value
   */
  const onChange = (e, selectedValues, filterKey) => {
    const { checked } = e.target;

    learningPathStore.filterKeys.forEach((key) => {
      if (filterKey && key !== filterKey) return; // If filterKey is provided, only apply to that filter

      const fk = Array.isArray(selectedValues)
        ? selectedValues
        : [selectedValues];

      fk.forEach((selectedValue) => {
        const found = learningPathStore.filters[key]?.find(
          (filter) => filter.key === selectedValue,
        );

        if (!found) return;

        found.checked = checked;
      });
    });

    learningPathStore.filterEntries();
  };

  const isChecked = (filterKey, key) =>
    learningPathStore.filters[filterKey].find((filter) => filter.key === key)
      ?.checked;

  const isExpanded = (id) => learningPathStore.expandedFilterGroups?.has(id);

  const renderFilterGroup = (id, key, title) => (
    <Panel
      eventKey={id}
      onToggle={(expanded) => {
        learningPathStore.toggleFilterGroups(id, expanded);
      }}
      data-analytics-id={`${key}-filt-skilp-lp`}
    >
      <Panel.Toggle componentClass="div" className="filter-panel-toggle">
        <Panel.Heading componentClass="header" className="filter-panel-heading">
          <Panel.Title className="filter-panel-heading-title">
            {title}
          </Panel.Title>
          <div className="filter-panel-heading-icon">
            <SVG src={isExpanded(id) ? CollapseIcon : ExpandIcon}>
              {isExpanded(id) ? t('Collapse') : t('Expand')}
            </SVG>
          </div>
        </Panel.Heading>
      </Panel.Toggle>
      <Panel.Body collapsible className="filter-panel-body">
        <Form>
          <FormGroup>
            {learningPathStore.filters[key].map((item) => (
              <div
                key={item?.key}
                className="filter-control-wrapper"
                data-analytics-id={`container-${item?.key}-${key}-filt-skilp-lp`}
              >
                <Checkbox
                  checked={isChecked(key, item?.key)}
                  name={item?.key}
                  className="filter-control"
                  onChange={(e) => onChange(e, item?.key, key)}
                  data-analytics-id={`${item?.key}-${key}-filt-skilp-lp`}
                >
                  {item?.value}
                </Checkbox>
                <small className="filter-control-badge">{item?.count}</small>
              </div>
            ))}
          </FormGroup>
        </Form>
      </Panel.Body>
    </Panel>
  );

  /**
   * This groups up filter options with the same label
   */
  const renderClusterTypeFilter = (id, key, title) => {
    const groupedFilterOptions = groupBy(
      learningPathStore.filters[key],
      'value',
    );
    return (
      <Panel
        eventKey={id}
        className="filter-panel"
        onToggle={(expanded) => {
          learningPathStore.toggleFilterGroups(id, expanded);
        }}
        data-analytics-id={`${key}-filt-skilp-lp`}
      >
        <Panel.Toggle componentClass="div" className="filter-panel-toggle">
          <Panel.Heading
            componentClass="header"
            className="filter-panel-heading"
          >
            <Panel.Title className="filter-panel-heading-title">
              {title}
            </Panel.Title>
            <div className="filter-panel-heading-icon">
              <SVG src={isExpanded(id) ? CollapseIcon : ExpandIcon}>
                {isExpanded(id) ? t('Collapse') : t('Expand')}
              </SVG>
            </div>
          </Panel.Heading>
        </Panel.Toggle>
        <Panel.Body collapsible className="filter-panel-body">
          <Form>
            <FormGroup>
              {Object.keys(groupedFilterOptions).map((groupKey) => {
                const groupItems = groupedFilterOptions[groupKey];
                const groupSummary = reduce(
                  groupItems,
                  (acc, element) => {
                    return {
                      ...acc,
                      totalCount: acc.totalCount + element.count,
                      keyList: [...acc.keyList, element.key],
                      key: element.key, // does not matter much
                      label: element.value, // all items have same label
                    };
                  },
                  { totalCount: 0, keyList: [] },
                );
                return (
                  <div
                    key={groupSummary?.key}
                    className="filter-control-wrapper"
                    data-analytics-id={`container-${groupSummary?.key}-${key}-filt-skilp-lp`}
                  >
                    <Checkbox
                      checked={isChecked(key, groupSummary?.key)}
                      name={groupSummary?.key}
                      className="filter-control"
                      onChange={(e) => {
                        // For example, when you click "beginner", all values mapping to this label
                        // will be applied to filter, like 0, 1, 2 and 3.
                        onChange(e, groupSummary?.keyList, key);
                      }}
                      data-analytics-id={`${groupSummary?.key}-${key}-filt-skilp-lp`}
                    >
                      {groupSummary?.label}
                    </Checkbox>
                    <small className="filter-control-badge">
                      {groupSummary.totalCount}
                    </small>
                  </div>
                );
              })}
            </FormGroup>
          </Form>
        </Panel.Body>
      </Panel>
    );
  };

  const renderCustomSkillPathButton = () => {
    return (
      <Button
        data-analytics-id="create-custom-btn-skillp-lp"
        key="lp-csk-button"
        className="btn-primary btn-block"
        onClick={() => {
          routerStore.navigate('custom-skill-path');
        }}
      >
        {t('Create Custom Skill Path')}
      </Button>
    );
  };

  const renderLegacyTaxonomyFilters = () => {
    return (
      <React.Fragment>
        {learningPathStore.filters.products.length
          ? renderFilterGroup(2, 'products', t('Products'))
          : null}
        {learningPathStore.filters.categories.length
          ? renderFilterGroup(3, 'categories', t('Categories'))
          : null}
      </React.Fragment>
    );
  };

  const renderNewTaxonomyFilters = () => {
    return (
      <React.Fragment>
        {learningPathStore.filters.taxonomyProducts?.length
          ? renderFilterGroup(2, 'taxonomyProducts', t('Products'))
          : null}
        {learningPathStore.filters.taxonomyTopics?.length
          ? renderFilterGroup(3, 'taxonomyTopics', t('Topics'))
          : null}
        {learningPathStore.filters.taxonomyAudiences?.length
          ? renderFilterGroup(4, 'taxonomyAudiences', t('Audiences'))
          : null}
        {learningPathStore.filters.levels?.length
          ? renderClusterTypeFilter(6, 'levels', t('Levels'))
          : null}
      </React.Fragment>
    );
  };

  // TODO: fix bug where filters do not render if you access the page via url or refresh. With this bug, the store.getFilterInfo is not even called...

  return (
    <section className="filters filters-lp">
      <header className="filter-group-heading">{t('Filter by')}</header>
      <header
        data-analytics-id="filt-cancel-btn-skillp-lp"
        className="filter-clear"
        role="button"
        tabIndex="0"
        onClick={() => learningPathStore.clearFilters()}
      >
        {t('Clear all')}
      </header>
      <PanelGroup id="filter-panel">
        {learningPathStore.filters.roles.length
          ? renderFilterGroup(1, 'roles', t('Job Roles'))
          : null}
        {newTaxonomiesEnabled
          ? renderNewTaxonomyFilters()
          : renderLegacyTaxonomyFilters()}
        {learningPathStore.filters.pathTypes.length
          ? renderFilterGroup(99, 'pathTypes', t('Path types'))
          : null}
        {(userStore.currentSubscriptionCode ||
          userStore.isOrganizationAdmin ||
          userStore.isOrganizationManager) && (
          <div className="custom-skill-path">
            {renderCustomSkillPathButton()}
          </div>
        )}
      </PanelGroup>
    </section>
  );
};

export default observer(LearningPathsFilters);
