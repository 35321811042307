import React, { useState, useEffect, useContext } from 'react';
import {
  Alert,
  AlertGroup,
  AlertActionCloseButton,
  AlertVariant,
  AlertActionLink,
} from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from 'services/ErrorService';
import ComponentError from 'components/Error/ComponentError';
import { observer } from 'mobx-react';
import { storesContext } from 'stores';
import { forEach } from 'lodash';
import { recordSeenLabUsageAlert } from '../../../services/LabService';
import '../styles.scss';

const LabUsageAlert = () => {
  const { t } = useTranslation();

  const { userStore } = useContext(storesContext);
  const { labHoursLeft, enablements } = userStore;
  const [alerts, setAlerts] = useState([]);

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const getLocalStorage = (key) => Number(localStorage.getItem(key) || -1);

  const removeAlert = (alertKey, seenLabUsageAlert = '', stopLabs = false) => {
    setAlerts((prevAlerts) => prevAlerts.filter((a) => a.key !== alertKey));

    if (seenLabUsageAlert !== undefined && userStore.isLoggedIn) {
      setLocalStorage(alertKey, seenLabUsageAlert);
      recordSeenLabUsageAlert(
        userStore.username,
        alertKey.replace('labUsageAlert', ''),
        stopLabs,
        seenLabUsageAlert,
      );
    }
  };

  const addAlert = (alert) => {
    setAlerts((prevAlerts) => {
      const isKeyExist = prevAlerts?.find(
        (prevAlert) => prevAlert.key === alert.key,
      );

      return isKeyExist ? prevAlerts : [...prevAlerts, alert];
    });
  };

  const getLabUsageAlertInfo = (labUsageHoursLeft, courseCode) => {
    const roundLabUsageHoursLeft = Math.round(labUsageHoursLeft);
    if (labUsageHoursLeft >= 10) {
      return {
        message: (
          <>
            {courseCode} {t('Lab hours remaining:')} {roundLabUsageHoursLeft}
          </>
        ),
        variant: 'info',
        description: (
          <>
            {t('You have:')} {roundLabUsageHoursLeft}{' '}
            {t(
              'lab hours remaining. Keep up the momentum to reach your goals. Plan your time effectively to maximize your progress.',
            )}
          </>
        ),
      };
    }

    if (labUsageHoursLeft >= 1) {
      return {
        message: (
          <>
            {courseCode} {t('Lab hours remaining:')} {roundLabUsageHoursLeft}
          </>
        ),
        variant: 'warning',
        description: (
          <>
            {t('You have:')} {roundLabUsageHoursLeft}{' '}
            {t(
              'study hours remaining. Stay focused and prioritize your most important tasks to make the most of your time.',
            )}
          </>
        ),
      };
    }

    if (labUsageHoursLeft > 0) {
      return {
        message: (
          <>
            {courseCode} {t('Lab hours remaining: less than one')}
          </>
        ),
        variant: 'warning',
        description: (
          <>
            {t(
              'You have less than one lab hour remaining. Wrap up your work and plan your next steps to stay on track.',
            )}
          </>
        ),
      };
    }

    return {
      message: (
        <>
          {courseCode} {t('Lab hours exhausted')}
        </>
      ),
      variant: 'warning',
      description: (
        <>
          {t(
            'You have used all your allocated lab hours. Well done on reaching this milestone in your learning journey - your dedication is truly commendable! As you progress, no further labs can be started during this subscription period. Thank you for your hard work, and stay motivated for the next steps ahead.',
          )}
        </>
      ),
    };
  };

  const addLabUsageAlertSubscription = () => {
    if (!userStore.currentSubscriptionCode) {
      return;
    }

    const { upcoming_lab_usage_alert: upcomingLabUsageAlert } =
      enablements.configurations;
    const alertKey = 'labUsageAlert';
    if (
      upcomingLabUsageAlert !== undefined &&
      labHoursLeft <= upcomingLabUsageAlert &&
      getLocalStorage(alertKey) !== upcomingLabUsageAlert
    ) {
      addAlert({
        ...getLabUsageAlertInfo(labHoursLeft, ''),
        ...{
          key: alertKey,
          labUsageHoursLeft: labHoursLeft,
          seenLabUsageAlert: upcomingLabUsageAlert,
        },
      });
    }
  };

  const addLabUsageAlertAlacarte = () => {
    if (!userStore.isAlaCarte) return;

    forEach(enablements, (enablement, code) => {
      const { upcoming_lab_usage_alert: upcomingLabUsageAlert } =
        enablement.configurations;

      const labHours = enablement.quotas?.lab_hours || {};
      const { allowed = 0, used = 0 } = labHours;
      const labUsageHoursLeft = Math.max(allowed - used, 0);
      const alertKey = `labUsageAlert${code}`;

      if (
        upcomingLabUsageAlert !== undefined &&
        labUsageHoursLeft <= upcomingLabUsageAlert &&
        getLocalStorage(alertKey) !== upcomingLabUsageAlert
      ) {
        addAlert({
          ...getLabUsageAlertInfo(labUsageHoursLeft, code.toUpperCase()),
          ...{
            key: alertKey,
            labUsageHoursLeft,
            seenLabUsageAlert: upcomingLabUsageAlert,
          },
        });
      }
    });
  };

  const addLabUsageAlert = () => {
    addLabUsageAlertSubscription();
    addLabUsageAlertAlacarte();
  };

  useEffect(() => {
    addLabUsageAlert();
  }, [enablements]);

  return (
    <AlertGroup isToast isLiveRegion>
      {alerts.map((alert) => (
        <Alert
          className="lab_usage_alert"
          variant={AlertVariant[alert.variant]}
          title={alert.message}
          timeout={false}
          onTimeout={() => removeAlert(alert.key)}
          actionClose={
            <AlertActionCloseButton
              title={alert.title}
              onClose={() => removeAlert(alert.key, alert.seenLabUsageAlert)}
            />
          }
          key={alert.key}
          actionLinks={
            <React.Fragment>
              <AlertActionLink // eslint-disable-next-line no-console
                onClick={() => removeAlert(alert.key, alert.seenLabUsageAlert)}
              >
                close
              </AlertActionLink>
              {alert.labUsageHoursLeft ? (
                <AlertActionLink // eslint-disable-next-line no-console
                  onClick={() =>
                    removeAlert(alert.key, alert.seenLabUsageAlert, true)
                  }
                >
                  {t('Stop all running labs')}
                </AlertActionLink>
              ) : (
                ''
              )}
            </React.Fragment>
          }
        >
          {alert.description}
        </Alert>
      ))}
    </AlertGroup>
  );
};

export default withErrorBoundary(
  observer(LabUsageAlert),
  ComponentError,
  handleError,
);
