/* eslint-disable import/prefer-default-export */
export enum ExamStatusUppercase {
  Unenrolled = 'UNENROLLED',
  Unscheduled = 'UNSCHEDULED',
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  RetakeAvailable = 'RETAKE_AVAILABLE',
  RetakeScheduled = 'RETAKE_SCHEDULED',
  RetakeComplete = 'RETAKE_COMPLETE',
  Requested = 'REQUESTED',
  ResultsPending = 'RESULTS_PENDING',
  RetakeResultsPending = 'RETAKE_RESULTS_PENDING',
}

export enum ExamStatus {
  Unenrolled = 'unenrolled',
  Unscheduled = 'unscheduled',
  Scheduled = 'scheduled',
  Completed = 'completed',
  RetakeAvailable = 'retake_available',
  RetakeScheduled = 'retake_scheduled',
  RetakeComplete = 'retake_complete',
  Requested = 'requested',
  ResultsPending = 'results_pending',
  RetakeResultsPending = 'retake_results_pending',
}
