import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import {
  Alert,
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  CardFooter,
} from '@patternfly/react-core';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { storesContext } from 'stores';
import LabIcon from 'img/icons/lab_flask.svg';
import PremiumSessionIcon from 'img/icons/premium_session.svg';
import ExamIcon from 'img/icons/exams.svg';

import './styles.scss';

function LimitedTrialStats() {
  const { t } = useTranslation();
  const { routerStore, userStore } = useContext(storesContext);
  const { enablementsQuotas } = userStore;
  const { exams } = enablementsQuotas || {};

  const isLabLimitAlmostReached = userStore.remainingLabHours <= 1;
  const isLabLimitReached = userStore.remainingLabHours <= 0;

  return (
    <div className="enterprise-trial-stats">
      <Card className="enterprise-trial-stats__item" ouiaId="BasicCard">
        <CardHeader>
          <SVG
            className="enterprise-trial-stats__item-icon"
            src={LabIcon}
            title={t('Remote Exam')}
            width={40}
            height={40}
          />
          <CardTitle className="enterprise-trial-stats__item-title">
            {t('Lab hours used')}
          </CardTitle>
        </CardHeader>
        <CardBody className="enterprise-trial-stats__item-stat-body">
          <p className="enterprise-trial-stats__item-stat">
            {userStore.totalUsedLabHours}/{userStore.totalAllowedLabHours}
          </p>
        </CardBody>
        {isLabLimitReached ? (
          <Alert
            className="enterprise-trial-stats__item-alert"
            isInline
            variant="danger"
            title={t('No more lab hours left.')}
          />
        ) : (
          isLabLimitAlmostReached && (
            <Alert
              className="enterprise-trial-stats__item-alert"
              isInline
              variant="warning"
              title={t('Only {{timeLeft}} lab time left.', {
                timeLeft: moment
                  .utc(
                    moment
                      .duration(userStore.remainingLabHours)
                      .as('milliseconds'),
                  )
                  .format('HH:mm:ss'),
              })}
            />
          )
        )}
        <CardBody>
          <p className="enterprise-trial-stats__item-description">
            {t(
              'Upgrade to our Premium Subscription for expanded lab usage, granting you access to {{limit}} hours of cloud-based, hands-on lab time.',
              { limit: 400 },
            )}
          </p>
        </CardBody>
      </Card>

      <Card className="enterprise-trial-stats__item" ouiaId="BasicCard">
        <CardHeader>
          <SVG
            className="enterprise-trial-stats__item-icon"
            src={PremiumSessionIcon}
            title={t('Remote Exam')}
            width={40}
            height={40}
          />
          <CardTitle className="enterprise-trial-stats__item-title">
            {t('Premium sessions used')}
          </CardTitle>
        </CardHeader>
        <CardBody className="enterprise-trial-stats__item-stat-body">
          <p className="enterprise-trial-stats__item-stat">
            {userStore.numberTotalUsedPremiumSessions}/
            {userStore.currentSubscriptionPremiumVTLimit}
          </p>
        </CardBody>
        {userStore.hasReachedCurrentSubscriptionPremiumVTLimit && (
          <Alert
            className="enterprise-trial-stats__item-alert"
            isInline
            variant="danger"
            title={t('No more premium sessions left.')}
          />
        )}
        <CardBody>
          <p className="enterprise-trial-stats__item-description">
            {t(
              'Delve into live, virtual sessions taught by expert instructors with our Premium subscription.',
            )}
          </p>
        </CardBody>
        <CardFooter>
          <Button
            variant="danger"
            onClick={() => {
              routerStore.navigate('premium:sessions:live');
            }}
          >
            {t('See Premium sessions')}
          </Button>
        </CardFooter>
      </Card>

      <Card className="enterprise-trial-stats__item" ouiaId="BasicCard">
        <CardHeader>
          <SVG
            className="enterprise-trial-stats__item-icon"
            src={ExamIcon}
            title={t('Remote Exam')}
            width={40}
            height={40}
          />
          <CardTitle className="enterprise-trial-stats__item-title">
            {t('Exams used')}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <p className="enterprise-trial-stats__item-stat">
            {exams?.used || 0}/{exams?.allowed}
          </p>
          <p className="enterprise-trial-stats__item-description">
            {t(
              'Our Premium Subscription gives you a full 365 days of exclusive access to our top-tier features and benefits.',
            )}
          </p>
        </CardBody>
        <CardFooter>
          <Button
            variant="danger"
            onClick={() => {
              routerStore.navigate('catalog', { q: 'exam' });
            }}
          >
            {t('See Exams')}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default observer(LimitedTrialStats);
