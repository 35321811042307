import React, { Component } from 'react';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';
import { Col, Row } from 'react-bootstrap';
import * as Sentry from '@sentry/react';

import { AlertGroupProvider, FeatureProvider } from '@ole-ui/ole-ui-components';
import Header from '../Header';
import Main from '../Main';
import Footer from '../Footer';
import { Intercom } from '../Intercom';
import SearchAutosuggest from '../Search';
import ImpersonatingUserBanner from '../Banners/ImpersonatingUserBanner';
import Drawer from '../Drawer';
import SessionModal from '../SessionModal';
import PendoWidget from '../PendoWidget';
import LabUsageAlert from '../LabEnvironment/LabUsageAlert';

@inject(
  'configStore',
  'userStore',
  'routerStore',
  'eventStore',
  'sessionStore',
  'featuresStore',
)
@observer
class Layout extends Component {
  static propTypes = {
    configStore: MobXPropTypes.observableObject.isRequired,
    userStore: MobXPropTypes.observableObject.isRequired,
    routerStore: MobXPropTypes.observableObject.isRequired,
    eventStore: MobXPropTypes.observableObject.isRequired,
    sessionStore: MobXPropTypes.observableObject.isRequired,
    featuresStore: MobXPropTypes.observableObject.isRequired,
  };

  render() {
    const {
      configStore,
      userStore,
      routerStore,
      eventStore,
      sessionStore,
      featuresStore,
    } = this.props;
    const { config } = configStore;
    const { route } = routerStore;

    return (
      <FeatureProvider
        handleFeatureCheck={featuresStore.isAFeatureFlagEnabled}
        features={featuresStore.features}
      >
        <AlertGroupProvider>
          {route && <Header />}

          {userStore.isImpersonatingUser && (
            <ImpersonatingUserBanner
              {...userStore.impersonatedUser}
              subscriptionType={userStore.currentSubscriptionCode}
              clearAction={() => userStore.clearImpersonatedUser()}
            />
          )}
          {userStore.isLoggedIn &&
            !userStore.isOpenSubscriber &&
            route &&
            route?.name !== 'skills-paths' &&
            !route?.name.includes('catalog') &&
            !route?.name.includes('courses:page') &&
            !route?.name.includes('courses:view') &&
            !route?.name.includes('premium:sessions:calendar') &&
            !route?.name.includes('free-trial-registration') &&
            route?.name !== 'home' && (
              <div className="container-grid-responsive">
                <Row>
                  <Col xs={12}>
                    <SearchAutosuggest />
                  </Col>
                </Row>
              </div>
            )}
          {userStore.isLoggedIn && userStore.canShowLabUsageAlert ? (
            <LabUsageAlert />
          ) : null}
          {userStore.isLoggedIn && sessionStore.sessionExpireTime && (
            <SessionModal expirationTime={sessionStore.sessionExpireTime} />
          )}
          <Main />
          {config?.INTERCOM_IO_ENABLED && config?.INTERCOM_IO_APP_ID ? (
            <Intercom appID={config?.INTERCOM_IO_APP_ID} />
          ) : null}

          {userStore.isLoggedIn &&
          configStore?.pendoAppId &&
          userStore.privacyConsents.functional ? (
            <PendoWidget />
          ) : null}
          <Footer />
          {eventStore.isDrawerOpen && <Drawer />}
        </AlertGroupProvider>
      </FeatureProvider>
    );
  }
}

export default Sentry.withProfiler(Layout);
