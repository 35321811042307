/**
 * @file Redirect component for external subscribers
 * @author Attila Farkas <afarkask@redhat.com>
 */

import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { when, reaction } from 'mobx';
import { storesContext } from '../../stores';
import ViewLoader from '../Loading/View';
import RedirectStore from './store';

const Redirect = () => {
  const { routerStore, catalogStore, classesStore, userStore } =
    useContext(storesContext);
  const store = useLocalStore(() => new RedirectStore());
  const path = routerStore?.route?.path;
  const courseCode = path.slice(
    path.indexOf(store.urlPattern) + store.urlPattern.length,
  );
  const loaderStyle = {
    margin: '20px 0',
  };

  useEffect(() => {
    when(
      () => !store.loaded && userStore.isLoggedIn,
      async () => {
        await classesStore.getClassesForCurrentUser();
        store.hasRelatedData = true;
      },
    );

    reaction(
      () => store.hasRelatedData && userStore.subscription?.subscription,
      async () => {
        await catalogStore.getCurrentSubscriptionCatalog();
        store.hasCatalogData = true;
      },
    );

    reaction(
      () => store.hasCatalogData,
      () => {
        catalogStore.selectedByExternalSubscriber = courseCode;
        routerStore.navigate('home');
        store.loaded = true;
      },
    );
  }, []);

  return (
    <div style={loaderStyle}>
      <ViewLoader />
    </div>
  );
};

export default observer(Redirect);
