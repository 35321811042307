/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import { Badge, Flex } from '@patternfly/react-core';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import DataTable from 'components/Datatable2';
import { handleError } from 'services/ErrorService';
import { storesContext } from 'stores';
import GenericViewError from '../../../Error/GenericViewError';
import './styles.scss';

const UserList = () => {
  const { t, i18n } = useTranslation();
  const { organizationStore } = useContext(storesContext);
  const { SearchBar } = Search;
  const [selectedUsers, setSelectedUsers] = useState([]);

  const managerName = (cell, row) => {
    if (row.org_manager_id) {
      const foundManager = organizationStore.managerList.find((manager) => {
        return manager.uuid === row.org_manager_id;
      });
      if (!foundManager) {
        return 'Unassigned';
      }
      return foundManager.preferred_name || foundManager.username;
    }
    return 'Unassigned';
  };

  const columnDefinitions = useMemo(() => {
    if (organizationStore.isAdminOfCurrentlySelectedOrg) {
      return [
        {
          text: t('Name'),
          dataField: 'preferred_name',
          id: 'report_title',
        },
        {
          text: t('Username'),
          dataField: 'username',
          id: 'username',
        },
        {
          text: t('Email'),
          dataField: 'email',
        },
        {
          text: t('Manager'),
          dataField: 'manager_list',
          isDummyField: true,
          sort: true,
          formatter: managerName,
          sortValue: managerName,
          filterValue: managerName,
          formatExtraData: organizationStore.managerList,
        },
        {
          text: t('Last Login'),
          dataField: 'last_login',
          formatter: (cell, row) => {
            if (!row?.last_login || row?.last_login === 'NA') {
              return t('Never');
            }

            return <div>{new Date(row.last_login).toLocaleDateString()}</div>;
          },
        },
      ];
    }

    return [
      {
        text: t('Name'),
        dataField: 'preferred_name',
        id: 'report_title',
      },
      {
        text: t('Username'),
        dataField: 'username',
        id: 'username',
      },
      {
        text: t('Email'),
        dataField: 'email',
      },
      {
        text: t('Last Login'),
        dataField: 'last_login',
        formatter: (cell, row) => {
          if (!row?.last_login || row?.last_login === 'NA') {
            return t('Never');
          }

          return <div>{new Date(row.last_login).toLocaleDateString()}</div>;
        },
      },
    ];
  }, [
    i18n.language,
    organizationStore.isAdminOfCurrentlySelectedOrg,
    organizationStore.managerList,
  ]);

  const selectRow = {
    mode: 'checkbox',
    onSelect: (row, isSelected) => {
      if (isSelected && !selectedUsers.includes(row.username)) {
        const newUsers = [...selectedUsers, row.username];

        organizationStore.setSelectedUserForManager(newUsers);

        setSelectedUsers(newUsers);
      } else {
        const newUsers = selectedUsers.filter((u) => u !== row.username);

        organizationStore.removeSelectedUserForManager(row.username);

        setSelectedUsers(newUsers);
      }
    },
    onSelectAll: (isSelected, rows) => {
      const ids = rows.map((r) => r.username);

      if (isSelected) {
        organizationStore.setSelectedUserForManager(ids);

        setSelectedUsers(ids);
      } else {
        organizationStore.removeAllSelectedUsersForManager();

        setSelectedUsers([]);
      }
    },
  };

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '20',
        value: 20,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  });

  useEffect(() => {
    if (organizationStore.isAdminOfCurrentlySelectedOrg) {
      organizationStore.getManagers();
    }

    organizationStore.getReportees(
      organizationStore.isAdminOfCurrentlySelectedOrg,
    );
  }, [organizationStore.organization]);

  let badgeLabel = organizationStore.managerReportees.length;

  if (organizationStore.selectedUsersByManagerList?.length > 0) {
    badgeLabel = `${organizationStore.selectedUsersByManagerList.length}/${organizationStore.managerReportees.length}`;
  }

  return (
    <div className="dashboard-manager-user-list">
      <Flex>
        <h3>{t('Users')}</h3>
        <Badge key={1} isRead>
          {badgeLabel}
        </Badge>
      </Flex>
      <ToolkitProvider
        keyField="username"
        data={organizationStore.managerReportees}
        columns={columnDefinitions}
        search
      >
        {(toolkitProps) => (
          <div>
            <SearchBar
              className="user-search-bar"
              {...toolkitProps.searchProps}
            />
            <hr />
            <DataTable
              noDataIndication={t('No Users Available')}
              bordered={false}
              selectRow={selectRow}
              pagination={pagination}
              rowClasses="text-wrap"
              {...toolkitProps.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default withErrorBoundary(
  observer(UserList),
  GenericViewError,
  handleError,
);
