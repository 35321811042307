/* eslint-disable camelcase */

import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { storesContext } from '../../stores';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args);
  }
};

const Intercom = (props) => {
  const { appID } = props;
  const { userStore, eventStore } = useContext(storesContext);

  const userProps = () => {
    const { subscription, user, enablementsQuotas } = userStore;

    return {
      ...(user.uuid && { user_id: user.uuid }),
      ...(user.preferred_name && {
        name: user.preferred_name,
      }),
      ...(user.default_email && {
        email: user.default_email,
      }),
      ...(subscription.subscription && {
        subscription: subscription.subscription,
      }),
      ...(subscription.start_time && {
        subscription_start_time: subscription.start_time,
      }),
      ...(subscription.end_time && {
        subscription_end_time: subscription.end_time,
      }),
      ...(!!enablementsQuotas?.pdf_downloads?.allowed && {
        subscription_enablements_pdf_downloads_allowed:
          enablementsQuotas?.pdf_downloads?.allowed,
      }),
      ...user,
    };
  };

  useEffect(() => {
    if (!appID || !canUseDOM) {
      return false;
    }

    if (!window.Intercom) {
      ((w, d, id, s) => {
        function i(...args) {
          i.c(args);
        }
        i.q = [];
        i.c = (args) => {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = `https://widget.intercom.io/widget/${id}`;
        d.head.appendChild(s);
      })(window, document, appID);
    }

    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: appID,
      });
    }

    reaction(
      () =>
        userStore.user.uuid &&
        userStore.isLoggedIn &&
        userStore.subscription?.subscription,
      () => {
        if (!canUseDOM) return;
        if (!window.Intercom) return;

        if (!userStore.isLoggedIn) {
          if (window.Intercom.booted) window.Intercom('shutdown');

          window.Intercom('boot', {
            app_id: appID,
          });

          return;
        }

        window.Intercom('update', userProps());
      },
    );

    return () => {
      if (!canUseDOM || !window.Intercom) return false;

      window.Intercom('shutdown');

      delete window.Intercom;
      delete window.intercomSettings;
      return false;
    };
  }, []);

  useEffect(() => {
    // To hide Intercom button when the Drawer is opened.
    // When Intercom chat is open, the class of the element changes
    // from intercom-launcher to intercom-app, so we need to check
    // for both classes
    const elementIntercomLauncher =
      document.getElementsByClassName('intercom-launcher')[0] ||
      document.getElementsByClassName('intercom-app')[0];
    if (elementIntercomLauncher) {
      if (eventStore.isDrawerOpen) {
        elementIntercomLauncher.style.display = 'none';
      } else {
        elementIntercomLauncher.style.display = 'block';
      }
    }
  }, [eventStore.isDrawerOpen]);

  return null;
};

Intercom.propTypes = {
  appID: PropTypes.string.isRequired,
};

Intercom.displayName = 'Intercom';

export { Intercom, IntercomAPI };

export default observer(Intercom);
