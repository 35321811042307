import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { observer, useLocalStore } from 'mobx-react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Form,
  FormControl,
  FormGroup,
  Panel,
  PanelGroup,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import DayPicker, { DateUtils } from 'react-day-picker';
import { KalturaWidget, useFeature } from '@ole-ui/ole-ui-components';
import SVG from 'react-inlinesvg';
import useMobxStores from 'hooks/useMobxStores';
import CollapseIcon from 'img/icons/web-icon-caret-thin-up.svg';
import ExpandIcon from 'img/icons/web-icon-caret-thin-down.svg';
import CalendarIcon from 'img/icons/calendar.svg';

import PremiumFilterStore from './store';
import { KALTURA_CONFIGS } from '../../config/constants';
import 'react-day-picker/lib/style.css';
import DateTimePicker from '../DateTimePicker';
import './styles.scss';

const PremiumFilters = (props) => {
  const { showCategories, showProducts, showFullSeatsFilter } = props;
  const { t } = useTranslation();
  const newTaxonomiesEnabled = useFeature({
    section: 'catalog',
    flagName: 'new_taxonomies',
    defaultFlagValue: false,
  });
  const { premiumSessionFilterStore, userStore } = useMobxStores();
  const premiumFilterStore = useLocalStore(() => new PremiumFilterStore());
  const {
    numberOfMonths,
    toggleCalendar,
    toggleCalendarTime,
    isCalendarOpen,
    isCalendarTimeOpen,
  } = premiumFilterStore;

  const { from, to } = premiumSessionFilterStore.dateRange;

  const modifiers = { start: from, end: to };

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(
      day,
      premiumSessionFilterStore.dateRange,
    );
    premiumSessionFilterStore.setDateRange(range);
  };

  const handleTimeChange = (selectedTime, isStart) => {
    if (selectedTime && selectedTime?.length > 0) {
      if (isStart) {
        premiumSessionFilterStore.setTimeRange({
          from: selectedTime[0],
          to: premiumSessionFilterStore.timeRange.to,
        });
      } else {
        premiumSessionFilterStore.setTimeRange({
          from: premiumSessionFilterStore.timeRange.from,
          to: selectedTime[0],
        });
      }
    }
  };

  const handleDateTimeFilterChange = (e, store, filterKey) => {
    store.filterKeys.forEach((key) => {
      const found = store.filters[key]?.find(
        (filter) => filter.key === filterKey,
      );

      if (!found) return;

      found.checked =
        found.value?.from !== undefined && found.value.to !== undefined;
    });

    store.filterEntries();
  };

  const clearFilters = (e, exceptions = []) => {
    premiumSessionFilterStore.clearFilters(exceptions);
  };

  /**
   * This method is used to apply the filter when the user clicks on a filter option
   * @param {*} e onChange event
   * @param {*} selectedValues value(s) selected by the user when clicking the filter. Ex: 'redhat_fuse'
   * @param {*} store store to apply the filter to
   * @param {*} filterKey key of the filter. Ex: 'taxonomyProducts'. If not provided, the check will be applied to all filters containing the selected value
   */
  const onChange = (e, selectedValues, store, filterKey) => {
    const { checked } = e.target;

    store.filterKeys.forEach((key) => {
      if (filterKey && key !== filterKey) return; // If filterKey is provided, only apply to that filter

      const fk = Array.isArray(selectedValues)
        ? selectedValues
        : [selectedValues];

      fk.forEach((selectedValue) => {
        const found = store.filters[key]?.find(
          (filter) => filter.key === selectedValue,
        );

        if (!found) return;

        found.checked = checked;
      });
    });

    store.filterEntries();
  };

  const isChecked = (filterKey, key, store) =>
    store?.filters[filterKey].find((filter) => filter.key === key)?.checked;

  const isExpanded = (id, store) => store?.expandedFilterGroups?.has(id);

  const renderFilterGroup = (id, key, title, store) => (
    <Panel
      eventKey={id}
      className="filter-panel"
      onToggle={(expanded) => {
        store.toggleFilterGroups(id, expanded);
      }}
      data-analytics-id={`${key}-filt-liveSess-lp`}
    >
      <Panel.Toggle componentClass="div" className="filter-panel-toggle">
        <Panel.Heading componentClass="header" className="filter-panel-heading">
          <Panel.Title className="filter-panel-heading-title">
            {title}
          </Panel.Title>
          <div className="filter-panel-heading-icon">
            <SVG src={isExpanded(id) ? CollapseIcon : ExpandIcon}>
              {isExpanded(id) ? t('Collapse') : t('Expand')}
            </SVG>
          </div>
        </Panel.Heading>
      </Panel.Toggle>
      <Panel.Body collapsible className="filter-panel-body">
        <Form>
          <FormGroup>
            {store.filters[key].map((item) => (
              <div
                key={item?.key}
                className="filter-control-wrapper"
                data-analytics-id={`container-${item?.key}-${key}-filt-liveSess-lp`}
              >
                {item?.value !== 'Video Classroom' && (
                  <>
                    <Checkbox
                      checked={isChecked(key, item?.key, store)}
                      name={item?.key}
                      className="filter-control"
                      onChange={(e) => {
                        onChange(e, item?.key, store);
                      }}
                      data-analytics-id={`${item?.key}-${key}-filt-liveSess-lp`}
                    >
                      {item?.value}
                    </Checkbox>
                    <small className="filter-control-badge">
                      {item?.count}
                    </small>
                  </>
                )}
              </div>
            ))}
          </FormGroup>
        </Form>
      </Panel.Body>
    </Panel>
  );

  const renderDateFilterGroup = (id, key, title, store) => (
    <Panel eventKey={id} className="filter-panel">
      <Panel.Body className="filter-panel-body">
        <Form>
          <FormGroup>
            {store.filters[key].map((item) => (
              <div
                key={item?.key}
                className="date-control-wrapper"
                onClick={() => toggleCalendar()}
                role="button"
                tabIndex="0"
              >
                <>
                  <FormControl
                    data-analytics-id="date-range-filt-liveSess-lp"
                    type="text"
                    value={`${
                      from
                        ? moment(from).format('MMM DD, YYYY')
                        : t('Start date')
                    } - ${
                      to ? moment(to).format('MMM DD, YYYY') : t('End date')
                    }`}
                    placeholder="Select date range"
                    className="date-field"
                    disabled
                  />
                  <Button
                    className="date-btn"
                    data-analytics-id="date-calendar-btn-filt-liveSess-lp"
                  >
                    <SVG src={CalendarIcon} />
                  </Button>
                </>
              </div>
            ))}
          </FormGroup>
        </Form>
      </Panel.Body>
    </Panel>
  );

  const renderFullSessionFilter = () => {
    return (
      <Panel>
        <Panel.Body>
          <Form>
            <FormGroup>
              <>
                <Checkbox
                  data-analytics-id="hide-full-sess-chkbox-schedule-sess-lp"
                  checked={isChecked(
                    'fullsessions',
                    'fullsessions',
                    premiumSessionFilterStore,
                  )}
                  name="full-session-filter"
                  className="filter-full-session"
                  onChange={(e) => {
                    onChange(e, 'fullsessions', premiumSessionFilterStore);
                  }}
                >
                  {t('Hide full sessions').toUpperCase()}{' '}
                </Checkbox>
              </>
            </FormGroup>
          </Form>
        </Panel.Body>
      </Panel>
    );
  };

  const renderTimeFilterGroup = (id, key, title, store) => (
    <Panel eventKey={id} className="filter-panel">
      <Panel.Body className="filter-panel-body">
        <Form>
          <FormGroup>
            {store.filters[key].map(() => (
              <div
                key="timerange"
                className="date-control-wrapper"
                onClick={() => toggleCalendarTime()}
                role="button"
                tabIndex="0"
              >
                <>
                  <FormControl
                    data-analytics-id="time-range-filt-liveSess-lp"
                    type="text"
                    value={`${
                      premiumSessionFilterStore.timeRange.from
                        ? moment(
                            premiumSessionFilterStore.timeRange.from,
                          ).format('LT')
                        : t('Start time')
                    } - ${
                      premiumSessionFilterStore.timeRange.to
                        ? moment(premiumSessionFilterStore.timeRange.to).format(
                            'LT',
                          )
                        : t('End time')
                    }`}
                    placeholder="Select time range"
                    className="date-field"
                    disabled
                  />
                  <Button
                    className="date-btn"
                    data-analytics-id="time-calendar-btn-filt-liveSess-lp"
                  >
                    <SVG src={CalendarIcon} />
                  </Button>
                </>
              </div>
            ))}
          </FormGroup>
        </Form>
      </Panel.Body>
    </Panel>
  );

  /**
   * This groups up filter options with the same label
   */
  const renderClusterTypeFilter = (id, key, title, store) => {
    // TODO? Memoize this method...
    // TODO? Reuse this between catalog
    const groupedFilterOptions = groupBy(store.filters[key], 'value');
    return (
      <Panel
        eventKey={id}
        className="filter-panel"
        onToggle={(expanded) => {
          store.toggleFilterGroups(id, expanded);
        }}
        data-analytics-id={`${key}-filt-skilp-lp`}
      >
        <Panel.Toggle componentClass="div" className="filter-panel-toggle">
          <Panel.Heading
            componentClass="header"
            className="filter-panel-heading"
          >
            <Panel.Title className="filter-panel-heading-title">
              {title}
            </Panel.Title>
            <div className="filter-panel-heading-icon">
              <SVG src={isExpanded(id) ? CollapseIcon : ExpandIcon}>
                {isExpanded(id) ? t('Collapse') : t('Expand')}
              </SVG>
            </div>
          </Panel.Heading>
        </Panel.Toggle>
        <Panel.Body collapsible className="filter-panel-body">
          <Form>
            <FormGroup>
              {Object.keys(groupedFilterOptions).map((groupKey) => {
                const groupItems = groupedFilterOptions[groupKey];
                const groupSummary = reduce(
                  groupItems,
                  (acc, element) => {
                    return {
                      ...acc,
                      totalCount: acc.totalCount + element.count,
                      keyList: [...acc.keyList, element.key],
                      key: element.key, // does not matter much
                      label: element.value, // all items have same label
                    };
                  },
                  { totalCount: 0, keyList: [] },
                );
                return (
                  <div
                    key={groupSummary?.key}
                    className="filter-control-wrapper"
                    data-analytics-id={`container-${groupSummary?.key}-${key}-filt-skilp-lp`}
                  >
                    <Checkbox
                      checked={isChecked(key, groupSummary?.key)}
                      name={groupSummary?.key}
                      className="filter-control"
                      onChange={(e) => {
                        // For example, when you click "beginner", all values mapping to this label
                        // will be applied to filter, like 0, 1, 2 and 3.
                        onChange(e, groupSummary?.keyList, store);
                      }}
                      data-analytics-id={`${groupSummary?.key}-${key}-filt-skilp-lp`}
                    >
                      {groupSummary?.label}
                    </Checkbox>
                    <small className="filter-control-badge">
                      {groupSummary.totalCount}
                    </small>
                  </div>
                );
              })}
            </FormGroup>
          </Form>
        </Panel.Body>
      </Panel>
    );
  };

  const renderLegacyTaxonomyFilters = () => {
    return (
      <>
        {showProducts && premiumSessionFilterStore.filters.products.length
          ? renderFilterGroup(
              3,
              'products',
              t('Products'),
              premiumSessionFilterStore,
            )
          : null}
        {showCategories && premiumSessionFilterStore.filters.categories.length
          ? renderFilterGroup(
              4,
              'categories',
              t('Categories'),
              premiumSessionFilterStore,
            )
          : null}
      </>
    );
  };

  const renderNewTaxonomyFilters = () => {
    return (
      <>
        {premiumSessionFilterStore.filters.taxonomyProducts?.length
          ? renderFilterGroup(
              2,
              'taxonomyProducts',
              t('Products'),
              premiumSessionFilterStore,
            )
          : null}
        {premiumSessionFilterStore.filters.taxonomyTopics?.length
          ? renderFilterGroup(
              3,
              'taxonomyTopics',
              t('Topics'),
              premiumSessionFilterStore,
            )
          : null}
        {premiumSessionFilterStore.filters.taxonomyAudiences?.length
          ? renderFilterGroup(
              4,
              'taxonomyAudiences',
              t('Audiences'),
              premiumSessionFilterStore,
            )
          : null}
        {premiumSessionFilterStore.filters.level?.length
          ? renderClusterTypeFilter(
              5,
              'level',
              t('Levels'),
              premiumSessionFilterStore,
            )
          : null}
      </>
    );
  };

  return (
    <section className="premium-filters">
      <header className="filter-group-heading">{t('Filter by')}</header>
      <header
        data-analytics-id="filt-cancel-btn-liveSess-lp"
        className="filter-clear"
        role="button"
        tabIndex="0"
        onClick={(e) => {
          clearFilters(e);
        }}
      >
        {t('Clear all')}
      </header>
      <PanelGroup id="premium-filter-panel">
        {newTaxonomiesEnabled
          ? renderNewTaxonomyFilters()
          : renderLegacyTaxonomyFilters()}
        {premiumSessionFilterStore.filters.instructors.length
          ? renderFilterGroup(
              15,
              'instructors',
              t('Instructors'),
              premiumSessionFilterStore,
            )
          : null}

        {premiumSessionFilterStore.filters.languages.length
          ? renderFilterGroup(
              16,
              'languages',
              t('Languages'),
              premiumSessionFilterStore,
            )
          : null}

        {premiumSessionFilterStore.filters.daterange.length
          ? renderDateFilterGroup(
              17,
              'daterange',
              t('Select Date'),
              premiumSessionFilterStore,
            )
          : null}

        {premiumSessionFilterStore.filters.timerange.length
          ? renderTimeFilterGroup(
              17,
              'timerange',
              t('Select Time'),
              premiumSessionFilterStore,
            )
          : null}

        {showFullSeatsFilter && renderFullSessionFilter()}
      </PanelGroup>
      {userStore.isFreeTierSubscriber && (
        <div
          className="premVidFreeTierConatiner"
          data-analytics-id="prem-vid-free-tier-live-sessions-lp"
        >
          <KalturaWidget
            className="expert-extras-player"
            partnerId={KALTURA_CONFIGS.partnerId}
            playerId={KALTURA_CONFIGS.premiumInFreeTrial.uiConfId}
            entryId={KALTURA_CONFIGS.premiumInFreeTrial.entryId}
            isPlaylist={false}
          />
        </div>
      )}
      {/* <!-- Date Range Calendar Modal -->> */}
      {isCalendarOpen && (
        <Modal
          size="sm"
          show={isCalendarOpen}
          onHide={(e) => {
            handleDateTimeFilterChange(
              e,
              premiumSessionFilterStore,
              'daterange1',
            );
            toggleCalendar();
          }}
          dialogClassName="calendar-modal-dialog container"
        >
          <ModalHeader closeButton>
            <ModalTitle className="calendar-modal-title">
              {t('From')}: &nbsp;{' '}
              {`${
                from ? moment(from).format('MMM DD, YYYY') : t('Start date')
              }`}{' '}
              &nbsp; {t('To')}: &nbsp;
              {`${to ? moment(to).format('MMM DD, YYYY') : t('End date')}`}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="date-picker-wrapper">
              <FormGroup className="calendar-form">
                <DayPicker
                  className="Selectable"
                  numberOfMonths={numberOfMonths}
                  selectedDays={[from, { from, to }]}
                  modifiers={modifiers}
                  onDayClick={handleDayClick}
                />
                <div className="calendar-buttons">
                  <Button
                    className="clear-btn btn-outline-dark"
                    variant="outline-dark"
                    onClick={() => premiumSessionFilterStore.resetCalendar()}
                  >
                    {t('Reset')}
                  </Button>
                  <Button
                    className="close-btn"
                    onClick={(e) => {
                      handleDateTimeFilterChange(
                        e,
                        premiumSessionFilterStore,
                        'daterange1',
                      );
                      toggleCalendar();
                    }}
                  >
                    {t('Done')}
                  </Button>
                </div>
              </FormGroup>
            </div>
          </ModalBody>
        </Modal>
      )}

      {/* <!-- Time Range Calendar Modal -->> */}
      {isCalendarTimeOpen && (
        <Modal
          key="timerange"
          size="sm"
          show={isCalendarTimeOpen}
          onHide={(e) => {
            handleDateTimeFilterChange(
              e,
              premiumSessionFilterStore,
              'timerange1',
            );
            toggleCalendarTime();
          }}
          dialogClassName="calendar-modal-dialog container"
        >
          <ModalHeader closeButton>
            <ModalTitle className="calendar-modal-title">
              {t('Select Time Range')}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="time-picker-wrapper">
              <>
                <FormGroup>
                  <span>{t('StartTime')}</span>
                  <div key="2">
                    <>
                      <DateTimePicker
                        key="starttime"
                        options={{
                          inline: true,
                          mode: 'single',
                          defaultDate: premiumSessionFilterStore.timeRange.from
                            ? moment(
                                premiumSessionFilterStore.timeRange.from,
                              ).toDate()
                            : moment().startOf('day').toDate(),

                          enableTime: true,
                          noCalendar: true,
                        }}
                        value={
                          premiumSessionFilterStore.timeRange.from
                            ? moment(
                                premiumSessionFilterStore.timeRange.from,
                              ).toDate()
                            : moment().startOf('day').toDate()
                        }
                        onChange={(selectedDates) => {
                          handleTimeChange(selectedDates, true);
                        }}
                      />
                    </>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div key="3">
                    <>{t('EndTime')}</>
                  </div>
                  <div key="4">
                    <>
                      <DateTimePicker
                        key="endtime"
                        options={{
                          inline: true,
                          mode: 'single',
                          defaultDate: premiumSessionFilterStore.timeRange.to
                            ? moment(
                                premiumSessionFilterStore.timeRange.to,
                              ).toDate()
                            : moment().endOf('day').toDate(),

                          enableTime: true,
                          noCalendar: true,
                        }}
                        value={
                          premiumSessionFilterStore.timeRange.to
                            ? moment(
                                premiumSessionFilterStore.timeRange.to,
                              ).toDate()
                            : moment().endOf('day').toDate()
                        }
                        onChange={(selectedDates) => {
                          handleTimeChange(selectedDates, false);
                        }}
                      />
                    </>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button
                    className="clear-btn btn-outline-dark"
                    variant="outline-dark"
                    onClick={() =>
                      premiumSessionFilterStore.resetCalendarTime()
                    }
                  >
                    {t('Reset')}
                  </Button>
                  <Button
                    className="close-btn"
                    onClick={(e) => {
                      handleDateTimeFilterChange(
                        e,
                        premiumSessionFilterStore,
                        'timerange1',
                      );
                      toggleCalendarTime();
                    }}
                  >
                    {t('Done')}
                  </Button>
                </FormGroup>
              </>
            </div>
          </ModalBody>
        </Modal>
      )}
    </section>
  );
};

PremiumFilters.propTypes = {
  showCategories: PropTypes.bool,
  showProducts: PropTypes.bool,
  showFullSeatsFilter: PropTypes.bool,
};

PremiumFilters.defaultProps = {
  showCategories: false,
  showProducts: false,
  showFullSeatsFilter: false,
};

export default observer(PremiumFilters);
