import { observable, action, computed } from 'mobx';
import { DateUtils } from 'react-day-picker';

import { createContext } from 'react';
import {
  CUSTOM_SKILL_PATH_MODE,
  USER_SUBSCRIPTION_CODE,
} from 'config/constants';
import {
  createCustomSkillPath,
  updateCustomSkillPath,
  getCustomSkillPath,
} from 'services/LearningPathService';
import { Modality } from 'types';

class CustomSkillPathStore {
  constructor(
    catalogStore,
    learningPathStore,
    userStore,
    organizationStore,
    routerStore,
    vocabularyStore,
  ) {
    this.catalogStore = catalogStore;
    this.learningPathStore = learningPathStore;
    this.userStore = userStore;
    this.organizationStore = organizationStore;
    this.vocabularyStore = vocabularyStore;
    this.routerStore = routerStore;
  }

  customSkillPathValidation = {
    title: { length: 100 },
    description: { length: 200 },
    offerings: { length: 20 },
  };

  @observable customSkillPathMode = CUSTOM_SKILL_PATH_MODE.none;

  @observable apiUpdate = {
    inProgress: false,
    message: { body: '', title: '' },
    isError: false,
    showProgress: false,
  };

  @observable offeringToAdd = null;

  @observable selectedOffering = {};

  @observable customSkillPath = {
    title: '',
    description: '',
    offerings: [],
    target_users: [],
    end_date: undefined,
  };

  @observable customSkillPathOriginal = {};

  @observable showAddUserModal = false;

  @observable allowToAddUsersToPath = false;

  @observable userListToAdd = [];

  @observable loadingUserListToAdd = false;

  @computed get offerings() {
    return this.catalogStore.entries.map((o) => {
      const title = o.title?.replace?.('\u00a0', ' ') || '';

      return {
        label: `${o.code.toUpperCase()} - ${title}`,
        value: o,
      };
    });
  }

  @action async init(i18n, t) {
    try {
      const code = this.routerStore?.route?.params?.code || undefined;
      this.customSkillPathMode = code
        ? CUSTOM_SKILL_PATH_MODE.edit
        : (!this.isUserOrganisationLeader && CUSTOM_SKILL_PATH_MODE.create) ||
          CUSTOM_SKILL_PATH_MODE.none;

      this.allowFilters =
        this.customSkillPathMode !== CUSTOM_SKILL_PATH_MODE.none;

      if (code) {
        await this.getCustomSkillPath(code);
      }

      this.allowToAddUsersToPath = code
        ? this.targetUsersInPath?.length > 0
        : this.isUserOrganisationLeader;
      if (this.allowToAddUsersToPath) {
        await this.getUsersListToAddInPath(false);
      }

      this.catalogStore.removeModalityFromCatalog([Modality.ExpertSeminar]);
      if (t && i18n) this.catalogStore.getFilterInfo();
    } catch {
      // set csk to create mode
      this.customSkillPathMode = CUSTOM_SKILL_PATH_MODE.none;
      this.allowFilters = false;
      this.allowToAddUsersToPath = false;
    }
  }

  @action setOffering(o) {
    this.selectedOffering = o;
  }

  @action toggleDayPickerInput(visibility) {
    this.isCalendarOpen = visibility;
  }

  @action setCustomSkillPath(csk) {
    this.customSkillPath.title = csk.title || '';
    this.customSkillPath.description =
      csk.description?.body?.replace(/(<([^>]+)>)/gi, '') || '';

    this.customSkillPath.offerings =
      // eslint-disable-next-line camelcase
      csk.lp_elements?.map((lp) => {
        return lp.code;
      }) || [];
    if (csk.code) this.customSkillPath.code = csk.code;
    this.customSkillPath.end_date = csk.end_date || undefined;
    // eslint-disable-next-line camelcase
    this.customSkillPath.target_users = csk.target_users || [];
    if (csk.target_subscriptions?.length > 0)
      this.setTargetSubscription(csk.target_subscriptions[0]);
  }

  @action addOfferingsToPath(offeringCode) {
    const found = this.customSkillPath.offerings?.find((code) => {
      return code === offeringCode;
    });

    if (!found && offeringCode) {
      this.customSkillPath.offerings.push(offeringCode);
    }
    this.selectedOffering = {};
  }

  @action removeOfferingsFromPath(offeringCodeToRemove) {
    const remamingOfferings = this.customSkillPath.offerings?.filter(
      (code) => code !== offeringCodeToRemove,
    );
    this.customSkillPath.offerings = remamingOfferings;
  }

  @computed get isTitleValid() {
    const titleLength = this.customSkillPath.title.length || 0;
    return (
      titleLength > 0 &&
      titleLength <= this.customSkillPathValidation.title.length
    );
  }

  @computed get isDescriptionValid() {
    const titleLength = this.customSkillPath.description.length || 0;
    return titleLength <= this.customSkillPathValidation.description.length;
  }

  @computed get isOfferingsValid() {
    const offeringsInPathLength = this.customSkillPath.offerings.length || 0;
    return (
      offeringsInPathLength > 0 &&
      offeringsInPathLength <= this.customSkillPathValidation.offerings.length
    );
  }

  @computed get isEndDateValid() {
    const endDate = this.customSkillPath.end_date;

    if (endDate !== undefined) {
      return DateUtils.isFutureDay(new Date(endDate));
    }
    return true;
  }

  @computed get isUsersSelectionValid() {
    return this.allowToAddUsersToPath
      ? this.targetUsersInPath?.length > 0
      : true;
  }

  @computed get isAllValid() {
    return (
      this.isTitleValid &&
      this.isDescriptionValid &&
      this.isOfferingsValid &&
      this.isEndDateValid &&
      this.isUsersSelectionValid
    );
  }

  @computed get totalTargetUsers() {
    // eslint-disable-next-line camelcase
    return this.customSkillPath?.target_users?.length || 0;
  }

  @computed get targetUsersInPath() {
    // eslint-disable-next-line prefer-const
    let users = [];
    // eslint-disable-next-line camelcase
    if (
      this.customSkillPath.target_users &&
      this.customSkillPath.target_users.forEach
    )
      this.customSkillPath.target_users.forEach((user) => {
        users.push(user.username);
      });

    return users;
  }

  @action handleInputChange(e, input) {
    this.customSkillPath[input] = e.target.value;
  }

  @action cancelCustomSkillPath = () => {
    if (this.customSkillPathMode === CUSTOM_SKILL_PATH_MODE.edit)
      this.setCustomSkillPath(this.customSkillPathOriginal);
    else this.setCustomSkillPath({});
  };

  @action toggleAPIProgress() {
    this.apiUpdate.showProgress = !this.apiUpdate.showProgress;
  }

  @computed get customSkillPathTitle() {
    return !this.customSkillPath.title.toLowerCase().trim().startsWith('custom')
      ? `Custom ${this.customSkillPath.title}`
      : this.customSkillPath.title;
  }

  @observable allowFilters = false;

  @computed get allowToSelectCustomPathType() {
    return this.isUserOrganisationLeader && !this.allowFilters;
  }

  @computed get isUserOrganisationLeader() {
    return (
      this.userStore.isOrganizationAdmin || this.userStore.isOrganizationManager
    );
  }

  @computed get targetSubscriptionText() {
    return this.targetSubscription
      ? USER_SUBSCRIPTION_CODE[this.targetSubscription]
      : null;
  }

  @computed get targetSubscription() {
    // eslint-disable-next-line camelcase
    return this.customSkillPath?.target_subscriptions?.length > 0
      ? this.customSkillPath.target_subscriptions[0]
      : null;
  }

  @action saveCustomSkillPath = async () => {
    if (!this.isAllValid) return;
    this.apiUpdate.isError = false;
    try {
      const cskpToSave = {
        title: this.customSkillPath.title,
        ...(this.customSkillPath.target_users?.length > 0 && {
          target_users: this.customSkillPath.target_users,
        }),
        target_subscriptions: this.targetSubscription
          ? [this.targetSubscription]
          : [this.userStore.currentSubscriptionCode],
        ...(this.customSkillPath.description !== '' && {
          description: {
            content_type: 'text/x-safe-html',
            body: this.customSkillPath.description,
          },
        }),
        ...(this.customSkillPath.offerings?.length > 0 && {
          lp_elements: this.customSkillPath.offerings?.map((code) => {
            return { code };
          }),
        }),
        ...(this.customSkillPath.end_date && {
          end_date: this.customSkillPath.end_date,
        }),
      };

      this.apiUpdate.inProgress = true;

      if (this.customSkillPathMode === CUSTOM_SKILL_PATH_MODE.create) {
        await createCustomSkillPath(cskpToSave);
      } else {
        await updateCustomSkillPath(cskpToSave, this.customSkillPath.code);
      }
    } catch {
      this.apiUpdate.isError = true;
    } finally {
      this.apiUpdate.inProgress = false;
      this.toggleAPIProgress();
    }
  };

  @action getCustomSkillPath = async (code) => {
    if (!code) return;
    this.apiUpdate.inProgress = true;
    try {
      const skillPath = await getCustomSkillPath(code);
      if (skillPath) {
        this.customSkillPathOriginal = skillPath;
        this.setCustomSkillPath(skillPath);
        await this.catalogStore.getCatalogEntriesBySubscrition(
          this.targetSubscription,
          this.targetSubscription !== this.userStore.currentSubscriptionCode,
        );
      }
    } catch {
      this.customSkillPathOriginal = {};
    } finally {
      this.apiUpdate.inProgress = false;
    }
  };

  @action toggleAddUserToPathModal() {
    this.showAddUserModal = !this.showAddUserModal;
  }

  @action getUsersListToAddInPath = async (force) => {
    if (this.userListToAdd.length > 0 && !force) {
      return this.userListToAdd;
    }
    this.loadingUserListToAdd = true;
    try {
      const organizations = this.organizationStore.filteredOrganizations(
        this.userStore.isOrganizationAdmin ? 'admin' : 'manager',
      );
      this.userListToAdd =
        await this.organizationStore.getUsersByOrgIdsAndSubscription(
          organizations?.map((o) => o.doc_id),
        );
    } catch {
      this.userListToAdd = [];
    } finally {
      this.loadingUserListToAdd = false;
      return this.userListToAdd;
    }
  };

  @computed get uniqueSubscriptionInUserListToAdd() {
    return this.userListToAdd
      ?.map((u) => u.subscription)
      ?.filter(
        (subscription, index, self) => self.indexOf(subscription) === index,
      );
  }

  @action setUsersInPath(selectedUsers) {
    this.customSkillPath.target_users = [];
    selectedUsers.forEach((user) => {
      this.customSkillPath.target_users.push({
        username: user.username,
        org_id: user.org_id,
      });
    });
  }

  @action setTargetSubscription(subscriptionCode) {
    this.customSkillPath.target_subscriptions = subscriptionCode
      ? [subscriptionCode]
      : [];
  }

  @action setCustomSkillPathMode(mode) {
    this.customSkillPathMode = mode;
  }

  @action resetCustomPathFlow() {
    if (this.customSkillPathMode === CUSTOM_SKILL_PATH_MODE.edit) {
      this.routerStore.navigate('skills-paths');
      return;
    }
    this.allowFilters = !this.isUserOrganisationLeader;

    this.customSkillPathMode = this.isUserOrganisationLeader
      ? CUSTOM_SKILL_PATH_MODE.none
      : CUSTOM_SKILL_PATH_MODE.create;
  }

  @action onOfferingOrderChange(offering, index) {
    return (event) => {
      const direction = event.currentTarget.id;

      let shouldNotMove = true;

      if (direction === 'up') {
        shouldNotMove = this.customSkillPath.offerings[0] === offering.code;
      } else if (direction === 'down') {
        const { length } = this.customSkillPath.offerings;

        shouldNotMove =
          this.customSkillPath.offerings[length - 1] === offering.code;
      }

      if (shouldNotMove) return;

      const newIndexByDirection = {
        up: index - 1,
        down: index + 1,
      };

      const newIndex = newIndexByDirection[direction] || 0;

      const oldOfferingAtNewIndex = this.customSkillPath.offerings[newIndex];

      this.setOfferingsNewOrder(
        newIndex,
        index,
        oldOfferingAtNewIndex,
        offering.code,
      );
    };
  }

  @action setOfferingsNewOrder(
    newPosition,
    oldPosition,
    oldOfferingAtNewPosition,
    offeringToMove,
  ) {
    const newOrderedOfferings = this.customSkillPath.offerings.slice();

    newOrderedOfferings[newPosition] = offeringToMove;
    newOrderedOfferings[oldPosition] = oldOfferingAtNewPosition;

    this.customSkillPath.offerings = newOrderedOfferings;
  }
}

export default CustomSkillPathStore;
export const customSkillPathContext = createContext(null);
