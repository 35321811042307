import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  useFeature,
  CenteredLoadingSpinner,
  useViewportSize,
} from '@ole-ui/ole-ui-components';
import {
  Button,
  Tabs,
  Tab,
  TabTitleText,
  EmptyState,
  Title,
  EmptyStateBody,
} from '@patternfly/react-core';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';
import AdminDashboardComponent from '../Admin';
import ManagerDashboardComponent from '../Manager';
import SummaryDashboardComponent from '../Summary';
import ScheduledReports from '../ScheduledReports';
import MgmtCustomSkillPaths from '../CustomSkillPaths';
import './styles.scss';
import { ManagementDashboardTab } from '../types';

const MgmtOverviewNav = ({ setActiveTab, activeTab }) => {
  const { t } = useTranslation();
  const { organizationStore } = useContext(storesContext);
  const {
    organization,
    isLeaderOfCurrentlySelectedOrg,
    isAdminOfCurrentlySelectedOrg,
  } = organizationStore;

  const displayVertical = useViewportSize().isLowerThanMd;

  const isOrgScheduledReportsEnabled = useFeature({
    section: 'mgmt_overview',
    flagName: 'scheduled_reports',
    defaultFlagValue: false,
  });

  const handleTabClick = (event, tabIndex) => {
    setActiveTab(tabIndex);
  };

  if (organizationStore.isLoadingOrganizations) {
    return (
      <div className="mgmt-dashboard-loading">
        <CenteredLoadingSpinner />
      </div>
    );
  }

  if (organizationStore.didFailToFetchOrganizations) {
    return (
      <div>
        <EmptyState>
          <Title size="lg" headingLevel="h4">
            {t('Error loading dashboard')}
          </Title>
          <EmptyStateBody>
            {t(
              'Our team is now aware of the issue. If the issue persists for too long, please contact support.',
            )}
          </EmptyStateBody>
          <Button
            variant="link"
            type="button"
            onClick={organizationStore.retryLoadingOrganizations}
          >
            {t('Retry')}
          </Button>
        </EmptyState>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="mgmt-dashboard-container container-grid-responsive">
        <Tabs
          mountOnEnter
          activeKey={activeTab}
          onSelect={handleTabClick}
          aria-label="Management Dashboard"
          role="region"
          className="mgmt-dashboard-tabs"
          isVertical={displayVertical}
        >
          {isLeaderOfCurrentlySelectedOrg && (
            <Tab
              eventKey={ManagementDashboardTab.Summary}
              title={<TabTitleText>{t('Summary')}</TabTitleText>}
              aria-label="Summary"
              data-analytics-id="summary-tab-mgmnt-dboard-lp"
            >
              <SummaryDashboardComponent />
            </Tab>
          )}
          {isAdminOfCurrentlySelectedOrg && (
            <Tab
              eventKey={ManagementDashboardTab.Org}
              title={<TabTitleText>{t('Organization')}</TabTitleText>}
              data-analytics-id="admin-tab-mgmnt-dboard-lp"
            >
              <AdminDashboardComponent />
            </Tab>
          )}
          {isLeaderOfCurrentlySelectedOrg && (
            <Tab
              eventKey={ManagementDashboardTab.Team}
              title={<TabTitleText>{t('Team')}</TabTitleText>}
              data-analytics-id="team-tab-mgmnt-dboard-lp"
            >
              <ManagerDashboardComponent
                hideConsolidatedCSV={isOrgScheduledReportsEnabled}
              />
            </Tab>
          )}
          {isLeaderOfCurrentlySelectedOrg && (
            <Tab
              eventKey={ManagementDashboardTab.CustomSP}
              title={<TabTitleText>{t('Custom Skills Path')}</TabTitleText>}
              data-analytics-id="custom-skp-tab-mgmnt-dboard-lp"
            >
              <MgmtCustomSkillPaths />
            </Tab>
          )}
          {isLeaderOfCurrentlySelectedOrg && isOrgScheduledReportsEnabled && (
            <Tab
              isAriaDisabled={!organization?.id}
              eventKey={ManagementDashboardTab.Reports}
              title={<TabTitleText>{t('Reports')}</TabTitleText>}
              data-analytics-id="reports-tab-mgmnt-dboard-lp"
            >
              <ScheduledReports />
            </Tab>
          )}
        </Tabs>
      </div>
    </React.Fragment>
  );
};

MgmtOverviewNav.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
};

export default withErrorBoundary(
  observer(MgmtOverviewNav),
  GenericViewError,
  handleError,
);
