import { observable, computed, action, autorun, toJS } from 'mobx';
import { get, post, put } from 'axios';
import _ from 'lodash';
import { createContext } from 'react';
import {
  USER_LAB_API,
  INHIBIT_LAB_START_API,
  KALTURA_CONFIGS,
  LAB_REGIONS,
} from '../../config/constants';

import {
  getLabInformation,
  getOspHeatTemplates,
} from '../../services/LabService';

class LabEnvironmentStore {
  @observable courseSlug = null;

  @observable labDefinition = {};

  @observable currentEnrollment = {};

  @observable targetEnrollment = {};

  @observable _currentTemplate = 0;

  @observable userLab = {};

  @observable isUserLabReady = false;

  @observable lastRequestedCommand = null;

  @observable pollIntervalSecs = 0;

  @observable bypassSlugCheck = false;

  @observable labInformation = {};

  @observable currentLanguage = 'en-US';

  @observable ospHeatTemplates = [];

  @observable powerUserRegion = 'us-east-1';

  @observable powerUserHeatTemplate = '';

  @observable internetAccessDisconnectedSwitchChecked = null;

  @observable internetAccessCommandRequested = false;

  constructor(userStore) {
    this.userStore = userStore;
  }

  @observable _modal = {
    opened: false,
    working: false,
    title: '',
    confirmText: null,
    dismissText: '',
    confirmFunc: () => {},
  };

  @observable _inhibitLabInfo = { allow_lab_start: true, reason: '' };

  @computed get currentTemplate() {
    if (!this.availableTemplates.length) {
      return null;
    }

    return this.availableTemplates[this._currentTemplate];
  }

  @computed get hasUserLabParameters() {
    return this.labDefinition.user_lab_parameters?.length > 0;
  }

  @action setCurrentTemplate(index) {
    this._currentTemplate = index;
  }

  @computed get availableTemplates() {
    const components = this.labDefinition.components
      ? toJS(this.labDefinition.components)
      : [];
    /* eslint-disable camelcase */
    if (
      components.length &&
      (components[0].osp_templates || components[0].osp_heat_templates) &&
      (components[0].osp_templates?.length ||
        components[0].osp_heat_templates?.length)
    ) {
      return components[0].osp_templates || components[0].osp_heat_templates;
    }

    return [];
  }

  @computed get openStackComponent() {
    const { components } = this.userLab;
    return components && components.length
      ? components.find((c) => c.driver === 'openstack')
      : {};
  }

  @computed get openShiftComponent() {
    const { components } = this.userLab;
    return components && components.length
      ? components.find((c) => c.driver === 'shared_ocp')
      : {};
  }

  @computed get userCredentialMap() {
    return _.get(this.userLab, 'ssh_enabled', false)
      ? _.get(this.openStackComponent, 'context.user_credential_map', {})
      : {};
  }

  @computed get publicIp() {
    return _.get(this.openStackComponent, 'context.public_ip', null);
  }

  @computed get classroomWebapp() {
    return _.get(this.userLab, 'classroom_webapp', {});
  }

  @computed get webApplications() {
    return _.get(this.userLab, 'web_applications', []);
  }

  @computed get modal() {
    return this._modal;
  }

  @computed get cleanCourseSlug() {
    return this.courseSlug?.replace?.('vc-', '-');
  }

  @computed get convertedComponents() {
    return this.userLab.components.map((component) => ({
      ...component,
      events:
        component.events &&
        component.events.flatMap((event) => Object.values(event)),
    }));
  }

  @action createNewModal(modal, autoOpen = false) {
    this._modal.opened = autoOpen;
    this._modal.working = false;
    this._modal.title = modal.title ? modal.title : '';
    this._modal.confirmText = modal.confirmText ? modal.confirmText : null;
    this._modal.dismissText = modal.dismissText ? modal.dismissText : 'Ok';
    this._modal.confirmFunc = modal.confirmFunc ? modal.confirmFunc : () => {};
  }

  @action setModalWorking(working) {
    this._modal.working = working;
  }

  @action hideModal() {
    this._modal.opened = false;
  }

  @action modalDoConfirm() {
    if (this._modal.confirmFunc) this._modal.confirmFunc();
  }

  @action setInhibitLabInfo = async () => {
    // inhibit lab start should not apply for VT lab start
    const currentEuuid = this.currentEnrollment.uuid;
    const targetEuuid = this.targetEnrollment.uuid;

    // Checking for inhibit lab info only when starting a lab is possible
    if (
      !(currentEuuid || targetEuuid) &&
      (this.labState === 'ready' || this.labState === 'deleted') &&
      !(
        !this._inhibitLabInfo.allow_lab_start &&
        this._inhibitLabInfo.reason === 'throttled'
      )
    ) {
      const url = `${INHIBIT_LAB_START_API}?offering_slug=${this.cleanCourseSlug}`;
      try {
        const result = await get(url);
        this._inhibitLabInfo = result.data;
      } catch (error) {
        console.warn('Error checking for labs availability');
      }
    }
  };

  pollIntervalId = null;

  fetchUserLabAutorun = autorun(async () => {
    if (this.bypassSlugCheck || this.courseSlug) {
      // Wait for the lab info to be fetched
      await this.fetchUserLab();
      // If fetched, execute again to immediately refresh possibly stale states.
      if (this.userLab) {
        this.fetchUserLab();
      }
    }
  });

  pollingActiveAutorun = autorun(() => {
    const delay = this.pollIntervalSecs * 1000;
    if (this.pollIntervalId) {
      clearInterval(this.pollIntervalId);
    }
    if (delay) {
      this.pollIntervalId = setInterval(this.fetchUserLab, delay);
    }
  });

  @action fetchUserLab = async () => {
    const userLabId = this.userLab.doc_id;
    const userLabOfferingSlug = this.userLab.offering_slug;
    const sharedOfferingSlugs = this.userLab.shared_offering_slugs_list;
    const currentEuuid = this.currentEnrollment.uuid;
    const targetEuuid = this.targetEnrollment.uuid;

    if (sharedOfferingSlugs?.length > 0) {
      this.bypassSlugCheck = true;
    }

    if (!userLabId) {
      let url = `${USER_LAB_API}?offering=${this.cleanCourseSlug}`;
      if (currentEuuid) {
        url += `&current_euuid=${currentEuuid}`;
      }
      if (targetEuuid) {
        url += `&target_euuid=${targetEuuid}`;
      }
      try {
        const result = await get(url);
        if (result.data.items && result.data.items.length) {
          [this.userLab] = result.data.items;
        } else {
          this.userLab = {};
        }
      } catch (error) {
        if (error.response?.status === 403) {
          console.warn('disabling polling due to session timeout');
          this.pollIntervalSecs = 0;
        }
      }
    } else if (
      this.bypassSlugCheck ||
      this.cleanCourseSlug === userLabOfferingSlug
    ) {
      const url = `${USER_LAB_API}${userLabId}`;
      try {
        const result = await get(url);
        this.userLab = result.data;
        this.isUserLabReady = true;
      } catch (error) {
        if (error.response?.status === 403) {
          console.warn('disabling polling due to session timeout');
          this.pollIntervalSecs = 0;
        }
      }
    }
  };

  @action doCommand = async (command, params) => {
    this.lastRequestedCommand = command;

    if (command === 'launch') {
      return this.doLaunch(params);
    }

    if (!this.userLab.doc_id) {
      console.warn('no active lab.');
      return {};
    }

    this.internetAccessCommandRequested = command === 'internet_access';

    const url = `${USER_LAB_API}${this.userLab.doc_id}`;
    const result = await put(url, { command, params });

    if (result.data?.status === 'S200') {
      /* eslint-disable camelcase */
      if (result.data?.user_lab?.doc_id && command !== 'app_stop') {
        /* eslint-disable camelcase */
        this.userLab = result.data.user_lab;
      }
    } else if (result.data?.status === 'E529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'throttled';
      console.warn('Cannot start labs: throttled');
    } else if (result.data?.status === 'UE529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'user_throttled';
      console.warn('Cannot start labs: user_throttled');
    } else if (result.data?.status === 'NA529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'lab_offering_not_allowed';
      console.warn('Cannot start labs: lab_offering_not_allowed');
    } else if (result.data?.status === 'LU529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'lab_quota_limit_reached';
      console.warn('Cannot start labs: lab_quota_limit_reached');
    }

    this.internetAccessCommandRequested = false;
    return result;
  };

  @action doLaunch = async (params) => {
    if (this.userLab.doc_id) {
      console.warn('lab already exists.');
      return {};
    }

    const url = USER_LAB_API;
    let data = {
      command: 'launch',
      offering_slug: this.courseSlug,
      current_euuid: this.currentEnrollment.uuid,
      target_euuid: this.targetEnrollment.uuid,
      ...(this.currentTemplate
        ? {
            child_offering_slug:
              this.currentTemplate?.child_offering_slug ||
              this.currentTemplate?.id,
          }
        : {}),
      params,
    };

    if (this.powerUserHeatTemplate) {
      const powerUserData = {
        power_user_heat_template: this.powerUserHeatTemplate,
        power_user_region: this.powerUserRegion || 'us-east-1',
        disable_internet_access:
          this.internetAccessDisconnectedSwitchChecked === null
            ? false
            : this.internetAccessDisconnectedSwitchChecked,
      };

      data = { ...data, ...powerUserData };

      if (data.child_offering_slug) {
        delete data.child_offering_slug;
      }
    }

    const result = await post(url, data);

    // this logic is shared with doCommand above,
    // and should be combined...
    if (result.data?.status === 'S200') {
      /* eslint-disable camelcase */
      if (result.data?.user_lab?.doc_id) {
        /* eslint-disable camelcase */
        this.userLab = result.data.user_lab;
      }
    } else if (result.data?.status === 'E529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'throttled';
      console.warn('Cannot start labs: throttled');
    } else if (result.data?.status === 'UE529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'user_throttled';
      console.warn('Cannot start labs: user_throttled');
    } else if (result.data?.status === 'NA529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'lab_offering_not_allowed';
      console.warn('Cannot start labs: lab_offering_not_allowed');
    } else if (result.data?.status === 'LU529') {
      this._inhibitLabInfo.allow_lab_start = false;
      this._inhibitLabInfo.reason = 'lab_quota_limit_reached';
      console.warn('Cannot start labs: lab_quota_limit_reached');
    }

    return result;
  };

  @action getLabInformation = async (language) => {
    this.labInformation = '';
    return getLabInformation(this.courseSlug, language)
      .then((data) => {
        this.labInformation = data;
      })
      .catch((error) => {
        throw error;
      });
  };

  @action getOspHeatTemplates = async () => {
    try {
      this.ospHeatTemplates = await getOspHeatTemplates();
    } catch (error) {
      console.error('Fail to fetch getOspHeatTemplates', error);
      throw new Error(`Failed to fetch data: ${error.message}`);
    }
  };

  @computed get orientationInformation() {
    const text = this.labInformation?.text;
    if (text) {
      const htmlParser = new DOMParser();
      const node = htmlParser.parseFromString(text, 'text/html');
      return node.body.innerHTML;
    }
    return '';
  }

  @computed get orientationVideo() {
    const videosForOrientation =
      this.labInformation?.orientation_video?.entries || [];
    const _defaultLanguage = 'en-US';
    const _currentLanguage = this.currentLanguage || _defaultLanguage;

    let videosForOrientationByLanguage =
      videosForOrientation?.length > 0 &&
      videosForOrientation?.filter((v) => v.language === _currentLanguage);
    videosForOrientationByLanguage =
      videosForOrientationByLanguage?.length > 0
        ? videosForOrientationByLanguage
        : videosForOrientation?.filter((v) => v.language === _defaultLanguage);

    return videosForOrientation?.length > 0
      ? (videosForOrientationByLanguage.length > 0 &&
          videosForOrientationByLanguage[0]) ||
          videosForOrientation[0]
      : null;
  }

  @computed get orientationVideoPlayerId() {
    return (
      this.labInformation?.orientation_video?.custom_player_id ||
      KALTURA_CONFIGS.player.uiConfId
    );
  }

  @computed get labState() {
    if (
      !this.canLabStart &&
      [
        'throttled',
        'user_throttled',
        'lab_offering_not_allowed',
        'lab_quota_limit_reached',
      ].includes(this.reasonLabCantStart) &&
      this.lastRequestedCommand === 'app_start'
    ) {
      return this.userLab.state;
    }

    if (
      !this.canLabStart &&
      [
        'throttled',
        'user_throttled',
        'lab_offering_not_allowed',
        'lab_quota_limit_reached',
      ].includes(this.reasonLabCantStart)
    ) {
      return 'ready';
    }

    if (!this.userLab.state) {
      if (this.lastRequestedCommand === 'launch') return 'requested';
      return 'ready';
    }

    if (
      this.userLab.state === 'running' &&
      (this.lastRequestedCommand === 'app_stop' ||
        this.userLab.desired_state === 'stopped')
    )
      return 'stopping';
    if (
      (this.userLab.state === 'running' || this.userLab.state === 'stopped') &&
      (this.lastRequestedCommand === 'app_delete' ||
        this.userLab.desired_state === 'deleted')
    )
      return 'deleting';
    if (
      this.userLab.state === 'stopped' &&
      (this.lastRequestedCommand === 'app_start' ||
        this.userLab.desired_state === 'running')
    )
      return 'starting';
    if (
      ['requested', 'transitioning', 'installing'].includes(
        this.userLab.state,
      ) &&
      (this.lastRequestedCommand === 'launch' ||
        this.userLab.desired_state === 'running')
    )
      return 'creating';

    return this.userLab.state;
  }

  @computed get labDeleteThreshold() {
    // after the threshold is reached, a button to extend autodelete will disappear
    if (
      this.courseSlug.startsWith('do280') ||
      this.courseSlug.startsWith('do417') ||
      this.courseSlug.startsWith('do380') ||
      this.courseSlug.startsWith('do480') ||
      this.courseSlug.startsWith('rh445')
    ) {
      return 7;
    }
    return 14;
  }

  @computed get canLabStart() {
    return this._inhibitLabInfo.allow_lab_start;
  }

  @computed get reasonLabCantStart() {
    if (!this.canLabStart) {
      return this._inhibitLabInfo.reason;
    }

    return undefined;
  }

  regionsFilterOptions = () => {
    return Object.keys(LAB_REGIONS).map((key) => ({
      value: key,
      label: LAB_REGIONS[key],
    }));
  };

  @computed get regionsFilterValue() {
    if (this.userLab?.power_user_region)
      return {
        value: this.regionsFilterOptions().find(
          (region) => region.value === this.userLab.power_user_region,
        ),
      };

    return {};
  }

  @computed get heatTemplatesOptions() {
    return this.ospHeatTemplates.length > 0
      ? this.ospHeatTemplates.map((template) => {
          return { label: template, value: template };
        })
      : [];
  }

  @computed get heatTemplateFilterValue() {
    if (this.userLab?.power_user_heat_template)
      return {
        value: this.heatTemplatesOptions.find(
          (template) =>
            template.value === this.userLab.power_user_heat_template,
        ),
      };

    return {};
  }

  @computed get internetAccessDisconnected() {
    if (
      this.internetAccessCommandRequested === false &&
      this.userLab?.internet_access !== undefined
    ) {
      return !this.userLab.internet_access;
    }

    return this.internetAccessDisconnectedSwitchChecked === null
      ? false
      : this.internetAccessDisconnectedSwitchChecked;
  }

  @computed get internetAccessSwitchDisable() {
    // the switch is enabled if no user lab
    if (!this.userLab.doc_id) {
      return false;
    }

    // enable or disable internet access only when ssh is not true
    if (this.userLab?.ssh_enabled === true) {
      return true;
    }

    return ['creating', 'starting', 'stopping', 'deleting'].includes(
      this.labState,
    );
  }

  @computed get hasLabHours() {
    const currentEuuid = this.currentEnrollment.uuid;
    const targetEuuid = this.targetEnrollment.uuid;

    // user has lab hours should not apply for VT lab start
    if (currentEuuid || targetEuuid) {
      return true;
    }

    return this.userStore.hasLabHours || false;
  }
}

export default LabEnvironmentStore;
export const labEnvironmentContext = createContext(null);
