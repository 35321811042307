/**
 * @file Course Catalog view allowing filtering and searching for courses
 * @author Joshua Jack <jjack@redhat.com>
 */

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reaction, when } from 'mobx';
import { observer } from 'mobx-react';
import { CenteredLoadingSpinner } from '@ole-ui/ole-ui-components';
import {
  Title,
  EmptyState,
  EmptyStateIcon,
  EmptyStateBody,
} from '@patternfly/react-core';
import CatalogIcon from '@patternfly/react-icons/dist/esm/icons/catalog-icon';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import Filters from 'components/Catalog/Filters';
import { storesContext } from 'stores';
import SearchAutosuggest from 'components/Search';
import FlippingBanner from 'components/Banners/FlippingBanner';
import { Modality } from 'types';

import './styles.scss';

const ProgressCatalogCard = React.lazy(() =>
  import(/* webpackChunkName: 'catalog' */ './ProgressCatalogCard'),
);

const ExamCard = React.lazy(() =>
  import(/* webpackChunkName: 'exam-card' */ 'components/Exam/Card'),
);

const Catalog = () => {
  const { t } = useTranslation();
  const { catalogStore, routerStore, searchStore, uiStore, userStore } =
    useContext(storesContext);

  uiStore.title = routerStore?.route?.params?.title;

  const { currentPage, paginatedEntries, setCurrentPage, totalPages, loaded } =
    catalogStore;

  const { title } = uiStore;
  document.title = t(title);

  const presetFilters = () => {
    catalogStore.clearFilters();
    if (routerStore?.route?.params?.q) {
      searchStore.query = routerStore?.route?.params?.q;
    } else {
      searchStore.query = '';
    }
    catalogStore.presetFilters(routerStore?.route?.params?.filter);
  };

  useEffect(() => {
    // Populate entry data and filters on initial load
    when(
      () => catalogStore.loaded && userStore.didFetchSubscription,
      async () => {
        if (userStore.hasActiveSubscription) {
          try {
            const entries = await catalogStore.getCurrentSubscriptionCatalog();

            if (entries) {
              await catalogStore.getFilterInfo();
            }
          } catch (e) {
            console.error(e);
          }
        }
      },
    );

    // Select preset filters on initial load
    when(
      () => catalogStore.filterInfoLoaded && routerStore?.route?.name,
      () => presetFilters(),
    );

    // Update preset filters when route changes
    reaction(
      () => catalogStore.filterInfoLoaded && routerStore?.route?.name,
      () => presetFilters(),
    );

    reaction(
      () => catalogStore.filterInfoLoaded && routerStore?.route?.params?.q,
      () => catalogStore.filterEntries(),
    );
  }, []);

  if (!loaded) {
    return (
      <div className="catalog__loading-container">
        <CenteredLoadingSpinner />
      </div>
    );
  }

  return (
    <div className="catalog container-grid-responsive">
      <React.Suspense
        fallback={
          <div className="catalog__loading-container">
            <CenteredLoadingSpinner />
          </div>
        }
      >
        <div className="catalog__search">
          <SearchAutosuggest />
        </div>
        {catalogStore?.filters?.earlyAccess?.length > 0 &&
        catalogStore?.filters?.earlyAccess[0]?.checked ? (
          <FlippingBanner banner="earlyAccess" />
        ) : (
          ''
        )}
        <div className="catalog__content">
          <Filters />
          <section className="catalog__list-container">
            {paginatedEntries.length ? (
              <React.Fragment>
                <div className="catalog__list">
                  {paginatedEntries.map((entry) => {
                    const { doc_id: docId } = entry;

                    if (entry.modality === Modality.Exam) {
                      return <ExamCard entry={entry} key={docId} />;
                    }

                    return <ProgressCatalogCard entry={entry} key={docId} />;
                  })}
                </div>
                <div className="catalog__list-pagination">
                  <UltimatePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onChange={setCurrentPage}
                  />
                </div>
              </React.Fragment>
            ) : (
              <EmptyState>
                <EmptyStateIcon icon={CatalogIcon} />
                <Title size="lg" headingLevel="h4">
                  {t('No catalog entries found')}
                </Title>
                <EmptyStateBody>
                  {t(
                    'No results match the filter criteria. Clear all filters and try again.',
                  )}
                </EmptyStateBody>
              </EmptyState>
            )}
          </section>
        </div>
      </React.Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(Catalog),
  GenericViewError,
  handleError,
);
