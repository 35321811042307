/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withErrorBoundary } from 'react-error-boundary';
import ExclamationTriangleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';
import { Icon } from '@patternfly/react-core';
import { storesContext } from 'stores';
import { handleError } from 'services/ErrorService';
import ComponentError from 'components/Error/ComponentError';
import { useFeature } from '@ole-ui/ole-ui-components';
import './styles.scss';

const SubscriptionWidget = () => {
  const { t } = useTranslation();
  const { userStore, classesStore, routerStore } = useContext(
    storesContext,
  ) as any;
  const {
    enablementsQuotas,
    subscriptionDaysLeft,
    labHoursLeft,
    enforceLabLimit,
  } = userStore;
  const { standard } = classesStore;
  const { route } = routerStore;
  const {
    pdf_downloads: pdfDownloads,
    exams,
    claims_allowed: claimsAllowed,
    claims_consumed: claimsConsumed,
  } = enablementsQuotas || {};

  const isBookshelfEnabled = useFeature({
    section: 'course',
    flagName: 'bookshelf_button_active',
    defaultFlagValue: false,
  });

  const getSubscriptionDays = () => {
    const currentOffering = standard.find(
      (offering) => offering.slug_tag === route.params.course,
    );

    if (!currentOffering) {
      return 0;
    }

    const daysleft = moment(currentOffering.end_time).diff(
      moment(),
      'days',
      true,
    );

    return daysleft;
  };

  const subscriptionDaysALC = getSubscriptionDays();

  const exclamationIcon = (
    <React.Fragment>
      <Icon status="warning" size="lg" className="exclamation-triangle-icon">
        <ExclamationTriangleIcon />
      </Icon>
    </React.Fragment>
  );

  const infoCircleIcon = (
    <React.Fragment>
      <Icon status="info" size="lg" className="exclamation-triangle-icon">
        <InfoCircleIcon />
      </Icon>
    </React.Fragment>
  );

  const getLabUsageRemaining = () => {
    const inputHours = labHoursLeft;
    const totalSeconds = inputHours * 3600;

    // Convert seconds to hours, minutes, and seconds
    const duration = moment.duration(totalSeconds, 'seconds');

    const hours = Math.round(duration.asHours());
    // const minutes = duration.minutes();
    // const seconds = duration.seconds();

    const labHoursRemainingTxt = <>{t('Lab hours remaining')}:&nbsp;</>;

    if (hours > 20) {
      return (
        <li>
          {t('Lab hours used')} &nbsp;
          <strong>
            {userStore.totalUsedLabHours}/{userStore.totalAllowedLabHours}
          </strong>
        </li>
      );
    }

    if (hours > 10) {
      return (
        <li>
          {infoCircleIcon} {labHoursRemainingTxt}
          <span className="usage-info-alert">
            <strong>
              {t('less than')} {hours}
            </strong>
          </span>
        </li>
      );
    }

    if (hours > 1) {
      return (
        <li>
          {exclamationIcon} {labHoursRemainingTxt}
          <span className="usage-alert">
            <strong>
              {t('less than')} {hours}
            </strong>
          </span>
        </li>
      );
    }

    if (totalSeconds > 0) {
      return (
        <li className="usage-alert-bg">
          {exclamationIcon} {labHoursRemainingTxt}
          <span className="usage-alert">
            <strong>{t('less than')} 1</strong>
          </span>
        </li>
      );
    }

    return (
      <li className="usage-alert-bg">
        <span className="usage-alert">
          <strong>{t('All lab hours have been used')}</strong>
        </span>
      </li>
    );
  };

  const labUsageRemaining = getLabUsageRemaining();

  return (
    <div className="subscription-widget">
      <ul className="subscription-info">
        <li>
          {userStore.isAlaCarte ? (
            <React.Fragment>
              {subscriptionDaysALC > 0 && subscriptionDaysALC <= 1 ? (
                <strong>{t('Last day')}</strong>
              ) : (
                <React.Fragment>
                  {t('Days remaining')}&nbsp;
                  <strong>{Math.trunc(subscriptionDaysALC)}</strong>
                </React.Fragment>
              )}
              <strong />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {t('Days remaining')}&nbsp;
              <strong>{subscriptionDaysLeft}</strong>
            </React.Fragment>
          )}
        </li>
        {userStore.enablementsQuotas &&
          !userStore.isLimitedTrialSubscriber &&
          !enforceLabLimit &&
          userStore.currentSubscriptionCode && (
            <React.Fragment>
              <span>|</span>
              <li>
                {t('Lab hours used')}
                &nbsp;
                <strong>
                  {userStore.totalUsedLabHours}/{userStore.totalAllowedLabHours}
                </strong>
              </li>
            </React.Fragment>
          )}
        {userStore.enablementsQuotas && enforceLabLimit && (
          <React.Fragment>
            <span>|</span>
            {labUsageRemaining}
          </React.Fragment>
        )}
        {userStore.enablementsQuotas &&
          !userStore.isLimitedTrialSubscriber &&
          userStore.currentSubscriptionCode && (
            <React.Fragment>
              {(isBookshelfEnabled || pdfDownloads?.allowed > 0) && (
                <>
                  <span>|</span>
                  <li>
                    {t('E-books claimed')}&nbsp;
                    <strong>
                      {isBookshelfEnabled
                        ? claimsAllowed > 0
                          ? `${claimsConsumed || 0}/${claimsAllowed}`
                          : `${claimsConsumed || 0}`
                        : userStore.isRHUSubscriber
                        ? pdfDownloads?.used || 0
                        : `${pdfDownloads?.used || 0}/${pdfDownloads?.allowed}`}
                    </strong>
                  </li>
                </>
              )}
              {exams?.allowed > 0 && (
                <React.Fragment>
                  <span>|</span>
                  <li>
                    {t('Exams')}&nbsp;
                    <strong>
                      {exams?.used || 0}/{exams?.allowed}
                    </strong>
                  </li>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
      </ul>
    </div>
  );
};

export default withErrorBoundary(
  observer(SubscriptionWidget),
  ComponentError,
  handleError,
);
