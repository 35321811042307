import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-mobx-router5';
import SVG from 'react-inlinesvg';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { getOfferingTitleByLanguage } from 'services/UtilityService';
import BaseCard from 'components/Card';
import ExamIcon from 'img/icons/exam.svg';
import RemoteExamIcon from 'img/icons/remote_exam.svg';
import PrelimExamIcon from 'img/icons/prelim_exam.svg';
import LockIcon from 'img/icons/lock.svg';
import { handleError } from 'services/ErrorService';
import { ExamStatus, ExamStatusUppercase } from 'types/exam';
import { Modality, OfferingStatus } from 'types';
import ExamCardStore from './store';
import ExamCollateral from './ExamCollateral';

const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <Alert bsStyle="danger">
      {t("Oops! We're having trouble displaying this card.")}
    </Alert>
  );
};

const ExamCard = ({
  exam,
  status,
  canAccess,
  lastAccessed,
  actionButtonsArray,
  disableActionButtons,
  allowOrderingOffering,
  onOfferingOrderChange,
}) => {
  const { code, version, translations, visibility, modalities } = exam;
  let { modality } = exam;

  const { i18n, t } = useTranslation();

  const store = useLocalStore(() => new ExamCardStore());

  const EXAM_MODALITY = Modality.Exam;
  const REMOTE_EXAM_MODALITY = 'remote-exam';
  const PRELIM_EXAM_MODALITY = 'prelim-exam';

  modality = EXAM_MODALITY;

  if (/ex.*r/.test(code)) {
    modality = REMOTE_EXAM_MODALITY;
  }

  if (code?.substring(0, 2) === 'pe') {
    modality = PRELIM_EXAM_MODALITY;
  }

  const remoteExamAvailable = modalities?.includes('remote');

  const isRetiredExam = status === OfferingStatus.Retired;

  const lockedTooltip = (
    <Tooltip id="locked-tooltip">
      {t('This content is not available as part of your subscription')}
    </Tooltip>
  );

  const getExamIcon = () => {
    if (canAccess) {
      switch (modality) {
        case REMOTE_EXAM_MODALITY:
          return (
            <SVG
              src={RemoteExamIcon}
              title={t('Remote Exam')}
              width={40}
              height={40}
            />
          );
        case PRELIM_EXAM_MODALITY:
          return (
            <SVG
              src={PrelimExamIcon}
              title={t('Preliminary Exam')}
              width={40}
              height={40}
            />
          );
        default:
          return (
            <SVG src={ExamIcon} title={t('Exam')} width={40} height={40} />
          );
      }
    } else {
      return (
        <OverlayTrigger
          placement="bottom"
          trigger={['hover']}
          overlay={lockedTooltip}
        >
          <div className="content-locked">
            <SVG
              src={LockIcon}
              title={t('Content is locked')}
              width={40}
              height={40}
              style={{ pointerEvents: 'auto' }}
            />
          </div>
        </OverlayTrigger>
      );
    }
  };

  const getTranslation = () => {
    return typeof translations === 'object'
      ? Object.values(translations)
      : translations;
  };

  const getExamTitle = () => {
    const title = getOfferingTitleByLanguage(
      exam,
      getTranslation(),
      i18n.language,
    );
    const skuLessTitle = title.split(' (EX')[0];
    return `${code.toUpperCase()} - ${skuLessTitle}`;
  };

  const getProgress = () => {
    return status === ExamStatus.Completed ? 100 : 0;
  };

  const getColorCard = () => {
    switch (modality) {
      case REMOTE_EXAM_MODALITY:
        return '#084887';
      case PRELIM_EXAM_MODALITY:
        return '#2274A5';
      default:
        return '#004153';
    }
  };

  const getSubtitleCard = () => {
    let subtitleString = '';

    if (isRetiredExam) {
      subtitleString = t('Retired Offering');
    } else {
      if (modality === REMOTE_EXAM_MODALITY) {
        subtitleString += `${t('Remote Exam')}! `;
      }

      if (remoteExamAvailable) {
        subtitleString += `${t('Remote Available')}! `;
      }
    }

    return subtitleString;
  };

  const renderExamStatus = () => {
    return (
      status?.toUpperCase() !== ExamStatusUppercase.Unenrolled && (
        <span>{`${t('Status')}: ${t(
          _.upperFirst(status.toLowerCase().replaceAll('_', ' ')),
        )}`}</span>
      )
    );
  };

  const actionsForExam = () => {
    let actionsToReturn = [];
    const isAbleToAccess =
      visibility !== 'upgrade' &&
      visibility !== 'hidden' &&
      canAccess &&
      !isRetiredExam;

    if (isAbleToAccess) {
      if (actionButtonsArray?.length > 0) {
        actionsToReturn = actionButtonsArray;
        return actionsToReturn;
      }

      actionsToReturn.push(
        <Link
          data-analytics-id={`view-btn-${modality}-card-lp`}
          className={`course-view ${
            disableActionButtons && 'course-actions-disabled'
          }`}
          href
          routeName="exams:view"
          {...(disableActionButtons && { disabled: true })}
          key={uuidv4()}
          routeParams={{
            slug: `${code}-${version}`,
          }}
        >
          {t('View').toUpperCase()}
        </Link>,
      );
    }

    return actionsToReturn;
  };

  return (
    <BaseCard
      customClassName={`exam-card ${isRetiredExam && 'grayscale-filter'}`}
      title={`${getExamTitle()}`}
      subtitle={getSubtitleCard()}
      firstLineElement={renderExamStatus()}
      cardColor={getColorCard()}
      iconClassName={modality}
      svgIconElement={getExamIcon()}
      progress={getProgress()}
      actionButtonsArray={actionsForExam()}
      actionToggle={() => store.getExamCollateral(code, i18n.language)}
      leftPanelElement={lastAccessed && <span>{lastAccessed}</span>}
      allowOrderingOffering={allowOrderingOffering}
      onOfferingOrderChange={onOfferingOrderChange}
    >
      <div className="course-collateral">
        <React.Fragment>
          <ExamCollateral collateral={store.collateral} />
        </React.Fragment>
      </div>
    </BaseCard>
  );
};

ExamCard.propTypes = {
  exam: PropTypes.object,
  status: PropTypes.string,
  canAccess: PropTypes.bool,
  lastAccessed: PropTypes.object,
  actionButtonsArray: PropTypes.array,
  disableActionButtons: PropTypes.bool,
  allowOrderingOffering: PropTypes.bool,
  onOfferingOrderChange: PropTypes.func,
};

ExamCard.defaultProps = {
  exam: {},
  status: 'unenrolled',
  canAccess: true,
  lastAccessed: undefined,
  actionButtonsArray: [],
  disableActionButtons: false,
  allowOrderingOffering: false,
  onOfferingOrderChange: () => {},
};

export default withErrorBoundary(observer(ExamCard), ErrorMessage, handleError);
