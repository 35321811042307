import React, { useContext, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';

import { Button } from '@patternfly/react-core';
import { Helmet } from 'react-helmet';
import { storesContext } from 'stores';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import LearningPathWidget from 'components/LearningPath/Widget';
import SubscriptionWidget from 'components/Subscriptions/LineWidget';
import LimitedTrialStats from 'components/Subscriptions/LimitedTrial/Stats';
import HeroCarousel from 'components/HeroCarousel';
import ClassesWidget from 'components/Classes/Widget';
import MyProgressWidget from 'components/MyProgress';
import SearchAutosuggest from 'components/Search';
import PendingManagerForm from 'components/Dashboard/PendingManagerForm';
import HomeStore, { homeContext } from './store';

import './styles.scss';
import FreeTrialOffering from './FreeTrialOffering';
import FreeTrialComparisonTable from './FreeTrialComparisonTable';

const GenericAlert = React.lazy(() =>
  import(
    /* webpackChunkName: 'generic-alert' */ 'components/Banners/GenericAlert'
  ),
);

const ZeroDialog = React.lazy(() =>
  import(/* webpackChunkName: 'zero-dialog' */ './ZeroDialog'),
);

const ComplianceBannerDialog = React.lazy(() =>
  import(/* webpackChunkName: '-dialog' */ './ComplianceBanner'),
);

const PrivacyBannerDialog = React.lazy(() =>
  import(/* webpackChunkName: '-dialog' */ './PrivacyBanner'),
);

const PremiumIntroBannerDialog = React.lazy(() =>
  import(/* webpackChunkName: '-dialog' */ './PremiumIntroBanner'),
);

const ConcurrentLoginBanner = React.lazy(() =>
  import(
    /* webpackChunkName: '-dialog' */ 'components/Banners/ConcurrentLogin'
  ),
);

const SubscriptionComparisonTable = React.lazy(() =>
  import(
    /* webpackChunkName: '-dialog' */ 'components/Subscriptions/ComparisonTable'
  ),
);

const SyndicationComponent = React.lazy(() =>
  import(
    /* webpackChunkName: 'syndication-component' */ 'components/SyndicationComponent'
  ),
);

const HomeView = () => {
  const { t } = useTranslation();
  const {
    alertStore,
    classesStore,
    routerStore,
    uiStore,
    userStore,
    eventStore,
    premiumSessionsStore,
    organizationStore,
    configStore,
  } = useContext(storesContext);
  const { hasCurrentEnrollment } = classesStore;
  const homeStore = useLocalStore(() => new HomeStore(routerStore, uiStore));
  const { isChineseInstance } = uiStore;
  const { isFreeTrialRegistrationEnabled } = configStore;
  const showComplianceBannerDialog = userStore.isComplianceBannerDue;
  const showPrivacyBannerDialog = userStore.needsPrivacyBanner;
  const showPremiumIntroBannerDialog = userStore.needsPremiumIntroBanner;
  const showFreeTrialOffering =
    isFreeTrialRegistrationEnabled &&
    !userStore.isUserAuthorized &&
    !userStore.hasUserAnyPastSubscription;

  const concurrentLoginAlert = eventStore.isConcurrentLogin
    ? {
        onDismiss: eventStore.resetConcurrentLogin,
      }
    : null;

  const homeAlerts = alertStore.currentAlerts?.filter((alert) =>
    alert.environment.includes('home'),
  );
  useEffect(() => {
    document.body.style.overflow = homeStore.isTourDisplayed
      ? 'hidden'
      : 'visible';
  }, [homeStore.isTourDisplayed]);

  useEffect(() => {
    if (premiumSessionsStore.loaded) {
      homeStore.premClassesWithEnrollments =
        classesStore.premiumClassesWithEnrollments;
    }
  }, [premiumSessionsStore.loaded]);

  if (showFreeTrialOffering) {
    return (
      <div className="container-grid-responsive">
        <FreeTrialOffering />
      </div>
    );
  }

  const showFreeTrialComparisonTable =
    isFreeTrialRegistrationEnabled &&
    classesStore.didFetchClassesForUser &&
    userStore.didFetchSubscription &&
    !userStore.isUserAuthorized &&
    userStore.hasUserAnyPastSubscription;

  return (
    <React.Fragment>
      <Helmet>
        <title>Red Hat Learning</title>
      </Helmet>
      {homeStore.isTourDisplayed && (
        <div className="tour-wrapper">
          <div className="overlay">
            <div className="overlay-content">
              <div
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                  paddingBottom: '56.25%',
                }}
              >
                <iframe
                  src={homeStore.videoToPlay}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  scrolling="auto"
                  style={{ position: 'absolute', height: '100%' }}
                  allowFullScreen
                  webkitallowfullscreen
                  mozAllowFullScreen
                  title="Welcome to the Red Hat Learning Subscription"
                />
              </div>
              <div className="text-center">
                <Button
                  type="button"
                  variant="danger"
                  onClick={() => {
                    homeStore.toggleTour();
                  }}
                >
                  {t('Close video')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <homeContext.Provider value={homeStore}>
        <HeroCarousel />
      </homeContext.Provider>
      {!userStore.isAlaCarte && <SubscriptionWidget />}
      <div className="container-grid-responsive">
        <SearchAutosuggest />
        {showComplianceBannerDialog ? <ComplianceBannerDialog /> : null}
        {showPrivacyBannerDialog ? <PrivacyBannerDialog /> : null}
        {showPremiumIntroBannerDialog ? <PremiumIntroBannerDialog /> : null}
        {organizationStore.organization.isPending && <PendingManagerForm />}
        {eventStore.isConcurrentLogin ? (
          <ConcurrentLoginBanner {...concurrentLoginAlert} />
        ) : null}
        {!hasCurrentEnrollment ? <ZeroDialog /> : null}
        {/* FIXME: remove this banner when Novello is released */}
        {homeAlerts?.map((alert) => (
          <GenericAlert {...alert} />
        ))}
        {userStore?.isLimitedTrialSubscriber && <LimitedTrialStats />}
        {userStore?.isFreeTierSubscriber && !showFreeTrialComparisonTable && (
          <SubscriptionComparisonTable />
        )}
        {showFreeTrialComparisonTable ? (
          <div className="home-free-trial-wrapper">
            <FreeTrialComparisonTable />
            <h3>
              {t(`Want to continue learning? Upgrade to one of these tiers or`)}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={userStore.partnerUrl}
              >
                {' '}
                {t(`contact us`)}.
              </a>
            </h3>
          </div>
        ) : (
          <div className="widget-wrapper">
            <section className="my-widgets">
              <ClassesWidget classes={classesStore.iltClasses} modality="ilt" />
              <ClassesWidget classes={classesStore.vtClasses} modality="vt" />
              {userStore.isPremiumSubscriber && (
                <ClassesWidget
                  classes={classesStore.premiumClassesWithEnrollments}
                  modality="premvt"
                />
              )}
              <MyProgressWidget />
              {!userStore.isAlaCarte &&
                !userStore.isLS101Subscriber &&
                !userStore.isWD200Subscriber &&
                !userStore.isExternalSubscriber && <LearningPathWidget />}
            </section>
            {!isChineseInstance && (
              <section className="syndication-widget">
                <SyndicationComponent />
              </section>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withErrorBoundary(
  observer(HomeView),
  GenericViewError,
  handleError,
);
