import React, { useContext, useEffect } from 'react';
import { when, reaction } from 'mobx';
import { useTranslation } from 'react-i18next';
import { observer, useLocalStore } from 'mobx-react';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import { Link } from 'react-mobx-router5';
import moment from 'moment';
import shortid from 'shortid';
import { storesContext } from '../../../stores';
import ViewLoader from '../../Loading/View';
import './styles.scss';
import OfferingsListStore from './store';

import { DASHBOARD_SORT_FILEDS } from '../../../config/constants';

const DashboardCard = React.lazy(() =>
  import(/* webpackChunkName: 'dashboard-card' */ '../DashboardCard'),
);

const OfferingsFilter = React.lazy(() =>
  import(/* webpackChunkName: 'dashboard-card' */ './OfferingsFilter'),
);

const DashboardCardNonAlacarte = React.lazy(() =>
  import(
    /* webpackChunkName: 'dashboard-card-non-alacarte' */ '../DashboardCardNonAlaCarte'
  ),
);

const Offeringslist = () => {
  const {
    rootStore,
    userStore,
    catalogStore,
    progressStore,
    examsStore,
    classesStore,
    learningPathStore,
    premiumSessionsStore,
  } = useContext(storesContext);
  const { t } = useTranslation();
  const store = useLocalStore(
    () =>
      new OfferingsListStore(
        rootStore,
        userStore,
        catalogStore,
        progressStore,
        examsStore,
        classesStore,
        learningPathStore,
        premiumSessionsStore,
      ),
  );

  const noResultMessage = () => {
    const noResultMsg = `There are no offerings currently in progress.`;
    return t(noResultMsg);
  };

  useEffect(() => {
    // Populate catalog entry data
    when(
      () => !store.loaded && userStore.isLoggedIn,
      async () => {
        // eslint-disable-next-line no-console
        console.log('Dashboard: user  logged in');

        await classesStore.getClassesForCurrentUser();
        await store.getLabHoursWithCourse();
        await catalogStore.getAllCatalogEntries();

        store.hasRelatedData = true;
      },
    );

    reaction(
      () => store.hasRelatedData && userStore.subscription?.subscription,
      async () => {
        if (userStore.subscription?.subscription) {
          // eslint-disable-next-line no-console
          console.log('Dashboard: has subscription start');
          await catalogStore.getCurrentSubscriptionCatalog();
          if (userStore.isPremiumSubscriber && !premiumSessionsStore.loaded) {
            await premiumSessionsStore.getPremiumSessionContent();
            await classesStore.getPremiumSessionEnrollmentsForCurrentUser();
          }
          // eslint-disable-next-line no-console
          console.log('Dashboard: has subscription finsihed');
          store.hasCatalogData = true;
        } else if (!userStore.isAlaCarte) {
          // eslint-disable-next-line no-console
          console.log('Dashboard: is alacarte');
          store.hasCatalogData = true;
        }
      },
    );

    // Select preset filters on initial load
    reaction(
      () => store.hasCatalogData,
      () => {
        // eslint-disable-next-line no-console
        console.log('Dashboard: clear');
        store.clearFilters();
        store.loaded = true;
      },
    );

    reaction(
      () => classesStore.premiumEnrollmentsReloaded,
      () => {
        // eslint-disable-next-line no-console
        if (classesStore.premiumEnrollmentsReloaded && store.hasCatalogData) {
          store.filterEntries();
          store.loaded = true;
        }
      },
    );
  }, []);

  const getPercentage = (item) => {
    const { slug_tag: courseSlug } = item;
    const { progress } = classesStore;

    if (progress[courseSlug]) {
      return progress[courseSlug].total_progress;
    }

    return 0;
  };

  const renderNonAlacarte = (progressRecord) => {
    const normalizedOfferings = progressRecord;

    if (normalizedOfferings) {
      return (
        <DashboardCardNonAlacarte
          key={shortid.generate()}
          progressRecord={progressRecord}
        />
      );
    }
    return null;
  };
  const renderAlacarte = (item) => {
    const percentage = getPercentage(item);
    return (
      <DashboardCard
        course={item}
        progress={Math.round(percentage * 100)}
        key={shortid.generate()}
        lastAccessed={moment(item['@timestamp']).format('MMM/DD/YYYY')}
      />
    );
  };
  const renderOfferings = () => {
    const { currentPage, paginatedEntries, setCurrentPage, totalPages } = store;
    return (
      <React.Suspense fallback={<ViewLoader />}>
        <React.Fragment>
          {(store.loaded && (
            <OfferingsFilter
              sortByField={store.sortByField}
              isSortAscending={store.isSortAscending}
              onSortTitle={() => {
                store.onSortBy(DASHBOARD_SORT_FILEDS.title);
              }}
              onSortLastAccessDate={() => {
                store.onSortBy(DASHBOARD_SORT_FILEDS.lastAccessedDate);
              }}
              onSelectOfferings={store.onSelectOfferings}
              onSelectStatuses={store.onSelectStatuses}
              onSearchOfferings={store.onSearchOfferings}
            />
          )) ||
            null}

          <div className="dashboard-main-list">
            {store.loaded ? (
              (paginatedEntries.length &&
                paginatedEntries.map((item) =>
                  userStore.isAlaCarte
                    ? renderAlacarte(item)
                    : renderNonAlacarte(item),
                )) || (
                <div className="no-progress-msg">
                  <p>{noResultMessage()}</p>
                  <Link href routeName="catalog">
                    {t('Explore the Catalog')}
                  </Link>
                </div>
              )
            ) : (
              <div className="view-loader-wrapper">
                <ViewLoader />
              </div>
            )}
          </div>

          {store.loaded && paginatedEntries.length ? (
            <div className="offerings-list-pagination">
              <UltimatePagination
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={setCurrentPage}
              />
            </div>
          ) : null}
        </React.Fragment>
      </React.Suspense>
    );
  };
  return renderOfferings();
};

export default observer(Offeringslist);
