import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Text,
  TextVariants,
} from '@patternfly/react-core';
import { Link } from 'react-mobx-router5';
import GenericAlert from 'components/Banners/GenericAlert';
import LabControlsStore, { labControlsContext } from 'stores/LabControls';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import useMobxStores from 'hooks/useMobxStores';
import { useViewportSize } from '@ole-ui/ole-ui-components';
import CourseStore from './store';

const LabStartDialog = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'lab-start-dialog' */ 'components/LabControls/LabStartDialog'
    ),
);

const SurveyDialog = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'survey-dialog' */ 'components/Course/SurveyDialog/SurveyDialog'
    ),
);

type Props = {
  courseStore: CourseStore;
  labControlsStore: LabControlsStore;
  courseSlug: string;
};

const CourseHeader = ({ courseSlug, courseStore, labControlsStore }: Props) => {
  const { t } = useTranslation();
  const { routerStore, alertStore, userStore } = useMobxStores();
  const isMobile = useViewportSize().isLowerThanMd;

  const {
    courseCode,
    modality,
    courseVersion,
    currentSection,
    currentChapter,
    firstSection,
    originRoute,
  } = courseStore;
  const { currentAlerts } = alertStore;
  const courseAlerts = currentAlerts.filter(
    (alert) => alert.enabled && alert.environment.includes(courseSlug),
  );

  const showLabStartDialog =
    labControlsStore.courseSlug &&
    !(labControlsStore.blueprints?.length > 1) &&
    !(labControlsStore.created || labControlsStore.started) &&
    courseStore.canShowAutostart &&
    userStore.hasLabHours;

  const formatSectionTitle = (section) => {
    let finalTitle = section?.title;
    if (isMobile) {
      finalTitle = section?.title?.split(':')?.[0] || section.title;
    }
    return finalTitle;
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem
          className="hud-course-view__fade-in"
          component={Link}
          to={originRoute.to}
          onClick={() => routerStore.navigate(originRoute.to)}
        >
          {t(originRoute.label) || t('Catalog')}
        </BreadcrumbItem>
        <BreadcrumbItem
          className="hud-course-view__fade-in hud-course-view__fade-in--delayed"
          component={Link}
          to="courses:page"
          onClick={() =>
            routerStore.navigate('courses:page', {
              course: (routerStore.route as any).params.course,
              page: firstSection.page_tag,
            })
          }
        >
          {courseStore.title || t('Your course')}
        </BreadcrumbItem>
        {currentChapter && (
          <BreadcrumbItem
            className="hud-course-view__fade-in hud-course-view__fade-in--delayed"
            component={Link}
            isActive={currentChapter.page_tag === currentSection?.page_tag}
            to="courses:page"
            onClick={() => {
              routerStore.navigate('courses:page', {
                course: (routerStore.route as any).params.course,
                page:
                  currentChapter.page_tag ||
                  (routerStore.route as any).params.course,
              });
            }}
          >
            {formatSectionTitle(currentChapter)}
          </BreadcrumbItem>
        )}
        {currentSection && (
          <BreadcrumbItem
            className="hud-course-view__fade-in hud-course-view__fade-in--delayed"
            isActive
          >
            {formatSectionTitle(currentSection)}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      {courseStore.title && (
        <Text component={TextVariants.h1}>{courseStore.title}</Text>
      )}
      {courseAlerts.map((alert) => (
        <GenericAlert key={alert.start_time || alert.title} {...alert} />
      ))}
      {courseStore.canShowSurveyDialog && (
        <Suspense fallback="Loading">
          <SurveyDialog
            onEnrollmentSave={courseStore.saveEnrollmentUserData}
            enrollmentUserData={courseStore.userData}
            courseCode={courseCode}
            courseSlug={courseSlug}
            modality={modality}
            courseVersion={courseVersion}
          />
        </Suspense>
      )}
      {showLabStartDialog && (
        <labControlsContext.Provider value={labControlsStore}>
          <Suspense fallback="Loading">
            <LabStartDialog
              onEnrollmentSave={courseStore.saveEnrollmentUserData}
              enrollmentUserData={courseStore.userData}
            />
          </Suspense>
        </labControlsContext.Provider>
      )}
    </div>
  );
};

export default withErrorBoundary(
  observer(CourseHeader),
  ComponentError,
  handleError,
);
