/* eslint-disable camelcase */
/**
 * @file Course Catalog store
 * @author Joshua Jack <jjack@redhat.com>
 */

import { action, observable, computed } from 'mobx';
import moment from 'moment';
import flatMap from 'lodash/flatMap';
import { getLegacyCatalog } from '../services/CatalogService';
import {
  postEnrollment,
  getAllUserEnrollments,
  patchEnrollment,
} from '../services/PremiumEnrollmentService';
import { getPremiumSessionInterests } from '../services/PremiumSessionInterestedService';
import { markPremiumSessionSurveyAsSeen } from '../services/PremiumSessionService';
import {
  PREMIUM_VT_SESSION_STATES,
  PREMIUM_VT_SESSION_ERROR_STATES,
  PREMIUM_VT_SCHEDULE_SESSION_LIMIT,
  PREMVT_SESSION_SURVEY_NOTIFICATION_ID,
} from '../config/constants';

class ClassesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable vtClasses = [];

  @observable iltClasses = [];

  @observable progress = {};

  @observable offerings = [];

  @observable standard = [];

  @observable premiumVTClasses = []; // Premium VT enrollments grouped by offering

  @observable didFetchClassesForUser = false;

  @observable premiumVTInterestedClasses = [];

  @observable premiumEnrollmentsReloaded = false;

  @action getClassesForCurrentUser = async () => {
    let result = {};

    try {
      result = await getLegacyCatalog();
    } catch (err) {
      console.error(`Couldn't fetch legacy offerings`, err);
      return;
    } finally {
      this.didFetchClassesForUser = true;
    }
    // sometimes API returns inconsistent results on the endpoint for legacy catalog entries.
    // if one of the needed properties is missing, fall back to default value
    this.offerings = result?.offerings || [];
    this.standard = result?.standard || [];
    this.vtClasses = result?.role_vt || [];
    this.iltClasses = result?.ilt || [];
  };

  @computed get hasCurrentEnrollment() {
    const today = moment();

    const hasCurrentVtClass = this.vtClasses.map((vtClass) => {
      const start = moment(vtClass.start_time);
      const end = moment(vtClass.end_time);
      return today.isBetween(start, end);
    });

    const hasCurrentIltClass = this.iltClasses.map((iltClass) => {
      const start = moment(iltClass.start_time);
      const end = moment(iltClass.end_time);
      return today.isBetween(start, end);
    });

    const daysleft = this.rootStore?.userStore?.subscriptionDaysLeft;

    if (
      this.standard.length ||
      (!this.standard.length && hasCurrentVtClass.includes(true)) ||
      (!this.standard.length && hasCurrentIltClass.includes(true)) ||
      (!this.standard.length && daysleft !== 0)
    ) {
      return true;
    }

    return false;
  }

  @action enrollUser = async (
    premiumVTSessionId,
    rescheduleSessionId,
    refreshEnrollments = true,
    rescheduleSessionIdAsDocId = false,
  ) => {
    let response = {};

    let rescheduleEnrolledId;

    if (rescheduleSessionIdAsDocId) {
      rescheduleEnrolledId = rescheduleSessionId;
    } else {
      rescheduleEnrolledId = rescheduleSessionId
        ? this.getPremiumClassEnrollmentsBySessionId(rescheduleSessionId)
            ?.doc_id
        : undefined;
    }

    try {
      response = await postEnrollment(
        premiumVTSessionId,
        PREMIUM_VT_SESSION_STATES.enrolled,
        rescheduleEnrolledId,
      );
      if (refreshEnrollments) {
        await this.getPremiumSessionEnrollmentsForCurrentUser();
        await this.rootStore.userStore.getEnablements();
      }

      return response;
    } catch (e) {
      throw e;
    }
  };

  @action cancelPremVTUserEnrollment = async (premiumVTSessionEnrollmentId) => {
    let response = {};
    try {
      response = await patchEnrollment(
        premiumVTSessionEnrollmentId,
        PREMIUM_VT_SESSION_STATES.cancelled,
      );
      this.premiumEnrollmentsReloaded = false;
      await this.getPremiumSessionEnrollmentsForCurrentUser();
      await this.rootStore.userStore.getEnablements();
      this.premiumEnrollmentsReloaded = true;
      return response;
    } catch (e) {
      throw e;
    }
  };

  @action attendPremVTUserEnrollment = async (premiumVTSessionEnrollmentId) => {
    let response = {};
    try {
      response = await patchEnrollment(
        premiumVTSessionEnrollmentId,
        PREMIUM_VT_SESSION_STATES.completed,
      );
      this.premiumEnrollmentsReloaded = false;
      await this.getPremiumSessionEnrollmentsForCurrentUser();
      this.premiumEnrollmentsReloaded = true;
      return response;
    } catch (e) {
      throw e;
    }
  };

  @action getPremiumSessionEnrollmentsForCurrentUser = async () => {
    try {
      const response = await getAllUserEnrollments([
        PREMIUM_VT_SESSION_STATES.completed,
        PREMIUM_VT_SESSION_STATES.enrolled,
      ]);
      await this.getPremiumSessionInterestsForCurrentUser();
      this.premiumVTClasses = response;
      this.sendPremiumSessionSurveyNotifications();
    } catch (error) {
      console.error(error);
      this.premiumVTClasses = [];
    }
  };

  @action getPremiumSessionInterestsForCurrentUser = async () => {
    try {
      const response = await getPremiumSessionInterests();
      this.premiumVTInterestedClasses = response;
    } catch (error) {
      console.error(error);
      this.premiumVTInterestedClasses = [];
    }
  };

  @computed get groupedPremiumSessionEnrollmentsByOffering() {
    const sessions = this.rootStore.premiumSessionsStore.premiumSessions;
    const groupedEnrollments = {};
    this.premiumVTClasses.forEach((enrollment) => {
      sessions.forEach((session) => {
        if (enrollment.premvt_session_uuid === session.doc_id) {
          const offeringSlug = session.offering_slug;
          if (!(offeringSlug in groupedEnrollments))
            groupedEnrollments[offeringSlug] = [];
          if (enrollment.state !== PREMIUM_VT_SESSION_STATES.cancelled)
            groupedEnrollments[offeringSlug].push(enrollment);
        }
      });
    });
    return groupedEnrollments;
  }

  @computed get premiumClassesWithEnrollments() {
    const premiumClasses =
      this.rootStore.premiumSessionsStore.premiumContentWithScheduledSessions;
    const classesWithEnrollments = [];
    premiumClasses.forEach((premClass) => {
      const slug = premClass.offering_slug;
      const enrollments = this.groupedPremiumSessionEnrollmentsByOffering[slug];
      if (enrollments) {
        const enrollmentsWithoutCancelled = enrollments.filter(
          (e) => e.state !== PREMIUM_VT_SESSION_STATES.cancelled,
        );
        if (enrollmentsWithoutCancelled.length)
          classesWithEnrollments.push({
            ...premClass,
            enrollments: enrollmentsWithoutCancelled,
          });
      }
    });

    return classesWithEnrollments;
  }

  @computed get getPremiumSessionEnrollmentsOfferingSlugForCurrentUser() {
    return Array.from(
      new Set(
        flatMap(this.premiumVTClasses, (pse) => {
          const splitSessionSlug = pse.premvt_session_slug.split('-');
          return `${splitSessionSlug[0]}-${splitSessionSlug[1]}`;
        }),
      ),
    );
  }

  @computed get numberEnrolledPremiumSessions() {
    const enrolledSessions = this.premiumVTClasses.filter(
      (enrollment) => enrollment.state === PREMIUM_VT_SESSION_STATES.enrolled,
    );

    return enrolledSessions.length;
  }

  @computed get numberAvailablePremiumSessionEnrollmentsForUser() {
    const diff =
      PREMIUM_VT_SCHEDULE_SESSION_LIMIT - this.numberEnrolledPremiumSessions;

    return diff > 0 ? diff : 0;
  }

  @action getPremiumClassEnrollmentsForCourseSlug(courseSlug) {
    return this.groupedPremiumSessionEnrollmentsByOffering[courseSlug];
  }

  @action getPremiumClassEnrollmentsBySessionId(
    premvtSessionId,
    state = PREMIUM_VT_SESSION_STATES.enrolled,
  ) {
    return this.premiumVTClasses?.find(
      (e) => e.premvt_session_uuid === premvtSessionId && e.state === state,
    );
  }

  static getUseEnrollErrorMessage = (errorDetail, t) => {
    let errorMessage = '';
    const errorType = Object.values(PREMIUM_VT_SESSION_ERROR_STATES).find(
      (e) => {
        return errorDetail?.includes(e);
      },
    );

    switch (errorType) {
      case PREMIUM_VT_SESSION_ERROR_STATES.invalid_session:
      case PREMIUM_VT_SESSION_ERROR_STATES.nonscheduled_completed_session:
        errorMessage = t(
          'Sorry, the session you are trying to schedule is not available any more.',
        );
        break;
      case PREMIUM_VT_SESSION_ERROR_STATES.enrolled_session: // not available
        errorMessage = t('You are already enrolled with this session');
        break;
      case PREMIUM_VT_SESSION_ERROR_STATES.capacity_reached: // not available
        errorMessage = t(
          'Sorry, the session you are try to schedule is full. Please select a different time slot.',
        );
        break;
      case PREMIUM_VT_SESSION_ERROR_STATES.conflicting_enrollments: // not available
        errorMessage = t(
          'The session you are try to schedule is conflicting with already scheduled sessions.',
        );
        break;
      case PREMIUM_VT_SESSION_ERROR_STATES.session_limit_exceeded:
        errorMessage = t(
          'This session can not be scheduled because you have reached your maximum amount of sessions.',
        );
      case PREMIUM_VT_SESSION_ERROR_STATES.schedule_limit_exceeded: // not available
        errorMessage = t(
          `This session could not be scheduled because you have reached your maximum amount of sessions that can be scheduled at one time (${PREMIUM_VT_SCHEDULE_SESSION_LIMIT}). In order to schedule this session, please either cancel a session scheduling, or attend an enrolled session`,
        );
        break;
      case PREMIUM_VT_SESSION_ERROR_STATES.subscription_expires_before_schedule:
        errorMessage = t(
          'This session can not be scheduled because it ends after your subscription expires.',
        );
      default:
        errorMessage = t(
          "Session couldn't be scheduled, please try again. If the problem persists please contact support.",
        );
    }
    return errorMessage;
  };

  static getUseUnEnrollErrorMessage = (errorDetail, t) => {
    let errorMessage = '';
    const errorType = Object.values(PREMIUM_VT_SESSION_ERROR_STATES).find(
      (e) => {
        return errorDetail?.includes(e);
      },
    );

    switch (errorType) {
      case PREMIUM_VT_SESSION_ERROR_STATES.cannot_cancel_started_session:
        errorMessage = t(
          'Sorry, the session you are trying to schedule has already started.',
        );
        break;

      default:
        errorMessage = t(
          "Session couldn't be cancelled, please try again. If the problem persists please contact support.",
        );
    }
    return errorMessage;
  };

  @action sendPremiumSessionSurveyNotifications() {
    this.premiumVTClasses.forEach(async (enrollment) => {
      if (enrollment?.state === PREMIUM_VT_SESSION_STATES.completed) {
        const sessionId = enrollment.premvt_session_uuid;
        const session =
          await this.rootStore.premiumSessionsStore.fetchPremiumSessionBySessionId(
            sessionId,
          );
        const notificationId = PREMVT_SESSION_SURVEY_NOTIFICATION_ID.replace(
          '<<session_id>>',
          session?.doc_id,
        );
        if (session?.state === PREMIUM_VT_SESSION_STATES.completed)
          this.rootStore.eventStore.triggerLocalNotification(
            `Session ${session?.premvt_session_slug.toUpperCase()} ended`,
            notificationId,
            `${
              session.instructors.length > 0
                ? `Instructor: ${session?.instructors.map(
                    (instructor) => `${instructor.name} `,
                  )}`
                : ''
            }\nHow did it go?`,
            'Go to survey',
            () =>
              this.rootStore.routerStore.navigate('sessions:view:survey', {
                sessionid: sessionId,
                sessionslug: session?.premvt_session_slug,
              }),
            undefined, // onDelete action
            () => {
              try {
                markPremiumSessionSurveyAsSeen(sessionId);
              } catch (error) {
                console.error('Error archiving notification:', error);
              }
            },
            enrollment.survey_seen,
            moment(session?.end_time).format('YYYY/MM/DD HH:mm'),
          );
      }
    });
  }

  @action getInterestInSession(sessionSlug) {
    return this.premiumVTInterestedClasses.find(
      (interested) => interested.premvt_session_slug === sessionSlug,
    );
  }
}

export default ClassesStore;
