/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import { action, computed, observable } from 'mobx';
import Fuse from 'fuse.js';
import deepmerge from 'deepmerge';
import isEmpty from 'lodash/isEmpty';
import lodashValues from 'lodash/values';
import countBy from 'lodash/countBy';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import intersectionBy from 'lodash/intersectionBy';
import lodashGet from 'lodash/get';

import {
  CatalogEntry,
  CourseType,
  Modality,
  OfferingCodePrefix,
  OfferingVisibility,
  VocabularyItem,
  VocabularyNamespaces,
} from 'types';
import { extractFromSlug } from 'helpers/utils';
import { getCatalog, getCatalogEntries } from '../services/CatalogService';

// These fields are used to extract the categories and products
// from the catalog entries. The first element is prioritized over the second,
// and so on.
export const TAXONOMIES_PRIORITIES = {
  legacy: {
    categories: ['metadata.categories', 'categories'],
    products: ['metadata.products', 'products'],
  },
  new: {
    topics: ['metadata.taxonomies.topics', 'taxonomies.topics'],
    audiences: ['metadata.taxonomies.audiences', 'taxonomies.audiences'],
    products: ['metadata.taxonomies.products', 'taxonomies.products'],
    level: ['metadata.level', 'level'], // the only one outside of taxonomies
  },
};

export type CatalogFilterState = {
  key: string;
  value: string;
  count: number;
  checked: boolean;
};

type FilterKeys =
  | 'earlyAccess'
  | 'categories'
  | 'languages'
  | 'modalities' // TODO: rename to deliveryFormats because it's not just about modality anymore
  | 'products'
  | 'roles'
  | 'taxonomyProducts'
  | 'taxonomyAudiences'
  | 'taxonomyTopics'
  | 'level';

type CatalogFilters = {
  [K in FilterKeys]?: CatalogFilterState[];
};

type FlattenedCatalogFilters = {
  [K in FilterKeys]?: string[];
};

export const compareByLevelAsc = (
  a: CatalogFilterState,
  b: CatalogFilterState,
) => parseInt(a.key, 10) - parseInt(b.key, 10);

class CatalogStore {
  @observable subscriptionCatalog: CatalogEntry[] = [];

  @observable loadedSubscriptionCatalog: boolean = false;

  @observable loadingSubscriptionCatalog: boolean = false;

  @observable allCatalogEntries: CatalogEntry[] = [];

  @observable loadedCatalog: boolean = false;

  @observable loadingCatalog: boolean = false;

  @observable filterInfoLoaded = false;

  @observable filters: CatalogFilters = CatalogStore.initializeFilters();

  @observable managementOverviewFilters: CatalogFilters =
    CatalogStore.initializeFilters();

  @observable expandedFilterGroups = new Set();

  @observable _filteredEntries: CatalogEntry[] = [];

  @observable searchResults = null;

  @observable.ref courseCollateral = [];

  @observable _currentPage: number = 1;

  @observable entriesPerPage: number = 10;

  @observable selectedByExternalSubscriber = '';

  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action searchEntries = (string) => {
    const options = {
      shouldSort: true,
      threshold: 0,
      tokenize: true,
      matchAllTokens: true,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['slug', 'title', 'modality', 'course_type'],
    };

    if (!string.length) {
      this.searchResults = this.filteredEntries;
      return;
    }

    const fuse = new Fuse(this.filteredEntries, options);
    this.searchResults = fuse.search(string);
  };

  @computed get query() {
    return this.rootStore.searchStore.query;
  }

  @computed get totalPages(): number {
    return Math.ceil(this.filteredEntries.length / this.entriesPerPage) || 1;
  }

  @computed get currentPage(): number {
    if (this._currentPage > this.totalPages) {
      return 1;
    }

    return this._currentPage;
  }

  @computed get filterKeys(): string[] {
    return Object.keys(this.filters);
  }

  /**
   * Returns an unique array of catalog entries, preferring the latest version.
   * Only works on subscription based catalog.
   */
  @computed get uniqueCatalogEntries() {
    const translations = {};

    // Construct a dic of unique catalog entries, preferring the latest version
    const uniqueDict = this.subscriptionCatalog
      .filter(
        (entry) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict, entry) => {
        if (
          !dict[entry.code] ||
          CatalogStore.isGreaterVersion(entry.version, dict[entry.code].version)
        ) {
          dict[entry.code] = entry;
          if (entry.translations) {
            // accumulate the title translations
            translations[entry.code] = translations[entry.code]
              ? deepmerge(translations[entry.code], entry.translations)
              : entry.translations;
          }
        }
        return dict;
      }, {});

    Object.entries(uniqueDict).forEach(([code, entry]: any) => {
      const translation = translations[entry.code] || [];

      uniqueDict[code] = deepmerge(entry, {
        translations: [
          {
            language: 'en-US',
            title: entry.title,
          },
          ...translation,
        ],
      });
    });

    return uniqueDict;
  }

  @computed get entries(): Array<CatalogEntry> {
    return Object.values(this.uniqueCatalogEntries);
  }

  set entries(entries) {
    this.subscriptionCatalog = entries;
  }

  @computed get filteredEntries() {
    const checkedFiltersLength = flatMap(
      this.filterKeys.map((key) =>
        this.filters[key].filter((filter) => filter.checked),
      ),
    ).length;

    return checkedFiltersLength || this.query
      ? this._filteredEntries
      : this.entries;
  }

  @computed get paginatedEntries() {
    const startIndex = (this.currentPage - 1) * this.entriesPerPage;
    return this.filteredEntries.slice(
      startIndex,
      startIndex + this.entriesPerPage,
    );
  }

  /**
   * Flattens the filters for a given key and returns an array of the checked keys.
   */
  flattenCheckedFilters(key: string): string[] {
    return this.filters[key]
      .filter((item: CatalogFilterState) => item.checked)
      .map((item: CatalogFilterState) => item.key);
  }

  // idea: offload this to a separate worker
  __getFilteredEntries = (
    entryList: Array<unknown>,
    filters: FlattenedCatalogFilters = {},
    entryAcessor: (unknown) => CatalogEntry = (entry) => entry as CatalogEntry,
    entryCourseIdField = 'doc_id',
  ) => {
    const { featuresStore } = this.rootStore;
    const newTaxonomiesEnabled = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );
    const filteredDataByFilter = []; // contains filtered data array for each filter

    if (filters.earlyAccess?.length) {
      const filteredByEarlyAccess = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;
        return filters.earlyAccess.includes(entry?.status);
      });
      filteredDataByFilter.push(filteredByEarlyAccess);
    }

    if (filters.languages?.length) {
      const filteredByLanguages = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;
        const languageMap = flatMap(entry.translations, 'language');
        const filtered = languageMap?.filter((item) =>
          filters.languages.includes(item),
        );
        return languageMap?.some((item) => filtered.includes(item));
      });
      filteredDataByFilter.push(filteredByLanguages);
    }

    if (filters.modalities?.length) {
      const filteredByModalities = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;

        let entryModality = '';

        if (
          entry?.course_type === CourseType.Regular ||
          (entry?.modality && !entry?.course_type)
        ) {
          entryModality = entry?.modality;
        }

        let entryCourseType = (entry?.course_type as string) || '';

        if (entry?.course_type === CourseType.LabPlus) {
          entryCourseType = CourseType.Lesson;
        }

        return (
          filters.modalities.includes(entryModality) ||
          filters.modalities.includes(entryCourseType)
        );
      });
      filteredDataByFilter.push(filteredByModalities);
    }

    if (filters.roles?.length) {
      const filteredByRoles = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;
        // FIXME: Waiting on the property name for 'Job Roles'
        const { job_roles: jobRoles } = entry;
        const filtered = jobRoles?.filter((item) =>
          filters.roles.includes(item),
        );
        return jobRoles?.some((item) => filtered.includes(item));
      });
      filteredDataByFilter.push(filteredByRoles);
    }

    // ========== Legacy filter taxonomies ==========
    if (filters.categories?.length) {
      const filteredByCategories = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;

        const entryCategories = CatalogStore.prioritizeField(
          entry,
          TAXONOMIES_PRIORITIES.legacy.categories,
          true,
        );

        const filtered = entryCategories?.filter((item) =>
          filters.categories.includes(item),
        );

        return entryCategories?.some((item) => filtered.includes(item));
      });
      filteredDataByFilter.push(filteredByCategories);
    }

    if (filters.products?.length) {
      const filteredByProducts = entryList.filter((e) => {
        const entry = entryAcessor(e);
        if (!entry) return false;

        const entryProducts = CatalogStore.prioritizeField(
          entry,
          TAXONOMIES_PRIORITIES.legacy.products,
          true,
        );

        const filtered =
          entryProducts?.filter((item) => filters.products.includes(item)) ||
          [];

        return entryProducts?.some((item) => filtered.includes(item));
      });
      filteredDataByFilter.push(filteredByProducts);
      // causing intersection issue...
    }

    // ========== End of legacy filter taxonomies ==========

    if (newTaxonomiesEnabled) {
      if (filters.taxonomyTopics?.length) {
        const filteredByTopics = entryList.filter((e) => {
          const entry = entryAcessor(e);
          if (!entry) return false;

          const entryTopics = CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.new.topics,
            true,
          );
          const filtered = entryTopics?.filter((item) =>
            filters.taxonomyTopics.includes(item),
          );

          return entryTopics?.some((item) => filtered.includes(item));
        });
        filteredDataByFilter.push(filteredByTopics);
      }

      if (filters.taxonomyAudiences?.length) {
        const filteredByAudiences = entryList.filter((e) => {
          const entry = entryAcessor(e);
          if (!entry) return false;

          const entryAudiences = CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.new.audiences,
            true,
          );

          const filtered = entryAudiences?.filter((item) =>
            filters.taxonomyAudiences.includes(item),
          );

          return entryAudiences?.some((item) => filtered.includes(item));
        });
        filteredDataByFilter.push(filteredByAudiences);
      }

      if (filters.taxonomyProducts?.length) {
        const filteredByProducts = entryList.filter((e) => {
          const entry = entryAcessor(e);
          if (!entry) return false;

          const entryProducts = CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.new.products,
            true,
          );

          const filtered =
            entryProducts?.filter((item) =>
              filters.taxonomyProducts.includes(item),
            ) || [];

          return entryProducts?.some((item) => filtered.includes(item));
        });
        filteredDataByFilter.push(filteredByProducts);
      }

      if (filters.level?.length) {
        const filteredByLevels = entryList.filter((e) => {
          const entry = entryAcessor(e);
          if (!entry) return false;

          const entryLevel = CatalogStore.prioritizeField(
            entry,
            TAXONOMIES_PRIORITIES.new.level,
            false,
          );

          return filters.level.includes(`${entryLevel}`);
        });
        filteredDataByFilter.push(filteredByLevels);
      }
    }

    return filteredDataByFilter.length
      ? intersectionBy(...filteredDataByFilter, entryCourseIdField) // combine intersecting the result of all filters to get final filtered entries
      : entryList;
  };

  /**
   * Filters the catalog entries based on the checked filters.
   * If a query is present, it will also filter the entries based on the query.
   */
  @action filterEntries = () => {
    const { featuresStore } = this.rootStore;
    const newTaxonomiesEnabled = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );

    const filterKeys: FilterKeys[] = [
      'earlyAccess',
      'categories',
      'languages',
      'modalities',
      'products',
      'roles',
    ];

    if (newTaxonomiesEnabled) {
      filterKeys.push(
        'taxonomyProducts',
        'taxonomyAudiences',
        'taxonomyTopics',
        'level',
      );
    }

    const filtersByType: FlattenedCatalogFilters = filterKeys.reduce(
      (acc, key) => {
        acc[key] = this.flattenCheckedFilters(key);
        return acc;
      },
      {} as FlattenedCatalogFilters,
    );

    this._filteredEntries = this.__getFilteredEntries(
      this.entries,
      filtersByType,
    ) as CatalogEntry[];

    if (this.query) {
      this.searchEntries(this.query);
      this._filteredEntries = this.searchResults;
    }
  };

  /**
   * Fetches the catalog entries for a given subscription.
   * @param {string} subscriptionCode - the subscription code to fetch the catalog entries for
   * @param {boolean} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getCatalogEntriesBySubscrition = async (
    subscriptionCode: string,
    force: boolean,
  ) => {
    if (this.loadedSubscriptionCatalog && !force) {
      return this.subscriptionCatalog;
    }

    if (!subscriptionCode) {
      return this.subscriptionCatalog;
    }

    this.loadingSubscriptionCatalog = true;
    try {
      const subscriptionEntries = await getCatalog(subscriptionCode);
      if (subscriptionEntries) {
        this.subscriptionCatalog = subscriptionEntries;
      }
    } finally {
      this.loadingSubscriptionCatalog = false;
      this.loadedSubscriptionCatalog = true;
    }
    return this.subscriptionCatalog;
  };

  /**
   * Fetches the catalog entries for the current subscription.
   * @param {boolean} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getCurrentSubscriptionCatalog = async (
    force: boolean,
  ): Promise<Array<CatalogEntry>> =>
    this.getCatalogEntriesBySubscrition(
      this.rootStore.userStore.currentSubscriptionCode,
      force,
    );

  /**
   * Fetches all catalog entries, independent from any subscription. Usually used for, but not limited to, alacarte users.
   * @param {*} force - if true, will fetch the catalog entries even if they are already loaded
   */
  @action getAllCatalogEntries = async (
    force: boolean,
  ): Promise<Array<CatalogEntry>> => {
    if (this.allCatalogEntries.length && !force) {
      return this.allCatalogEntries;
    }

    this.loadingCatalog = true;

    const allCatalogEntries: Array<CatalogEntry> = await getCatalogEntries();
    if (allCatalogEntries) {
      this.allCatalogEntries = allCatalogEntries;
    }

    this.loadingCatalog = false;
    this.loadedCatalog = true;

    return this.allCatalogEntries;
  };

  /**
   * Returns a dictionary of all catalog entries grouped by course code and version.
   * Works for alacarte users.
   */
  @computed get groupedAllCatalogEntries() {
    return CatalogStore.composeCatalogGroups(this.allCatalogEntries);
  }

  /**
   * load the filters for the catalog entries. This is used to populate the filters in the UI.
   * This loads the vocabulary data for each filter type and then renders the filters based on
   * what is available in the the catalog entries. This ensures that filters only show up if they
   * exist in at least one catalog entry.
   */
  @action getFilterInfo = async (
    entryList: Array<CatalogEntry> = this.entries || [],
    isManagementOverview: boolean = false,
  ) => {
    this.filterInfoLoaded = false;
    const language = this.rootStore.uiStore.currentLanguage;
    const { featuresStore, vocabularyStore } = this.rootStore;
    const { getVocabularyByNamespace } = vocabularyStore;

    const fieldName = isManagementOverview
      ? 'managementOverviewFilters'
      : 'filters';

    const newTaxonomiesEnabled: boolean = featuresStore.isFeatureEnabled(
      'catalog',
      'new_taxonomies',
    );

    type FilterConfiguration = {
      key: FilterKeys;
      values: string[];
      vocabulary?: any;
      vocabularyNamespace?: VocabularyNamespaces;
      compareFn?: (a: CatalogFilterState, b: CatalogFilterState) => number;
    };

    // TODO: In the future, move this to separate file that contains all the filter configurations.
    // for that we need to decouple entryList from the filter configurations, which could be done by
    // using a property like "extractor" that would be a function that extracts the values from an entry.
    const filterConfigurations: FilterConfiguration[] = [
      {
        key: 'earlyAccess',
        values: flatMap(entryList, 'status'),
        vocabulary: [{ display_name: 'Early Access', token: 'early' }],
      },
      {
        key: 'categories',
        values: entryList
          .map((entry) =>
            CatalogStore.prioritizeField(
              entry,
              TAXONOMIES_PRIORITIES.legacy.categories,
              true,
            ),
          )
          .flat(),
        vocabularyNamespace: VocabularyNamespaces.OfferingCategories,
      },
      {
        key: 'languages',
        values: flatMap(entryList, (key) =>
          key.translations?.map((translation) => translation?.language),
        ),
        vocabularyNamespace: VocabularyNamespaces.Languages,
      },
      {
        key: 'modalities',
        values: flatMap(this.entries, (obj: CatalogEntry) => {
          if (obj.course_type && obj.course_type !== CourseType.Regular) {
            // consider labplus as a lesson in this filter:
            if (CatalogStore.isLabPlus(obj.course_type)) {
              return CourseType.Lesson;
            }

            // lessons, labplus, etc
            return obj.course_type ? obj.course_type : [];
          }
          return obj.modality;
        }),
        vocabularyNamespace: VocabularyNamespaces.Modalities,
        compareFn: (a: CatalogFilterState, b: CatalogFilterState) => {
          // custom order for modality
          const customOrder = [
            CourseType.Lesson,
            Modality.Course,
            CourseType.LabPlus,
            Modality.Exam,
            Modality.TechOverview,
            Modality.ExpertSeminar,
          ];

          const indexOfA = customOrder.indexOf(a.key as CourseType | Modality);
          const indexOfB = customOrder.indexOf(b.key as CourseType | Modality);

          if (indexOfA === -1) {
            return 1;
          }

          return indexOfA - indexOfB;
        },
      },
      {
        key: 'products',
        values: entryList
          .map((entry) =>
            CatalogStore.prioritizeField(
              entry,
              TAXONOMIES_PRIORITIES.legacy.products,
              true,
            ),
          )
          .flat(),
        vocabularyNamespace: VocabularyNamespaces.OfferingProducts,
      },
      {
        key: 'roles',
        values: flatMap(entryList, 'job_roles'),
        vocabularyNamespace: VocabularyNamespaces.JobRoles,
      },
    ];

    if (newTaxonomiesEnabled) {
      filterConfigurations.push(
        {
          key: 'taxonomyTopics',
          values: entryList
            .map((entry) =>
              CatalogStore.prioritizeField(
                entry,
                TAXONOMIES_PRIORITIES.new.topics,
                true,
              ),
            )
            .flat(),
          vocabularyNamespace: VocabularyNamespaces.OfferingTaxonomyTopics,
        },
        {
          key: 'taxonomyAudiences',
          values: entryList
            .map((entry) =>
              CatalogStore.prioritizeField(
                entry,
                TAXONOMIES_PRIORITIES.new.audiences,
                true,
              ),
            )
            .flat(),
          vocabularyNamespace: VocabularyNamespaces.OfferingTaxonomyAudiences,
        },
        {
          key: 'taxonomyProducts',
          values: entryList
            .map((entry) =>
              CatalogStore.prioritizeField(
                entry,
                TAXONOMIES_PRIORITIES.new.products,
                true,
              ),
            )
            .flat(),
          vocabularyNamespace: VocabularyNamespaces.OfferingTaxonomyProducts,
        },
        {
          key: 'level',
          values: entryList
            .map((entry) =>
              CatalogStore.prioritizeField(
                entry,
                TAXONOMIES_PRIORITIES.new.level,
                false,
              ),
            )
            .flat(),
          vocabularyNamespace: VocabularyNamespaces.OfferingLevel,
          compareFn: compareByLevelAsc,
        },
      );
    }

    // get all the vocabularies at once (async, although they should be in memory already)
    const vocabularies = await Promise.all(
      filterConfigurations.map((config) =>
        config.vocabulary
          ? Promise.resolve(config.vocabulary)
          : getVocabularyByNamespace(config.vocabularyNamespace, language),
      ),
    );

    filterConfigurations.forEach((config, index) => {
      const vocabulary = vocabularies[index];

      this[fieldName][config.key] = CatalogStore.renderFilters({
        values: config.values,
        vocabulary,
        compareFn: config.compareFn,
      });
    });

    this.filterInfoLoaded = true;
    return this[fieldName];
  };

  @action setCurrentPage = (page = 1) => {
    this._currentPage = page;
  };

  @action clearCatalog = () => {
    this.subscriptionCatalog = [];
  };

  clearFilters = (exceptions: string[] = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item: CatalogFilterState) => {
        const skipped = exceptions.includes(item.key);

        if (!skipped) {
          item.checked = false;
        }
      });
    });

    this.filterEntries();
  };

  /**
   * Presets the filters based on an array of filter keys.
   * @param {Array} filters - an array of filter keys to preset to checked
   */
  presetFilters = (filters = []) => {
    this.filterKeys.forEach((key) => {
      this.filters[key].forEach((item) => {
        if (filters.includes(item.key)) item.checked = true;
      });
    });

    this.filterEntries();
  };

  toggleFilterGroups = (id, expanded) =>
    expanded
      ? this.expandedFilterGroups.add(id)
      : this.expandedFilterGroups.delete(id);

  /**
   * Returns a dictionary of all catalog entries of a given subscription grouped by course code and version.
   * Works for subscription users only. Will be empty for alacarte users.
   */
  @computed get groupedCatalogEntries() {
    return CatalogStore.composeCatalogGroups(this.subscriptionCatalog);
  }

  subscriptionHasCourseType = (courseType) =>
    (this.subscriptionCatalog?.find(
      (entry) =>
        entry?.course_type === courseType &&
        entry?.visibility !== OfferingVisibility.Hidden,
    ) &&
      true) ||
    false;

  @computed get subscriptionHasLabPlus() {
    return this.subscriptionHasCourseType(CourseType.LabPlus);
  }

  @computed get subscriptionHasLesson() {
    return this.subscriptionHasCourseType(CourseType.Lesson);
  }

  @computed get groupedAlaCarteEnrollments() {
    const translations = {};
    const { classesStore } = this.rootStore;

    const uniqueDict = Object.values(classesStore.offerings)
      .filter(
        (entry: any) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict: any, entry: any) => {
        if (
          !dict[entry.slug] ||
          CatalogStore.isGreaterVersion(entry.version, dict[entry.slug].version)
        ) {
          dict[entry.slug] = entry;
          if (entry.translations) {
            // accumulate the title translations
            translations[entry.slug] = translations[entry.slug]
              ? deepmerge(translations[entry.slug], entry.translations)
              : entry.translations;
          }
        }
        return dict;
      }, {});

    return Object.values(uniqueDict);
  }

  @computed get groupedAlaCarteEnrollmentsByCode() {
    return mapValues(groupBy(this.groupedAlaCarteEnrollments, 'code'), (o) =>
      keyBy(o, 'version'),
    );
  }

  @computed get groupedAlaCarteCatalogEntriesAsArray() {
    const allowedToView = pickBy(this.groupedAllCatalogEntries, (value, key) =>
      Boolean(this.groupedAlaCarteEnrollmentsByCode[key]),
    );
    return lodashValues(allowedToView);
  }

  @computed get loading(): boolean {
    return this.loadingCatalog || this.loadingSubscriptionCatalog;
  }

  @computed get loaded(): boolean {
    if (this.rootStore.userStore.hasActiveSubscription) {
      return this.loadedSubscriptionCatalog;
    }
    return this.loadedCatalog;
  }

  @action removeModalityFromCatalog(modalities: string[]) {
    if (!modalities) return;

    this.subscriptionCatalog = this.subscriptionCatalog?.filter(
      (entry) => !modalities.includes(entry.modality),
    );
  }

  @action getSortedVersions(code) {
    return this.subscriptionCatalog
      .filter((a) => a.code === code)
      .map((a) => a.version)
      .sort((a, b) => {
        const aa = a
          .split('.')
          .map((n) => +n + 100000)
          .join('.');
        const bb = b
          .split('.')
          .map((n) => +n + 100000)
          .join('.');
        if (aa < bb) return -1;
        if (aa > bb) return 1;
        return 0;
      });
  }

  @action getLatestVersion(code: string) {
    const versions = this.getSortedVersions(code);
    if (!versions.length) {
      return null;
    }

    return versions.slice(-1)[0];
  }

  getTranslatedCourseName(
    slugOrCode: string,
    locale = 'en',
    uniqueCatalogEntries = this.uniqueCatalogEntries,
  ) {
    const { code } = extractFromSlug(slugOrCode);
    const entry = uniqueCatalogEntries[code];
    const translatedName = entry?.translations?.find(
      (element) => element.language === locale,
    )?.title;

    return translatedName || entry?.title || code;
  }

  /**
   * Given a course code, returns any valid full slug (code + version) for the respective entry.
   * Useful for when we need the slug for linking to a course, but we don't know the version.
   * Does not guarantee the highest version.
   * @param {*} code course code without version
   * @param {*} uniqueCatalogEntries
   * @returns course slug (code + version)
   */
  getAnySlugFromCode(code, uniqueCatalogEntries = this.uniqueCatalogEntries) {
    if (code.includes('-')) {
      return code;
    }

    const entry = uniqueCatalogEntries[code];

    return entry.slug;
  }

  @computed get hasCatalogLoaded() {
    return !(
      isEmpty(this.groupedCatalogEntries) &&
      isEmpty(this.groupedAllCatalogEntries)
    );
  }

  getCatalogEntryForCourse(courseCode: string, courseVersion: string) {
    const code = `${courseCode}`.toLowerCase();

    return (
      this.groupedCatalogEntries?.[code]?.[courseVersion] ||
      this.groupedAllCatalogEntries?.[code]?.[courseVersion]
    );
  }

  private static initializeFilters(): CatalogFilters {
    return {
      categories: [],
      products: [],
      earlyAccess: [],
      languages: [],
      modalities: [],
      roles: [],
      taxonomyProducts: [],
      taxonomyAudiences: [],
      taxonomyTopics: [],
      level: [],
    };
  }

  static isGreaterVersion(v1, v2) {
    return (
      v1
        .split('.')
        .map((n) => +n + 100000)
        .join('.')
        .localeCompare(
          v2
            .split('.')
            .map((n) => +n + 100000)
            .join('.'),
        ) > 0
    );
  }

  /**
   * Calculates the count for each filter option as well as sort them by a compare function.
   * Given an array of values, returns an array of objects with the following properties:
   * @param {Array} values - an array of filter values to be counted for each filter option
   * @param {Array} vocabulary - an array of objects with display_name and token properties
   */
  static renderFilters({
    values,
    vocabulary = [],
    compareFn = null,
    preSelected = [],
    missingVocabularyPolicy = 'ignore',
  }: {
    /**
     * An array of values to be counted for each filter option.
     */
    values: any[];
    /**
     * An array of vocabulary items to use for display names.
     */
    vocabulary?: VocabularyItem[];
    /**
     * A compare function to sort the filters by. If not provided, will sort by count in descending order.
     */
    compareFn?:
      | ((a: CatalogFilterState, b: CatalogFilterState) => number)
      | null;
    /**
     * An array of pre-selected filter keys to be checked as true.
     */
    preSelected?: any[];
    /**
     * If set to 'ignore', will not render filters that do not have a corresponding vocabulary entry.
     * If set to 'useValues', will render filters with the value as the display name.
     */
    missingVocabularyPolicy?: 'ignore' | 'useValues';
  }) {
    const count = countBy(values);

    const filters = Object.keys(count).reduce(
      (filtered: CatalogFilterState[], entryId) => {
        const foundVocabulary = vocabulary.find(
          (item) => entryId === item.token,
        );

        let value = '';

        if (!foundVocabulary) {
          if (missingVocabularyPolicy === 'ignore') {
            return filtered;
          }
          value = entryId;
        } else {
          value = foundVocabulary.display_name;
        }

        const checked = preSelected.includes(entryId) || false;

        filtered.push({
          key: entryId,
          value,
          count: count[entryId],
          checked,
        } as CatalogFilterState);

        const compareByCount = (a: CatalogFilterState, b: CatalogFilterState) =>
          b.count - a.count;

        return filtered.sort(compareFn || compareByCount);
      },
      [],
    );
    return filters;
  }

  static composeCatalogGroups(catalogEntries: CatalogEntry[]) {
    // Construct a dict of all offerings by course code and version, omitting video classrooms
    const versionedEntries = catalogEntries
      .filter(
        (entry) =>
          entry.modality !== Modality.VideoClassroom &&
          entry.visibility !== OfferingVisibility.Hidden,
      )
      .reduce((dict, entry) => {
        if (!dict[entry.code]) {
          dict[entry.code] = {};
        }
        const translation = entry.translations || [];

        dict[entry.code][entry.version] = deepmerge(entry, {
          translations: [
            {
              language: entry.language,
              title: entry.title,
            },
            ...translation,
          ],
        });
        return dict;
      }, {});

    // deepmerge courses
    return versionedEntries;
  }

  static isLocked(offeringVisibility: OfferingVisibility) {
    return (
      offeringVisibility === OfferingVisibility.Upgrade ||
      offeringVisibility === OfferingVisibility.Hidden
    );
  }

  static isEarlyAccess(offeringCode: string) {
    return !!offeringCode?.endsWith(OfferingCodePrefix.EarlyAccess);
  }

  static isRegularCourse(offeringType: CourseType) {
    return offeringType === CourseType.Regular;
  }

  static isLabPlus(offeringType: CourseType) {
    return offeringType === CourseType.LabPlus;
  }

  static isLesson(offeringType: CourseType) {
    return offeringType === CourseType.Lesson;
  }

  /**
   *  Prioritizes a field in an entry based on an array of fields.
   * @param {CatalogEntry} entry  - the entry to prioritize the field for
   * @param {Array} fields  - an array of fields to prioritize
   * @param {Boolean} isArray - if true, the field is expected to be an array
   * @returns  the prioritized field value
   */
  static prioritizeField = (
    entry: unknown,
    fields: string[],
    isArray: boolean,
  ) => {
    for (let i = 0; i < fields.length; i += 1) {
      const field = fields[i];
      const value = lodashGet(entry, field);
      if (isArray) {
        if (Array.isArray(value) && value.length > 0) {
          return value;
        }
      } else if (value) {
        return value;
      }
    }
    return isArray ? [] : null;
  };
}

export default CatalogStore;
