import { observable, action, computed, toJS } from 'mobx';
import * as merge from 'deepmerge';
import { submitSupportCase } from '../../../services/SupportService';

class SupportStore {
  @observable formSubmitting = false;

  @observable formSubmitted = false;

  @observable formError = false;

  @observable formData = {};

  @observable catalogEntries = [];

  @observable schemaForm = {};

  uiSchema = {};

  impactFields = {};

  constructor(rootStore, schemaForm, uiSchema, impactFields) {
    this.rootStore = rootStore;
    this.schemaForm = schemaForm;
    this.uiSchema = uiSchema;
    this.impactFields = impactFields;
  }

  async init() {
    const entries =
      await this.rootStore.catalogStore.getCurrentSubscriptionCatalog();
    this.catalogEntries = this.selectCatalogEntries(entries);
    this.processCourseCodeEnums();
  }

  @computed get submitting() {
    return this.formSubmitting;
  }

  set submitting(boolean) {
    this.formSubmitting = boolean;
  }

  @computed get submitted() {
    return this.formSubmitted;
  }

  set error(boolean) {
    this.formError = boolean;
  }

  @computed get error() {
    return this.formError;
  }

  @computed get issue() {
    return this.issueObj;
  }

  set submitted(boolean) {
    this.formSubmitted = boolean;
  }

  @action async submit() {
    try {
      this.submitting = true;
      await submitSupportCase(this.prepareData());
      this.submitting = false;
      this.submitted = true;
    } catch (error) {
      console.error('submit error:', error);
      this.submitting = false;
      this.formError = true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  selectCatalogEntries(entries) {
    return entries.filter(
      (entry) => !entry.slug.includes('ea') && !entry.slug.includes('exps'),
    );
  }

  @action processCourseCodeEnums() {
    this.schemaForm.properties.courseCode.enum = [];
    const courseSlugs = this.catalogEntries.map(
      (entry) => `${entry.slug?.toUpperCase()}: ${entry.title}`,
    );

    courseSlugs.push('Not Applicable');

    const updatedSchema = {
      properties: {
        courseCode: {
          enum: toJS(courseSlugs),
        },
      },
    };

    this.schemaForm = merge(toJS(this.schemaForm), updatedSchema);
  }

  @action prepareData() {
    const country = this.rootStore.userStore.country || 'Not set';
    const { title, description, impact, subcategory } = this.formData;
    const courseCode = this.formData.courseCode?.split(':')[0].toLowerCase();
    const data = {
      ...this.formData.collected,
      title,
      description,
      impact: this.impactFields[impact],
      subcategory:
        subcategory === 'Learning Platform' ? 'ROL Portal' : subcategory,
      country,
      courseCode,
    };

    return data;
  }
}

export default SupportStore;
