/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { when, reaction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import {
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  FormGroup,
} from 'react-bootstrap';
import { Button } from '@patternfly/react-core';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import { withErrorBoundary } from 'react-error-boundary';
import moment from 'moment';
import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import ViewLoader from 'components/Loading/View';
import Filters from 'components/PremiumFilter';
import TableColumn from 'components/Table/Column';
import InstructorName from 'components/SessionDetails/InstructorName';
import { storesContext } from 'stores';
import { formatDateTime } from 'services/UtilityService';
import SessionDetails from 'components/SessionDetails';
import {
  PREMIUM_SCHEDULED_SESSION_SORT_FILEDS,
  PREMIUM_VT_SESSION_SEATS_RANGE,
} from 'config/constants';
import ScheduleSessionStore from './store';
import './styles.scss';

const ScheduleSession = () => {
  const { i18n, t } = useTranslation();
  const {
    catalogStore,
    routerStore,
    uiStore,
    userStore,
    vocabularyStore,
    premiumSessionsStore,
    premiumSessionFilterStore,
    classesStore,
  } = useContext(storesContext);

  const locale = i18n.language;

  const scheduleSessionStore = useLocalStore(
    () =>
      new ScheduleSessionStore(
        catalogStore,
        premiumSessionFilterStore,
        classesStore,
        routerStore,
      ),
  );
  uiStore.title = routerStore?.route?.params?.title;

  const { currentPage, paginatedEntries, setCurrentPage, totalPages } =
    scheduleSessionStore;

  const { title } = uiStore;
  document.title = t(title);

  const presetFilters = (clear = false, routerName) => {
    if (clear && routerName === 'premium:sessions:live') {
      catalogStore.clearFilters();
      premiumSessionFilterStore.clearFilters();
      premiumSessionFilterStore.getFilterInfo(i18n, t, vocabularyStore);
    }

    scheduleSessionStore.initSelectedSessionToEnroll();
    catalogStore.presetFilters(routerStore?.route?.params?.filter);
    premiumSessionFilterStore.presetFilters(
      premiumSessionFilterStore.flattenFilters('instructors'),
    );
  };

  const premSessionSlugSplitted =
    scheduleSessionStore.premSessionSlug?.split('-');

  const setIsRequestingSession = () => {
    const { sessionslug } = routerStore.route.params;
    scheduleSessionStore.isRequestingSession =
      !premiumSessionsStore.sessionSlugHasScheduledSessions(sessionslug);
  };

  const courseSlug = premSessionSlugSplitted.length
    ? `${premSessionSlugSplitted[0]}-${premSessionSlugSplitted[1]}`
    : '';

  useEffect(() => {
    // Populate catalog data and filters on initial load - this happens on hard browser refresth
    when(
      () => !catalogStore.loaded && userStore.subscription.subscription,
      async () => {
        const entries = await catalogStore.getCurrentSubscriptionCatalog();

        if (entries) {
          catalogStore.getFilterInfo();
        }
      },
    );

    setIsRequestingSession();
  }, []);

  // Premium sessions
  useEffect(() => {
    // // Select preset filters on initial load
    when(
      () =>
        catalogStore.loaded &&
        premiumSessionsStore.scheduledSessionsloaded &&
        routerStore?.route?.name,

      async () => {
        scheduleSessionStore.premSessionSlug =
          routerStore?.route?.params.sessionslug;

        premiumSessionFilterStore.getFilterInfo(
          i18n,
          t,
          vocabularyStore,
          scheduleSessionStore.filterToApply,
        );
        const { getVocabularyByNamespace } = vocabularyStore;
        scheduleSessionStore.languages = await getVocabularyByNamespace(
          'languages',
          'en-US',
        );

        scheduleSessionStore.ready = true;
      },
    );

    // Update preset filters when route changes
    reaction(
      () =>
        catalogStore.loaded &&
        premiumSessionsStore.scheduledSessionsloaded &&
        routerStore?.route?.name,
      () => presetFilters(true, routerStore?.route?.name),
    );

    // Check for session availability to display request form
    reaction(
      () => premiumSessionsStore.scheduledSessionsloaded,
      () => {
        setIsRequestingSession();
      },
    );
  }, []);

  const SubscriptionExpiredModal = () => {
    return (
      <Modal
        show={scheduleSessionStore.showSubscriptionExpiredModal}
        onHide={scheduleSessionStore.toggleSubscriptionExpiredModal}
        size="sm"
        dialogClassName="subscription-expired-modal container"
      >
        <ModalHeader closeButton>
          <ModalTitle>
            <strong>{t('Warning')}</strong>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>
            {t('Your subscription will expire on')}{' '}
            {moment(userStore.subscriptionEndTime).format('YYYY/MM/DD')}{' '}
            {t(
              'which is before the end date of this session. Please renew your subscription to select this session, or choose another date from the schedule.',
            )}
          </p>
          <p className="subscription-expired-modal-close-button">
            <Button
              variant="primary"
              onClick={scheduleSessionStore.toggleSubscriptionExpiredModal}
            >
              {t('Close')}
            </Button>
          </p>
        </ModalBody>
      </Modal>
    );
  };

  const ScheduleSessionRow = (p) => {
    const { entry, instructorLanguage } = p;
    const isSessionFull =
      premiumSessionsStore.convertAvailableSeatsToRange(
        entry.seats_available,
      ) === PREMIUM_VT_SESSION_SEATS_RANGE.full;
    const isSessionAfterSubscriptionEnds =
      moment(entry.end_time).diff(moment(userStore.subscriptionEndTime)) > 0;
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        onClick={() => {
          if (!isSessionFull && !isSessionAfterSubscriptionEnds)
            scheduleSessionStore.onSelectSessionToSchedule(entry.doc_id);
          else if (isSessionAfterSubscriptionEnds)
            scheduleSessionStore.toggleSubscriptionExpiredModal();
        }}
        className={`session-row ${isSessionFull ? 'full' : ''} ${
          entry.selected ? 'selected' : ''
        } ${isSessionAfterSubscriptionEnds ? ' disabled' : ''}`}
      >
        <div className="session-column-row">
          <div className="col-sm-2">
            <span className="sp-sm-header">{t('Date')} &nbsp; &nbsp; </span>
          </div>
          <div className="col-sm-10 col-md-12">
            {!isSessionFull && (
              <input
                type="radio"
                id={entry.doc_id}
                name="rd-session"
                className="rd-session-radio"
                {...(entry.selected && {
                  defaultChecked: 'checked',
                })}
                value={entry.doc_id}
                disabled={isSessionAfterSubscriptionEnds}
              />
            )}
            <label htmlFor={entry.doc_id}>
              {entry.start_time
                ? formatDateTime(entry.start_time, locale, 'll')
                : ''}
            </label>
          </div>
        </div>
        <div className="session-column-row">
          <div className="col-sm-2">
            <span className="sp-sm-header">{t('Time')} &nbsp; &nbsp; </span>
          </div>
          <div className="col-sm-10 col-md-12">
            {entry.start_time ? formatDateTime(entry.time, locale, 'LT') : ''}
          </div>
        </div>
        <div className="session-column-row">
          <div className="col-sm-2">
            <span className="sp-sm-header">{t('Language')} &nbsp; &nbsp; </span>
          </div>
          <div className="col-sm-10 col-md-12">{instructorLanguage}</div>
        </div>
        <div className="session-column-row">
          <div className="col-sm-2">
            <span className="sp-sm-header">
              {t('Instructors')}&nbsp; &nbsp;
            </span>
          </div>
          <div className="col-sm-10 col-md-12">
            {entry?.instructors?.length
              ? entry.instructors
                  .map((instructor) => (
                    <InstructorName
                      instructor={premiumSessionsStore.getDetailsForInstructor(
                        instructor,
                      )}
                    />
                  ))
                  .reduce((prev, curr) => [prev, ', ', curr])
              : '---'}
          </div>
        </div>
        <div className="session-column-row">
          <div className="col-sm-2">
            <span className="sp-sm-header">{t('Seats')}&nbsp; &nbsp;</span>
          </div>
          <div className="col-sm-10 col-md-12">
            {entry?.seats_available >= 0
              ? t(
                  premiumSessionsStore.convertAvailableSeatsToRange(
                    entry.seats_available,
                  ),
                )
              : t('No info')}
          </div>
        </div>
      </div>
    );
  };
  const renderScheduleSessionColumn = () => {
    return (
      <React.Fragment>
        <TableColumn
          isSortAscending={scheduleSessionStore.isSortAscending}
          currentSortByField={scheduleSessionStore.sortByField}
          sortByField={PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.date}
          onSort={(sortByField) => {
            scheduleSessionStore.onSortBy(sortByField);
          }}
          title={t('Date')}
        />
        <TableColumn
          isSortAscending={scheduleSessionStore.isSortAscending}
          title={t('Time')}
          currentSortByField={scheduleSessionStore.sortByField}
          sortByField={PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.time}
          onSort={(sortByField) => {
            scheduleSessionStore.onSortBy(sortByField);
          }}
          includeInfoIcon
        />
        <TableColumn
          isSortAscending={scheduleSessionStore.isSortAscending}
          title={t('Language')}
          currentSortByField={scheduleSessionStore.sortByField}
          sortByField={PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.language}
          onSort={(sortByField) => {
            scheduleSessionStore.onSortBy(sortByField);
          }}
        />

        <TableColumn
          isSortAscending={scheduleSessionStore.isSortAscending}
          currentSortByField={scheduleSessionStore.sortByField}
          sortByField={PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.instructor}
          onSort={(sortByField) => {
            scheduleSessionStore.onSortBy(sortByField);
          }}
          title={t('Instructor')}
        />

        <TableColumn
          isSortAscending={scheduleSessionStore.isSortAscending}
          currentSortByField={scheduleSessionStore.sortByField}
          sortByField={PREMIUM_SCHEDULED_SESSION_SORT_FILEDS.availableSeats}
          onSort={(sortByField) => {
            scheduleSessionStore.onSortBy(sortByField);
          }}
          title={t('Seats')}
        />
      </React.Fragment>
    );
  };

  const renderSubmitProgressModal = () => {
    return (
      <React.Fragment>
        <Modal
          size="sm"
          show={scheduleSessionStore.isShowASubmitProgress}
          onHide={() => {
            scheduleSessionStore.toggleSubmitProgressModal();
          }}
          dialogClassName="schedule-progress-modal-dialog container"
        >
          <ModalHeader closeButton>
            <ModalTitle
              className={
                !scheduleSessionStore.submitProgress?.hasError
                  ? 'modal-title success-message'
                  : 'modal-title error-message'
              }
            >
              {`${t('Schedule Session')}: `}
              {!scheduleSessionStore.submitProgress?.hasError
                ? t('Success')
                : t('Error')}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="model-wrapper">
              <FormGroup>
                <div className="progress-message">
                  {scheduleSessionStore.submitProgress.message}
                </div>
                <div className="progress-message-button">
                  <Button
                    variant="primary"
                    onClick={() => {
                      scheduleSessionStore.toggleSubmitProgressModal();
                      if (!scheduleSessionStore.submitProgress.hasError) {
                        routerStore.navigate('premium:sessions:live');
                      }
                    }}
                  >
                    {t('OK')}
                  </Button>
                </div>
              </FormGroup>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  };

  return (
    <div className="container-grid-responsive">
      <React.Suspense fallback={<ViewLoader />}>
        <Row className="schedule-container">
          <Col xs={12} md={4}>
            <Filters showFullSeatsFilter />
          </Col>

          <Col xs={12} md={8}>
            {(scheduleSessionStore.ready && (
              <SessionDetails
                data={{
                  ...(premiumSessionsStore
                    .groupedPremiumSessionContentByOffering[courseSlug] || {}),
                  duration_secs:
                    premiumSessionsStore.getSessionSecsDurationBySessionSlug(
                      scheduleSessionStore.premSessionSlug,
                    ),
                  premvt_session_slug: scheduleSessionStore.premSessionSlug,
                }}
                showMetaData={scheduleSessionStore.isRequestingSession}
                isRequestingSession={scheduleSessionStore.isRequestingSession}
              />
            )) ||
              null}
            {scheduleSessionStore.ready ? (
              paginatedEntries.length > 0 && (
                <section className="schedule-list-container">
                  <div className="schedule-list-header">
                    {renderScheduleSessionColumn()}
                  </div>
                  <React.Fragment>
                    <div className="schedule-list-wrapper">
                      {paginatedEntries.map((entry) => {
                        const { doc_id: docId } = entry;
                        const lan = scheduleSessionStore.languages?.find(
                          (l) => l.token === entry.instruction_language,
                        );

                        return (
                          <ScheduleSessionRow
                            entry={entry}
                            key={docId}
                            instructorLanguage={
                              // eslint-disable-next-line camelcase
                              (lan && lan?.display_name) ||
                              entry.instruction_language
                            }
                          />
                        );
                      })}
                    </div>
                    <div className="schedule-pagination">
                      <UltimatePagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onChange={setCurrentPage}
                      />
                    </div>
                    <div className="schedule-buttons">
                      {scheduleSessionStore.isRescheduling && (
                        <Button
                          className="cancel-reschedule-button schedule-reschedule-btn"
                          onClick={() => {
                            routerStore.navigate('home');
                          }}
                        >
                          {t('Cancel')}
                        </Button>
                      )}
                      {userStore.isFreeTierSubscriber ||
                      userStore.hasReachedCurrentSubscriptionPremiumVTLimit ? (
                        <Button
                          variant="link"
                          isDisabled
                          data-analytics-id="schedule-btn-session-scheduler-view-freeTier-lp"
                          className="disabled-patternfly-btn schedule-reschedule-btn"
                        >
                          {t('Schedule')}
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          disabled={!scheduleSessionStore.isAllowToSchedule}
                          type="submit"
                          onClick={() => {
                            scheduleSessionStore.enrollUser(t);
                          }}
                          data-analytics-id="schedule-btn-session-scheduler-view-lp"
                          className="schedule-reschedule-btn"
                        >
                          {!scheduleSessionStore.submitting ? (
                            `${
                              scheduleSessionStore.isRescheduling
                                ? t('Reschedule')
                                : t('Schedule')
                            }`
                          ) : (
                            <span className="spinner-wrapper">
                              <ViewLoader color="#ffffff" />
                            </span>
                          )}
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                </section>
              )
            ) : (
              <ViewLoader />
            )}
          </Col>
        </Row>
        {scheduleSessionStore.isShowASubmitProgress &&
          renderSubmitProgressModal()}
        {scheduleSessionStore.showSubscriptionExpiredModal &&
          SubscriptionExpiredModal()}
      </React.Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(ScheduleSession),
  GenericViewError,
  handleError,
);
