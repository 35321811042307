/* eslint-disable camelcase */

import { action, autorun, computed, observable } from 'mobx';
import Moment from 'moment';
import { get, find, has, pick, first } from 'lodash';
import { reportLoggedUser, reportError } from 'config/sentry';

import {
  getActiveSubscription,
  getCurrentUserRecord,
  getUserAvatar,
  postUserAvatar,
  getUserGroups,
  patchUserRecord,
  getUserInfo,
  patchUserInfo,
  getPageView,
  getVideoView,
  getLastLogin,
  getHoursSpent,
  getKalturaVideosWatched,
  getCredlyBadges,
  registerFreeTrial,
  restartFreeTrial,
  getEnablements,
  getQuotaUsage,
} from '../services/UserService';

import {
  isBasicSubscriber,
  isFreeTierSubscriber,
  isLimitedTrialSubscriber,
  isStandardSubscriber,
  isPremiumSubscriber,
  isRHUSubscriber,
  isLS300Subscriber,
  isLS101Subscriber,
  isXS001Subscriber,
  isWD200Subscriber,
} from '../services/SubscriptionService';

import { getPartnerInfo } from '../services/PartnerService';
import { getCustomerOrgInfo } from '../services/UserOrgService';

import { impersonate } from '../services/AuthService';

import labLimits from '../config/labLimits';

import {
  FREE_TIER_CONTACT_US,
  COMPLIANCE_BANNER_DUE_DAYS,
  ENABLEMENTS_POLLING_SECONDS,
} from '../config/constants';

import { getProperCaseForAString } from '../services/UtilityService';

class UserStore {
  @observable avatar;

  @observable privacyConsents = {
    functional: false,
    advertising: false,
  };

  @observable user = {};

  @observable _isLoggedIn = false;

  @observable groups = [];

  @observable didFetchGroups = false;

  @observable subscription = {};

  @observable didFetchSubscription = false;

  @observable partnerInfo = {};

  @observable managedGroups = [];

  @observable userinfo = {};

  @observable usageSummary = {};

  @observable kalturaVideosWatched = 0;

  @observable jobs = [];

  @observable myOrganizations = [];

  @observable achievements = [];

  @observable isLoadingAchievements = true;

  @observable roles = [];

  @observable enablements = {};

  @observable enablementsQuotas = {};

  @observable enablementsConfigurations = {};

  @observable didSetQuotasConfigurations = false;

  @observable enablementsPollingIntervalID = null;

  constructor(rootStore) {
    this.rootStore = rootStore;

    autorun(() => {
      if (!this.user.settings) {
        this.user.settings = {
          communication: {
            reports: {
              cc: [''],
              enabled: false,
            },
          },
        };
      }
    });
  }

  init() {
    const user = this.getCurrentUser();

    // since info about subscription is not necessary for login,
    // let's move this fetching the subscription and managed groups
    // into a separate sequence.
    user
      .then(() => {
        this.getCurrentUserGroups();
        this.getCurrentUserAvatar();
        this.getActiveSubscription();
        this.fetchManagedGroups();
        this.getUserinfo();
        this.getUsageSummary();
      })
      .catch((e) => {
        reportError(e, null, 'userStore.init', 'error');
        throw e;
      });

    return user;
  }

  @computed get enforceLabLimit() {
    if (this.isVTonly || this.hasILT) return false;

    return this.enablementsConfigurations?.enforce_lab_limit || false;
  }

  @computed get canShowLabUsageAlert() {
    if (!this.didSetQuotasConfigurations) return false;

    if (
      this.currentSubscriptionCode &&
      has(this.enablementsConfigurations, 'upcoming_lab_usage_alert')
    ) {
      return true;
    }

    if (this.isAlaCarte) {
      const hasLabUsageAlert = find(this.enablements, (enablement) => {
        return has(enablement.configurations, 'upcoming_lab_usage_alert');
      });

      if (hasLabUsageAlert) return true;
    }
    return false;
  }

  @computed get currentUser() {
    return this.user;
  }

  @computed get username() {
    return this.user?.username;
  }

  @computed get name() {
    const firstName = this.user?.first_name;
    const lastName = this.user?.last_name;
    const name = firstName && lastName ? `${firstName} ${lastName}` : undefined;
    return this.preferredName || name;
  }

  @computed get preferredName() {
    return this.user?.preferred_name;
  }

  @computed get email() {
    return this.user?.default_email;
  }

  @computed get defaultCountry() {
    return this.user?.default_country;
  }

  @computed get country() {
    return this.subscription?.country;
  }

  @computed get isLoggedIn() {
    return this._isLoggedIn;
  }

  set isLoggedIn(bool) {
    this._isLoggedIn = bool;
  }

  @computed get isReportingUser() {
    return this.groups?.includes('Reporting');
  }

  @computed get isManager() {
    return (
      this.username &&
      this.subscription?.groups &&
      this.managedGroups.length > 0
    );
  }

  @computed get isInstructor() {
    return this.groups?.includes('Instructors');
  }

  @computed get isImpersonatingUser() {
    return 'impersonator' in this.user;
  }

  @computed get impersonatedUser() {
    if (this.isImpersonatingUser) {
      return this.user;
    }

    return undefined;
  }

  @computed get isFreeTierSubscriber() {
    return this.user && isFreeTierSubscriber(this.subscription?.subscription);
  }

  @computed get isLimitedTrialSubscriber() {
    return (
      this.user && isLimitedTrialSubscriber(this.subscription?.subscription)
    );
  }

  @computed get isBasicSubscriber() {
    return this.user && isBasicSubscriber(this.subscription?.subscription);
  }

  @computed get isStandardSubscriber() {
    return this.user && isStandardSubscriber(this.subscription?.subscription);
  }

  @computed get isPremiumSubscriber() {
    return this.user && isPremiumSubscriber(this.subscription?.subscription);
  }

  @computed get isRHUSubscriber() {
    return this.user && isRHUSubscriber(this.subscription?.subscription);
  }

  @computed get isLS300Subscriber() {
    return this.user && isLS300Subscriber(this.subscription?.subscription);
  }

  @computed get isLS101Subscriber() {
    return this.user && isLS101Subscriber(this.subscription?.subscription);
  }

  @computed get isXS001Subscriber() {
    return this.user && isXS001Subscriber(this.subscription?.subscription);
  }

  @computed get isWD200Subscriber() {
    return this.user && isWD200Subscriber(this.subscription?.subscription);
  }

  @computed get isExternalSubscriber() {
    return (
      this.user &&
      (this.subscription?.subscription?.startsWith('xs') ||
        this.isOpenSubscriber)
    );
  }

  @computed get isOpenSubscriber() {
    const oe = this.rootStore.classesStore.standard.filter(
      (e) => e.etype === 'open',
    );
    return (
      !this.currentSubscriptionCode &&
      !!(oe.length && oe.length === this.rootStore.classesStore.standard.length)
    );
  }

  @action isOpenSubscriberForCourse(slug) {
    return !!this.rootStore.classesStore.standard.filter(
      (e) => e.etype === 'open' && e.slug_tag === slug,
    ).length;
  }

  @computed get hasUserAnyPastSubscription() {
    return Boolean(this.user?.subscription_past);
  }

  @computed get isPastSubscriptionFreeTrial() {
    return isFreeTierSubscriber(this.userRecentlyExpiredSubscription);
  }

  @computed get userRecentlyExpiredSubscription() {
    return this.user?.recent_expired_subscription; // a string like ls120
  }

  @computed get currentSubscriptionCode() {
    return this.subscription?.subscription || this.user?.current_subscription;
  }

  @computed get hasActiveSubscription() {
    return Boolean(this.subscription?.subscription);
  }

  @computed get isAlaCarte() {
    return (
      !this.currentSubscriptionCode &&
      !!this.rootStore.classesStore.offerings.filter(
        (e) => e._catalog === false,
      ).length
    );
  }

  @computed get hasAlaCarteEnrollments() {
    return !!this.rootStore.classesStore.offerings.filter(
      (e) => e._catalog === false,
    ).length;
  }

  @computed get getSubscriptionPrettyText() {
    if (this.currentSubscriptionCode?.toLowerCase().startsWith('ls')) {
      return this.currentSubscriptionCode.toUpperCase();
    }

    if (this.isOpenSubscriber) {
      return 'GPTE Open';
    }

    if (this.hasAlaCarteEnrollments) {
      return 'A la carte';
    }

    return 'No subscription';
  }

  @computed get hasVT() {
    return !!this.rootStore.classesStore.vtClasses.length;
  }

  @computed get isVTonly() {
    return this.hasVT === true && !this.currentSubscriptionCode;
  }

  @computed get hasILT() {
    return !!this.rootStore.classesStore.iltClasses.length;
  }

  @computed get isAdmin() {
    return this.groups?.includes('Admin');
  }

  @computed get isAdminOrOperations() {
    return (
      this.groups?.includes('Admin') || this.groups?.includes('Operations')
    );
  }

  @computed get isOrganizationAdmin() {
    return (
      this.user.group_set?.filter((group) => {
        return group.indexOf('ole_org_admins') > -1;
      }).length >= 1
    );
  }

  @computed get isOrganizationManager() {
    return (
      this.user.group_set?.filter((group) => {
        return group.indexOf('ole_org_managers') > -1;
      }).length >= 1
    );
  }

  @computed get isLabPowerUser() {
    return this.roles?.includes('lab_power_user');
  }

  @computed get isOrganizationLeader() {
    return this.isOrganizationManager || this.isOrganizationAdmin;
  }

  @computed get isInstructorOrAdmin() {
    return (
      this.groups?.includes('Instructors') || this.groups?.includes('Admin')
    );
  }

  @computed get hasLabHours() {
    if (!this.enforceLabLimit) {
      return true;
    }

    const labQuota = this.enablementsQuotas?.lab_hours;
    if (!labQuota) return true;

    const { allowed = 0, used = 0 } = labQuota;
    const hasHours = allowed > used;

    return hasHours;
  }

  @computed get hasExams() {
    if (this.enablementsQuotas?.exams) {
      const { exams } = this.enablementsQuotas;
      return exams.allowed - exams.used > 0;
    }
    return false;
  }

  @computed get hasExamRetakes() {
    // LS300 subscribers have a different behavior
    // and they can retake exams as long as they have
    // available exams
    if (this.isLS300Subscriber) {
      const { exams } = this.enablementsQuotas;
      return exams.used < exams.allowed;
    }

    if (this.enablementsQuotas?.exam_retakes) {
      const { exam_retakes: retakes } = this.enablementsQuotas;
      return retakes.allowed - retakes.used > 0;
    }

    return false;
  }

  @computed get subscriptionDaysLeft() {
    const currentTime = new Moment();
    const endDate = new Moment(this.subscription?.end_time);
    const diff = Moment.duration(endDate.diff(currentTime));
    return Math.ceil(diff.asDays());
  }

  @computed get numberTotalUsedPremiumSessions() {
    if (this.enablementsQuotas?.premvt_sessions?.used) {
      return this.enablementsQuotas?.premvt_sessions?.used;
    }
    return 0;
  }

  @computed get numberTotalEnrollPremiumSessions() {
    if (this.enablementsQuotas?.sessions_scheduled?.used) {
      return this.enablementsQuotas?.sessions_scheduled?.used;
    }
    return 0;
  }

  @computed get currentSubscriptionPremiumVTLimit() {
    if (this.enablementsQuotas?.premvt_sessions?.allowed) {
      return this.enablementsQuotas?.premvt_sessions?.allowed;
    }
    return Infinity;
  }

  @computed get currentSubscriptionEnrollPremiumVTLimit() {
    if (this.enablementsQuotas?.sessions_scheduled?.allowed) {
      return this.enablementsQuotas?.sessions_scheduled?.allowed;
    }
    return Infinity;
  }

  @computed get hasReachedCurrentSubscriptionPremiumVTLimit() {
    return (
      this.numberTotalUsedPremiumSessions >=
      this.currentSubscriptionPremiumVTLimit
    );
  }

  @computed get hasReachedCurrentSubscriptionEnrollPremiumVTLimit() {
    return (
      this.numberTotalEnrollPremiumSessions >=
      this.currentSubscriptionEnrollPremiumVTLimit
    );
  }

  @computed get remainingPdfDownloads() {
    if (this.enablementsQuotas?.pdf_downloads) {
      return (
        this.enablementsQuotas?.pdf_downloads?.allowed -
        this.enablementsQuotas?.pdf_downloads?.used
      );
    }
    return 0;
  }

  @computed get totalUsedLabHours() {
    const labHours = this.enablementsQuotas?.lab_hours || {};
    const { allowed = 0, used = 0 } = labHours;
    return Math.round(Math.min(allowed, used));
  }

  @computed get labHoursLeft() {
    const labHours = this.enablementsQuotas?.lab_hours || {};
    const { allowed = 0, used = 0 } = labHours;
    return Math.max(allowed - used, 0);
  }

  @computed get totalAllowedLabHours() {
    if (!this.enforceLabLimit && this.isAlaCarte) return 80;

    // Currently all the subscriptions are not limited
    // so we want to display the standard subscription limit when this happens
    // Currently default value is 40000, adding a threshold in case it changes
    const notSetLabHoursThreshold = 10000;
    if (this.enablementsQuotas?.lab_hours?.allowed > notSetLabHoursThreshold) {
      return labLimits[this.subscription?.subscription];
    }

    // If a custom value has been set in the subscription different to the default
    // but lower than the threshold
    return this.enablementsQuotas?.lab_hours?.allowed || 0;
  }

  @computed get remainingLabHours() {
    return this.totalAllowedLabHours - this.totalUsedLabHours;
  }

  @computed get chatTermsAccepted() {
    return this.userinfo.chat_terms_accepted || false;
  }

  @computed get isComplianceBannerDue() {
    if (!this.userinfo?.last_compliance_banner_display_time) {
      // First time login
      return true;
    }
    const currentTime = new Moment();

    const lastComplianceDisplayDate = new Moment(
      this.userinfo?.last_compliance_banner_display_time,
    );
    const diff = Moment.duration(currentTime.diff(lastComplianceDisplayDate));
    const daysDue = Math.floor(diff.asDays());
    return daysDue >= COMPLIANCE_BANNER_DUE_DAYS;
  }

  @computed get needsPrivacyBanner() {
    return this.myOrganizations?.length && !this.userinfo?.privacy_dismissed;
  }

  @computed get needsPremiumIntroBanner() {
    return this.isPremiumSubscriber && !this.userinfo?.premium_intro_dismissed;
  }

  @computed get partnerId() {
    return this.subscription?.partner_id;
  }

  @computed get partnerUrl() {
    const { url } = this.partnerInfo;
    return url || FREE_TIER_CONTACT_US;
  }

  @computed get subscriptionEndTime() {
    return this.subscription?.end_time;
  }

  @computed get isUserAuthorized() {
    return (
      this.hasActiveSubscription ||
      this.hasVT ||
      this.isOpenSubscriber ||
      this.hasAlaCarteEnrollments
    );
  }

  @computed get isLocalLogin() {
    return this.user?.domain?.toLowerCase().startsWith('local@@');
  }

  @computed get userPreferences() {
    return this.user?.preferences || {};
  }

  @computed get isHUDEnabledByUser() {
    const value = this.userPreferences.active_course_view || 'hud';
    return value === 'hud';
  }

  @action getPartnerInfo = async (force) => {
    if (!this.partnerId || (this.partnerInfo?.shortname && !force)) {
      return this.partnerInfo;
    }

    const info = await getPartnerInfo(this.partnerId);

    this.partnerInfo = info.items[0] || this.partnerInfo?.items[0];
    return this.partnerInfo;
  };

  @action getCurrentUserAvatar() {
    return getUserAvatar(this.user.uuid)
      .then((image) => {
        this.avatar = image;
        return this.avatar;
      })
      .catch(() => {
        return this.avatar;
      });
  }

  @action postCurrentUserAvatar(image) {
    return postUserAvatar(this.user.uuid, image).then((success) => {
      if (success) {
        getUserAvatar(this.user.uuid)
          .then((setImage) => {
            this.avatar = setImage;
          })
          .catch((error) => {
            console.error(error);
            return this.avatar;
          });
      }

      return success;
    });
  }

  @action getCurrentUser = async (force) => {
    if (this.user?.uuid && !force) {
      return this.user;
    }

    try {
      const user = await getCurrentUserRecord();
      this.user = user || this.user;
    } catch (error) {
      console.error(error);
      throw error;
    }

    if (this.user && this.user.uuid) {
      reportLoggedUser({
        email:
          this.user.email || this.user.default_email || first(this.user.emails),
        id: this.user.uuid,
        username: this.user.username,
        sessionId: this.user.sessionid,
      });
    }

    this.isLoggedIn = true;

    return this.user;
  };

  @action partiallyUpdateUserRecord(data) {
    return patchUserRecord(this.user.uuid, data).then((record) => {
      this.user = record;
      return this.user;
    });
  }

  @action updateUserPreferences(newPreferences, optimistic = false) {
    const data = {
      preferences: {
        ...this.user.preferences,
        ...newPreferences,
      },
    };

    if (optimistic) {
      this.user = {
        ...this.user,
        preferences: data.preferences,
      };
    }

    return this.partiallyUpdateUserRecord(data);
  }

  @action getCurrentUserGroups() {
    return getUserGroups(this.user.uuid)
      .then((data) => {
        this.groups = data?.groups;
        this.roles = data?.roles;
        this.didFetchGroups = true;
        return this.groups;
      })
      .catch(() => {
        this.groups = [];
        this.roles = [];
        this.didFetchGroups = true;
        return this.groups;
      });
  }

  @action async getActiveSubscription() {
    try {
      const json = await getActiveSubscription(this.user.uuid);
      this.subscription = json;
      return this.subscription;
    } catch (error) {
      throw error;
    } finally {
      this.didFetchSubscription = true;

      if (!this.subscription?.subscription) {
        this.rootStore.catalogStore.getAllCatalogEntries(); // pre load all entries since user f no subscription catalog
      }
    }
  }

  @action async getEnablements(setQuotasConfiguration = true) {
    try {
      const json = await getEnablements(this.user.uuid);
      this.enablements = json;
    } catch (error) {
      this.enablements = {};
    } finally {
      if (setQuotasConfiguration) {
        this.setEnablementsQuotasConfigurations();
      }
    }
  }

  @action async getQuotasUsage(euuid = '', slug = 'lab_hours') {
    try {
      const json = await getQuotaUsage(this.user.uuid, euuid, slug);
      this.enablementsQuotas = { ...this.enablementsQuotas, ...json };
    } catch (error) {
      console.error(error);
    }
  }

  @action setEnablementsQuotasConfigurations(course_slug) {
    let setEnablements = this.enablements;

    // for alacarte get course specific enablements
    if (this.isAlaCarte && course_slug) {
      const course_code = course_slug.split('-')[0];
      setEnablements = get(this.enablements, `${course_code}`);
    }

    this.enablementsQuotas = setEnablements?.quotas || {};
    this.enablementsConfigurations = setEnablements?.configurations || {};
    this.didSetQuotasConfigurations = true;
  }

  @action startEnablementsPolling() {
    if (this.enablementsPollingIntervalID)
      clearInterval(this.enablementsPollingIntervalID);

    this.enablementsPollingIntervalID = setInterval(() => {
      if (this.currentSubscriptionCode && this.enforceLabLimit) {
        this.getEnablements();
      }

      if (this.isAlaCarte) {
        this.getEnablements(false);
      }
    }, 1000 * ENABLEMENTS_POLLING_SECONDS);
  }

  @action stopEnablementsPolling() {
    if (this.enablementsPollingIntervalID)
      clearInterval(this.enablementsPollingIntervalID);
  }

  @action fetchManagedGroups() {
    const { groups } = this.subscription;

    if (!groups) return this.managedGroups;
    const promises = groups.map((group) => getCustomerOrgInfo(group));

    return Promise.all(promises).then((values) => {
      // eslint-disable-next-line
      for (let group of values) {
        if (group.managers?.includes(this.username)) {
          this.managedGroups.push(group);
        }
      }

      return this.managedGroups;
    });
  }

  @action getUserinfo() {
    return getUserInfo(this.username).then((userinfo) => {
      this.userinfo = userinfo;
    });
  }

  @action getUsageSummary = async () => {
    const promises = [
      getPageView(this.username),
      getVideoView(this.username),
      getLastLogin(this.username),
      getHoursSpent(this.username),
      getKalturaVideosWatched(),
    ];
    try {
      const results = await Promise.allSettled(promises); // call all at once and handle errors
      const [
        pageViewsResult,
        videosWatchedResult,
        lastLoginResult,
        hoursSpentResult,
        kalturaVideosWatchedResult,
      ] = results;
      if (pageViewsResult.status === 'fulfilled') {
        this.usageSummary.pageViews = pageViewsResult.value.view_total;
      } else {
        this.usageSummary.pageViews = 0;
      }
      if (videosWatchedResult.status === 'fulfilled') {
        this.usageSummary.videosWatched = videosWatchedResult.value.view_total;
      } else {
        this.usageSummary.videosWatched = 0;
      }
      if (
        lastLoginResult.status === 'fulfilled' &&
        lastLoginResult.value.last_login
      ) {
        this.usageSummary.lastLogin = new Moment(
          lastLoginResult.value.last_login,
        ).format('YYYY/MM/DD HH:mm');
      } else {
        this.usageSummary.lastLogin = null;
      }
      if (hoursSpentResult.status === 'fulfilled') {
        this.usageSummary.hoursSpent = Math.floor(
          hoursSpentResult.value.hours_spent,
        );
      } else {
        this.usageSummary.hoursSpent = 0;
      }
      if (kalturaVideosWatchedResult.status === 'fulfilled') {
        this.kalturaVideosWatched = kalturaVideosWatchedResult.value;
      } else {
        this.kalturaVideosWatched = 0;
      }
    } catch (error) {
      console.error(error);
    }
  };

  @action acceptChatTerms() {
    return patchUserInfo(this.username, { chat_terms_accepted: true }).then(
      (userinfo) => {
        this.userinfo = userinfo;
      },
    );
  }

  @action acknowledgeComplianceBanner() {
    return patchUserInfo(this.username, {
      last_compliance_banner_display_time: new Moment().startOf('day'),
    }).then((userinfo) => {
      this.userinfo = userinfo;
    });
  }

  @action acknowledgePrivacyBanner() {
    return patchUserInfo(this.username, {
      privacy_dismissed: true,
    }).then((userinfo) => {
      this.userinfo = userinfo;
    });
  }

  @action acknowledgePremiumIntroBanner() {
    return patchUserInfo(this.username, {
      premium_intro_dismissed: true,
    }).then((userinfo) => {
      this.userinfo = userinfo;
    });
  }

  @action async invalidateUser() {
    this.isLoggedIn = false;
    this.user = {};
    this.managedGroups = [];
    this.subscription = {};

    this.rootStore.catalogStore.clearCatalog();
    this.rootStore.learningPathStore.clearPaths();
    this.rootStore.progressStore.clearProgress();
    this.stopEnablementsPolling();
  }

  @action async impersonateUser(username) {
    try {
      await impersonate(username);
      this.rootStore.routerStore.navigate('home', { reload: true });
      window.location.reload();
    } catch (error) {
      console.error('impersonateUser error:', error.response);
      // eslint-disable-next-line no-alert
      alert(error.response.data.message);
    }
  }

  @action async clearImpersonatedUser() {
    try {
      const { impersonator } = this.user;
      await impersonate(impersonator);
      this.rootStore.routerStore.navigate('home', { reload: true });
      window.location.reload();
    } catch (error) {
      console.error('impersonateUser error:', error);
    }
  }

  @action hasRoles(roles) {
    roles = Array.isArray(roles) ? roles : [roles];

    let roleToCheck = '';
    const _hasRole = roles?.filter((r) => {
      roleToCheck = `is${getProperCaseForAString(r)}`;
      return (
        // eslint-disable-next-line no-prototype-builtins
        this.hasOwnProperty &&
        // eslint-disable-next-line no-prototype-builtins
        this.hasOwnProperty(roleToCheck) &&
        this[roleToCheck]
      );
    });

    return _hasRole?.length > 0;
  }

  @action async getUserAchievements() {
    this.isLoadingAchievements = true;
    try {
      const achievements = await getCredlyBadges();
      this.achievements = achievements;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingAchievements = false;
    }
  }

  @action async submitFreeTrialRegistration(formData) {
    if (isFreeTierSubscriber(this.userRecentlyExpiredSubscription)) {
      await restartFreeTrial(this.username, formData);
    } else {
      await registerFreeTrial(formData);
    }

    await this.getActiveSubscription(); // refresh subscription to get the new ls000
  }

  /**
   * Given a list of fields, returns an object with the initial values for a form, based on "/me" user data endpoint
   * @param {Array<string>} fieldList
   * @returns
   */
  @action getFreeTrialRegistrationInitialValues = async (fieldList) => {
    let baseObject = { ...this.user, email: this.user.default_email };

    if (fieldList && fieldList.length > 1) {
      baseObject = pick(baseObject, fieldList);
    }
    return baseObject;
  };
}

export default UserStore;
