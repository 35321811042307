import { action, observable } from 'mobx';
import { FeatureFlag, FeatureSection } from 'types';

import { getAllFeatures } from '../services/ConfigService';

export default class FeaturesStore {
  rootStore: any;

  @observable allFeaturesFetched = false;

  @observable features: FeatureSection[] = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  getFeatureFlag = (section: string, name: string): FeatureFlag => {
    const foundSection = this.features.find((f) => f.section === section);
    let feature = null;
    if (foundSection && Array.isArray(foundSection.flags)) {
      feature = foundSection.flags.find((flag) => flag.name === name);
    }
    return feature;
  };

  isFeatureEnabled = (
    section: string,
    name: string,
    defaultValue?: boolean,
  ) => {
    const featureFlag = this.getFeatureFlag(section, name);
    return this.isAFeatureFlagEnabled(featureFlag, defaultValue);
  };

  @action setAFeature = (feature: FeatureSection) => {
    const indexOfFeature = this.features.findIndex(
      (f) => f.section === feature.section,
    );

    if (indexOfFeature >= 0) {
      this.features[indexOfFeature] = feature;
    } else {
      this.features.push(feature);
    }
  };

  @action isAFeatureFlagEnabled = (
    featureFlag: FeatureFlag,
    defaultFlagValue: boolean = false,
  ): boolean => {
    let isFeatureEnabled = null;

    if (featureFlag) {
      const hasRole = this.rootStore.userStore.hasRoles(
        featureFlag?.super_roles || [],
      );
      const hasSubscription = this.hasSubscription(
        featureFlag?.super_subscriptions,
      );
      const hasAUserName = this.hasAUserName(featureFlag?.super_users);

      isFeatureEnabled =
        hasRole || hasSubscription || hasAUserName || featureFlag.active;
    }

    return isFeatureEnabled !== null ? isFeatureEnabled : defaultFlagValue;
  };

  @action findIsFeatureEnabledBySectionFlagName = (
    section: string,
    flagName: string,
    defaultFlagValue: boolean = false,
  ): boolean => {
    const flag = this.features
      ?.find((f) => f.section?.toLowerCase() === section?.toLowerCase())
      ?.flags.find((f) => f.name?.toLowerCase() === flagName?.toLowerCase());
    if (flag) {
      return this.isAFeatureFlagEnabled(flag, defaultFlagValue);
    }
    return defaultFlagValue;
  };

  /* eslint-disable no-param-reassign */
  @action hasSubscription(subscriptionCodes) {
    if (subscriptionCodes && !Array.isArray(subscriptionCodes)) {
      subscriptionCodes = [subscriptionCodes];
    }
    const currentUserSubscription =
      this.rootStore.userStore.currentSubscriptionCode?.toLowerCase()?.trim();

    if (!currentUserSubscription) {
      return (
        this.rootStore.userStore.isAlaCarte &&
        subscriptionCodes?.find(
          (code) => code?.toLowerCase()?.trim() === 'alacarte',
        )
      );
    }

    return (
      subscriptionCodes?.find(
        (code) => code?.toLowerCase()?.trim() === currentUserSubscription,
      ) !== undefined
    );
  }

  /* eslint-disable no-param-reassign */
  @action hasAUserName(usernames) {
    if (usernames && !Array.isArray(usernames)) {
      usernames = [usernames];
    }
    const currentUserName = this.rootStore.userStore.username
      ?.toLowerCase()
      ?.trim();
    return (
      usernames?.find(
        (username) => username?.toLowerCase()?.trim() === currentUserName,
      ) !== undefined
    );
  }

  @action getAllFeatures = async () => {
    try {
      const allFeatures = await getAllFeatures();

      allFeatures?.forEach((feature) => {
        this.setAFeature(feature);
      });
    } catch {
      this.features = [];
    } finally {
      this.allFeaturesFetched = true;
    }
  };
}
