import React, { useContext, useEffect } from 'react';
import UltimatePagination from 'react-ultimate-pagination-bootstrap-3';
import { useTranslation } from 'react-i18next';
import { when } from 'mobx';

import { observer, useLocalStore } from 'mobx-react';
import { Col, Row } from 'react-bootstrap';
import { withErrorBoundary } from 'react-error-boundary';
import { storesContext } from 'stores';

import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import ViewLoader from 'components/Loading/View';
import Filters from 'components/PremiumFilter';
import PremiumClassCard from 'components/Classes/PremiumClasses/Card';
import LimitedTrialLimitReachAlert from 'components/Subscriptions/LimitedTrial/LimitReachedAlert';

import './styles.scss';

import LiveSessionsStore from './store';

const LiveSessions = () => {
  const { i18n, t } = useTranslation();
  const {
    catalogStore,
    routerStore,
    uiStore,
    userStore,
    vocabularyStore,
    premiumSessionsStore,
    premiumSessionFilterStore,
    classesStore,
  } = useContext(storesContext);

  const liveSessionsStore = useLocalStore(
    () => new LiveSessionsStore(catalogStore, premiumSessionFilterStore),
  );

  const { paginatedEntries } = liveSessionsStore;

  uiStore.title = routerStore?.route?.params?.title;

  const { title } = uiStore;
  document.title = t(title);

  const presetFilters = () => {
    premiumSessionFilterStore.presetFilters(routerStore?.route?.params?.filter);
  };

  useEffect(() => {
    // Populate entry data and filters on initial load
    when(
      () => !catalogStore.loaded && userStore.subscription.subscription,
      async () => {
        const entries = await catalogStore.getCurrentSubscriptionCatalog();

        if (entries) {
          catalogStore.getFilterInfo();
        }
      },
    );
  }, []);

  // Premium sessions
  useEffect(() => {
    // // Select preset filters on initial load
    when(
      () =>
        catalogStore.loaded &&
        premiumSessionsStore.loaded &&
        routerStore?.route?.name,
      () => {
        if (
          catalogStore.loaded &&
          premiumSessionsStore.loaded &&
          !liveSessionsStore.ready
        ) {
          premiumSessionFilterStore.getFilterInfo(
            i18n,
            t,
            vocabularyStore,
            liveSessionsStore.filterForScheduledSessionFilters,
          );
          presetFilters();

          liveSessionsStore.ready = true;
        }
      },
    );
  }, [classesStore.premiumEnrollmentsReloaded, liveSessionsStore]);

  return (
    <div className="container-grid-responsive">
      {userStore.hasReachedCurrentSubscriptionPremiumVTLimit &&
        userStore.isLimitedTrialSubscriber && <LimitedTrialLimitReachAlert />}
      <React.Suspense fallback={<ViewLoader />}>
        <Row className="live-session-container">
          <Col xs={12} md={4}>
            <Filters showProducts showCategories />
          </Col>

          <Col xs={12} md={8}>
            <section className="live-session-list-container">
              {liveSessionsStore.ready ||
              classesStore.premiumEnrollmentsReloaded ? (
                (paginatedEntries.length > 0 && (
                  <React.Fragment>
                    <div className="live-session-list-wrapper">
                      {paginatedEntries.map((entry) => {
                        const { doc_id: docId } = entry;
                        return (
                          <PremiumClassCard
                            key={docId}
                            renderSessionCardsbyDefault={false}
                            coloredBottom
                            premiumClass={entry}
                            enrollments={classesStore.getPremiumClassEnrollmentsForCourseSlug(
                              entry.offering_slug,
                            )}
                          />
                        );
                      })}
                    </div>
                    <div className="live-session-pagination">
                      <UltimatePagination
                        currentPage={liveSessionsStore.currentPage}
                        totalPages={liveSessionsStore.totalPages}
                        onChange={liveSessionsStore.setCurrentPage}
                      />
                    </div>
                  </React.Fragment>
                )) || <p>{t('No premium sessions found')}</p>
              ) : (
                <ViewLoader />
              )}
            </section>
          </Col>
        </Row>
      </React.Suspense>
    </div>
  );
};

export default withErrorBoundary(
  observer(LiveSessions),
  GenericViewError,
  handleError,
);
